import axios from "@/plugins/axios";

export const GET_PURCHASES = (payload) => {
  return axios.get(`/bnpl/purchase/by-customer?`, { params: payload });
};

export const GET_PURCHASE_HISTORY = (payload) => {
  return axios.get(`/bnpl/v2/purchase/customer-history?`, { params: payload });
};

export const GET_SINGLE_PURCHASE_HISTORY = (purchaseId) => {
  return axios.get(`bnpl/purchase/${purchaseId}/customer-view`);
};

export const GET_PURCHASE_RECIEPT = (purchaseId) => {
  return axios.get(`/bnpl/v2/purchase/${purchaseId}/receipt`, { responseType: "blob" });
};

export const GET_CALCULATOR_DATA = () => {
  return axios.get(`/bnpl/v2/purchase/purchase-calculator`);
};

export const GET_CALCULATOR_FEE = (data) => {
  return axios.get(`/bnpl/v2/purchase/get-customer-fee`, { params: data });
};

export const GET_ALL_CUSTOMER_FEES = (data) => {
  return axios.get(`/bnpl/v2/purchase/get-customer-fee-all`, { params: data });
};