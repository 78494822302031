export const resetState = () => {
    return {
        card: {
            type: "",
            currency: "NGN",
            permanentAddressId: "",
            deliveryAddressId: "",
            pin: "",
            color: "",
            nickname: ""
        },
        virtualCards: [],
        fullAddress: {},
        calculatorData: {},
        allowedPaymentPlans: {}
    };
};
