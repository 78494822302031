var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", attrs: { id: "app" } },
    [
      _c("AlertBox"),
      _c("Toast"),
      _c("Loader"),
      _vm.growthBookReady
        ? _c(
            _vm.layout,
            { tag: "component" },
            [
              _c(
                "transition",
                { attrs: { name: _vm.handleTransition, mode: "out-in" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }