import axios from "@/plugins/axios";
export const VERIFY_BANK = ({ vendor, code }) => {
  return axios.post(`/verification/linked-account/vendor/${vendor}`, {
    authenticationId: code,
  });
};

export const VERIFY_CARD = ({ transactionReference }) => {
  return axios.put(
    `/wallet/transaction/${transactionReference}/query-new-card-transaction`
  );
};

export const VERIFY_WORK_EMAIL = (email) => {
  return axios.get(
    `/bnpl/company-profile/by-email-address?emailAddress=${email}`
  );
};
export const GET_WORK_EMAIL = (id) => {
  return axios.get(`/bnpl/employment-email-address/${id}/id`);
};
export const GET_WORK_EMPLOYMENT_DOCUMENTS = (id) => {
  return axios.get(`/bnpl/employment-document/${id}/id`);
};

export const GET_ALLOWED_DOCUMENTS = () => {
  return axios.get(`/bnpl/employment-document/allowed-type`);
};

export const VERIFY_BVN = (data) => {
  return axios.put(`/bnpl/customer/verify-bvn`, data);
};

export const QUERY_ACCOUNT_HEALTH = ({ accountNumber, bankId }) => {
  return axios.put(
    `/bnpl/account-financial-health/account-number/${accountNumber}/bank-id/${bankId}/query`
  );
};

export const PDF_ANALYSIS = (data) => {
  return axios.post(`bnpl/account-financial-health/submit-pdf-statement`, data);
};

export const QUERY_PDF_ANALYSIS = (accountHealthId) => {
  return axios.put(
    `bnpl/account-financial-health/${accountHealthId}/query-pdf-status`
  );
};

export const PDF_SUPPORTED_BANKS = () => {
  return axios.get(
    `/bnpl/account-financial-health/pdf-analysis-supported-banks`
  );
};

export const INITIATE_STATEMENT_REQUEST = (data) => {
  return axios.post(
    `/bnpl/account-financial-health/statement-request/initiate`,
    data
  );
};

export const QUERY_STATEMENT_REQUEST = (accountFinancialHealthId) => {
  return axios.put(
    `/bnpl/account-financial-health/${accountFinancialHealthId}/statement-request/query`
  );
};

export const CONFIRM_STATEMENT_REQUEST = (data) => {
  return axios.put(
    `/bnpl/account-financial-health/statement-request/confirm`,
    data
  );
};

export const E_CHANNEL_BANK_STATEMENT_REQUEST = (data) => {
  return axios.post(
    `/bnpl/account-financial-health/statement-request/receive-from-bank-echannel`,
    data
  );
};

export const REQUEST_STATEMENT_SUPPORTED_BANKS = () => {
  return axios.get(
    `/bnpl/account-financial-health/statement-request/supported-banks`
  );
};

export const GET_ACCOUNT_SUMMARY_FOR_CUSTOMER = () => {
  return axios.get(`/bnpl/account-financial-health/get-summary`)
}

export const HYPERVERGE_TOKEN = (expiration) => {
  return axios.get(
    `/verification/face-verification/hypreverge/${expiration}/login`
  );
};
