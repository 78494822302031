var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "layout", staticClass: "h-screen main" },
    [
      _c("Navbar"),
      _vm.growthBookReady
        ? _c(
            "div",
            { staticClass: "container-body" },
            [
              _c("Sidebar", {
                staticClass: "hidden md:block",
                attrs: { showCard: _vm.$growthbook.isOn("issued-card") },
              }),
              _c("MobileNav", { staticClass: "md:hidden" }),
              _c("div", { staticClass: "body-content md:p-4 py-2 px-4" }, [
                _c(
                  "div",
                  { staticClass: "container body-content__container pb-10" },
                  [_vm._t("default")],
                  2
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }