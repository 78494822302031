import {
    ORDER_CARD,
    GET_ALL_CARDS,
    GET_CARD_BY_ID,
    FIX_CARD_LIMIT,
    GET_CARD_SPENDING_LIMIT,
    CHANGE_CARD_PIN,
    BLOCK_CARD,
    UPDATE_DEFAULT_PAYMENT_PLAN,
    GET_DEFAULT_PAYMENT_PLAN,
    GET_PAYMENT_PLANS
} from "@/utils/api/card";

export default {

    getAllVirtualCards({ commit }, payload) {
        return new Promise((resolve, reject) => {
            GET_ALL_CARDS(payload.params)
                .then(({ data }) => {
                    payload.type === "paginate"
                        ? commit("ADD_VIRTUAL_CARDS", data.data)
                        : commit("GET_VIRTUAL_CARDS", data.data);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    resetCards({ commit }) {
        commit("RESET_CARDS", []);
    },

    updateCardType({ commit }, payload) {
        commit("UPDATE_CARD_TYPE", payload);
    },

    updateCardCustomization({ commit }, payload) {
        commit("UPDATE_CARD_CUSTOMIZATION", payload);
    },

    updateCardPin({ commit }, payload) {
        commit("UPDATE_CARD_PIN", payload);
    },

    updateCardDeliveryAddress({ commit }, payload) {
        commit("UPDATE_CARD_DELIVER_ADDRESS", payload);
    },

    saveFullAddress({ commit }, payload) {
        commit("SAVE_FULL_ADDRESS", payload);
    },

    fixCardLimit({ }, payload) {
        return new Promise((resolve, reject) => {
            FIX_CARD_LIMIT(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    blockCard({ }, payload) {
        return new Promise((resolve, reject) => {
            BLOCK_CARD(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getCardSpendingLimit({ }, payload) {
        return new Promise((resolve, reject) => {
            GET_CARD_SPENDING_LIMIT(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getAllowedPaymentPlans({ commit }) {
        return new Promise((resolve, reject) => {
            GET_PAYMENT_PLANS()
                .then((res) => {
                    commit("SET_ALLOWED_PAYMENT_PLAN", res.data.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getCardDefaultPaymentPlan({ }, payload) {
        return new Promise((resolve, reject) => {
            GET_DEFAULT_PAYMENT_PLAN(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    updateCardDefaultPaymentPlan({ }, payload) {
        return new Promise((resolve, reject) => {
            UPDATE_DEFAULT_PAYMENT_PLAN(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },


    changeCardPin({ }, payload) {
        return new Promise((resolve, reject) => {
            CHANGE_CARD_PIN(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    orderCard({ }, payload) {
        return new Promise((resolve, reject) => {
            ORDER_CARD(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getSingleCard({ }, payload) {
        return new Promise((resolve, reject) => {
            GET_CARD_BY_ID(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getDefaultPaymentPlan({ }, payload) {
        return new Promise((resolve, reject) => {
            GET_DEFAULT_PAYMENT_PLAN(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    setDefaultFlexi({ commit }) {
        commit("SET_DEFAULT_FLEXI_FEE");
    },

    setFlexiFee({ commit }, payload) {
        commit("SET_FLEXI_FEE", payload);
    },
};
