<template>
  <div class="grid lg:grid-cols-2 gap-4 pt-8">
    <div>
      <h4 class="text-lg font-semibold text-grey mb-2">Pending payments</h4>
      <Card class="mt-1 md:h-full">
        <EmptyState
          v-if="purchases.length === 0"
          description="No purchases found"
        />
        <div v-else>
          <div
            class="flex items-center justify-between item py-3 cursor-pointer"
            v-for="upcoming in truncateUpcoming"
            :key="upcoming.id"
          >
            <div class="flex items-center border-bottom">
              <div
                class="merchant-logo h-12 w-12 mr-2 rounded-full grid place-items-center border border-lightGrey-1"
              >
                <img
                  class="rounded-full max-h-10 max-w-full"
                  :src="getImage()"
                  alt="logo"
                />
              </div>
              <div>
                <p class="text-black">
                  {{ upcoming.orderTitle | truncate(25, "...") }}
                </p>
                <p class="text-grey text-xs">
                  due {{ upcoming.dueDate | timeBetween }}
                </p>
              </div>
            </div>
            <div>
              <p class="dark-grey font-bold text-right">
                {{ upcoming.amount | formatMoney }}
              </p>
              <p class="text-grey text-xs">
                of {{ upcoming.amount | formatMoney }}
              </p>
            </div>
          </div>
          <router-link to="/repayments">
            <p class="text-center font-semibold text-grey mt-4 cursor-pointer">
              View more
            </p>
          </router-link>
        </div>
      </Card>
    </div>
    <div>
      <h4 class="text-lg font-semibold text-grey mb-2">Purchases</h4>
      <Card class="mt-1 md:h-full">
        <EmptyState
          v-if="purchases.length === 0"
          description="No purchases found"
        />
        <div v-else>
          <div
            class="flex items-center justify-between item py-3"
            v-for="purchase in purchases"
            :key="purchase.id"
          >
            <div class="flex items-center border-bottom">
              <div
                class="merchant-logo h-12 w-12 mr-2 rounded-full grid place-items-center border border-lightGrey-1"
              >
                <img
                  class="rounded-full max-h-10 max-w-full"
                  :src="getImage()"
                  alt="logo"
                />
              </div>
              <div>
                <p class="text-black">{{ purchase.orderTitle | capitalize }}</p>
                <p class="text-grey text-xs">
                  {{ dayjs(purchase.createdAt).format("MMM D YYYY | hh:mmA") }}
                </p>
              </div>
            </div>
            <div>
              <p class="dark-grey font-bold text-right">
                {{ purchase.amountOfOrder | formatMoney }}
              </p>
              <!-- <p class="text-grey text-xs">of N105,000</p> -->
            </div>
          </div>
          <router-link to="/purchases">
            <p class="text-center font-semibold text-grey mt-4 cursor-pointer">
              View more
            </p>
          </router-link>
        </div>
      </Card>
    </div>
  </div>
</template>
<script>
  import Hr from "@/UI/Hr";
  import dayjs from "dayjs";
  import relativeTime from "dayjs/plugin/relativeTime";
  import getImage from "@/mixins/getImage";
  dayjs.extend(relativeTime);
  export default {
    mixins: [getImage],
    components: {
      Hr,
      EmptyState: () => import("@/UI/EmptyState"),
    },
    props: {
      upComingLoading: {
        type: Boolean,
        default: false,
        required: true,
      },
      upcomingPayments: {
        type: Array,
        default: () => [],
        required: true,
      },
      purchases: {
        type: Array,
        default: () => [],
        required: true,
      },
      purchaseLoading: {
        type: Boolean,
        default: false,
        required: true,
      },
    },
    computed: {
      dayjs() {
        return dayjs;
      },
      truncateUpcoming() {
        return this.upcomingPayments.slice(0, 10);
      },
      truncatePurchases() {
        return this.purchases.slice(0, 10);
      },
    },
  };
</script>
<style scoped>
  .item {
    border-bottom: 1px solid #f8f8f8;
  }
</style>
