var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    { staticClass: "flex searchContainer items-center", attrs: { p: _vm.p } },
    [
      _c("img", {
        staticClass: "h-4 w-4 mr-4",
        attrs: {
          src: "https://res.cloudinary.com/zillaafrica/image/upload/v1623838508/customer/Union_1_x261eq.svg",
          alt: "search",
        },
      }),
      _c("div", { staticClass: "line mr-4 h-4" }),
      _c("input", {
        staticClass: "border-none outline-none w-full",
        attrs: { type: "text", placeholder: _vm.placeholder },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event.target.value)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }