<template>
  <div class="h-screen main" ref="layout">
    <Navbar />
    <div class="container-body" v-if="growthBookReady">
      <Sidebar
        class="hidden md:block"
        :showCard="$growthbook.isOn('issued-card')"
      />
      <MobileNav class="md:hidden" />
      <div class="body-content md:p-4 py-2 px-4">
        <div class="container body-content__container pb-10">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Sidebar from "./Sidebar.vue";
import MobileNav from "./MobileNav.vue";
import Navbar from "./Navbar.vue";
import { Modal } from "@/UI/Modals";
import { Button } from "@/UI/Button";

export default {
  components: {
    Sidebar,
    Navbar,
    MobileNav,
    Modal,
    Button,
  },
  data: () => ({
    growthBookReady: false,
  }),
  computed: {
    ...mapState({
      userData: (state) => state?.auth?.user,
    }),
  },
  watch: {
    userData() {
      this.$growthbook.setAttributes({
        state: this.userData?.addressDetails?.state,
        "x-source": "WEB",
        principalId: this.userData.id,
      });
    },
  },
  mounted() {
    this.$growthbook.setAttributes({
      state: this.userData?.addressDetails?.state,
      "x-source": "WEB",
      principalId: this.userData.id,
    });
    this.growthBookReady = true;
  },
};
</script>

<style scoped>
.container-body {
  display: grid;
  grid-template-columns: 265px 1fr;
  height: calc(100vh - 6rem);
  /* min-height: -webkit-fill-available; */
  width: 100%;
  background-color: #f3f3f6;
}

@media (max-width: 768px) {
  .container-body {
    grid-template-columns: 100%;
    height: calc(100% - 5rem);
    /* grid-template-rows: 6rem 1fr 4rem; */
  }
  .body-content__container {
    min-height: calc(100% - 5rem);
  }
}

.body-content {
  height: calc(100vh - 6rem);
  overflow-y: auto;
  /* overflow-x: hidden; */
}
.body-content__container {
  min-height: calc(100% - 5rem);
  max-width: 54.125rem;
  margin: 0 auto;
}
.body-content::-webkit-scrollbar {
  width: 5px !important;
}
.body-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3;
  border-radius: 10px;
}
.body-content::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .main {
    display: grid;
    /* height: 100%; */
    /* scrollbar-width: none; */

    /* grid-template-rows: 6rem 1fr 5rem; */
  }
  .container-body {
    height: 100%;
    overflow-y: auto;
    /* min-height: -webkit-fill-available; */
  }
  .body-content {
    min-height: calc(100% - 4rem);
    /* padding-bottom: 6rem !important; */
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* height: calc(100%) */

    /* margin-bottom: 6rem; */
  }
  .body-content::-webkit-scrollbar {
    display: none !important;
  }
  .body-content::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 0;
  }
  .body-content::-webkit-scrollbar-thumb {
    background: none;
    border-radius: 0;
  }
  .heading-text {
    font-size: 1.438rem;
  }
}

.bottom-nav {
  background-color: white;
}
.body-content::-webkit-scrollbar {
  width: 0 !important;
}
.body-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3;
  border-radius: 10px;
}
.body-content::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 10px;
}

/* .heading-text {
  font-size: 2.25rem;
  line-height: 2.688rem;
}

.content-text {
  font-size: 0.875rem;
} */
</style>
