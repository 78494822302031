import { resetState } from "./defaultState";

export default {
  CREDIT_QUALIFICATION(state, payload) {
    state.creditQualification = payload;
  },
  EMOLOYMENT_INFO(state, payload) {
    state.userEmployementInfo = payload;
  },
  GET_USER_BIO(state, payload) {
    state.userBio = payload;
  },
  GET_USER_BANKS(state, payload) {
    state.userBanks = payload;
  },
  GET_USER_CASHOUT_ACCOUNT(state, payload) {
    state.userCashoutAccount = payload;
  },
  CASHOUT_ACCOUNT_HAS_BEEN_CALLED(state, payload) {
    state.cashoutEndpointCalled = payload;
  },
  SET_REGISTRATION_TODO(state, payload) {
    state.userRegistrationTodo = payload;
  },
  SET_ACCOUNT_SUMMARY(state, payload) {
    state.accountSummary = payload;
  },
  SET_DIRECTSTATEMENT_SUMMARY(state, payload) {
    state.accountSummary.pendingDirectStatementRequest = payload;
  },
  SET_RESET_USERINFO_STATE(state) {
    Object.assign(state, resetState());
  },
  SET_CREDIT_CARD_FEES(state, payload) {
    state.allCreditCardFees = payload;
  },

  SET_CHECKOUT_FEES(state, payload) {
    state.allCheckoutFees = payload;
  },
};
