var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid lg:grid-cols-2 gap-4 pt-8" }, [
    _c(
      "div",
      [
        _c("h4", { staticClass: "text-lg font-semibold text-grey mb-2" }, [
          _vm._v("Pending payments"),
        ]),
        _c(
          "Card",
          { staticClass: "mt-1 md:h-full" },
          [
            _vm.purchases.length === 0
              ? _c("EmptyState", {
                  attrs: { description: "No purchases found" },
                })
              : _c(
                  "div",
                  [
                    _vm._l(_vm.truncateUpcoming, function (upcoming) {
                      return _c(
                        "div",
                        {
                          key: upcoming.id,
                          staticClass:
                            "flex items-center justify-between item py-3 cursor-pointer",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex items-center border-bottom" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "merchant-logo h-12 w-12 mr-2 rounded-full grid place-items-center border border-lightGrey-1",
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "rounded-full max-h-10 max-w-full",
                                    attrs: { src: _vm.getImage(), alt: "logo" },
                                  }),
                                ]
                              ),
                              _c("div", [
                                _c("p", { staticClass: "text-black" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("truncate")(
                                          upcoming.orderTitle,
                                          25,
                                          "..."
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("p", { staticClass: "text-grey text-xs" }, [
                                  _vm._v(
                                    " due " +
                                      _vm._s(
                                        _vm._f("timeBetween")(upcoming.dueDate)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c("div", [
                            _c(
                              "p",
                              { staticClass: "dark-grey font-bold text-right" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatMoney")(upcoming.amount)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("p", { staticClass: "text-grey text-xs" }, [
                              _vm._v(
                                " of " +
                                  _vm._s(
                                    _vm._f("formatMoney")(upcoming.amount)
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    _c("router-link", { attrs: { to: "/repayments" } }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center font-semibold text-grey mt-4 cursor-pointer",
                        },
                        [_vm._v(" View more ")]
                      ),
                    ]),
                  ],
                  2
                ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("h4", { staticClass: "text-lg font-semibold text-grey mb-2" }, [
          _vm._v("Purchases"),
        ]),
        _c(
          "Card",
          { staticClass: "mt-1 md:h-full" },
          [
            _vm.purchases.length === 0
              ? _c("EmptyState", {
                  attrs: { description: "No purchases found" },
                })
              : _c(
                  "div",
                  [
                    _vm._l(_vm.purchases, function (purchase) {
                      return _c(
                        "div",
                        {
                          key: purchase.id,
                          staticClass:
                            "flex items-center justify-between item py-3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex items-center border-bottom" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "merchant-logo h-12 w-12 mr-2 rounded-full grid place-items-center border border-lightGrey-1",
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "rounded-full max-h-10 max-w-full",
                                    attrs: { src: _vm.getImage(), alt: "logo" },
                                  }),
                                ]
                              ),
                              _c("div", [
                                _c("p", { staticClass: "text-black" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("capitalize")(purchase.orderTitle)
                                    )
                                  ),
                                ]),
                                _c("p", { staticClass: "text-grey text-xs" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .dayjs(purchase.createdAt)
                                          .format("MMM D YYYY | hh:mmA")
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c("div", [
                            _c(
                              "p",
                              { staticClass: "dark-grey font-bold text-right" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatMoney")(
                                        purchase.amountOfOrder
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    _c("router-link", { attrs: { to: "/purchases" } }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center font-semibold text-grey mt-4 cursor-pointer",
                        },
                        [_vm._v(" View more ")]
                      ),
                    ]),
                  ],
                  2
                ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }