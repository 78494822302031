<template>
  <div>
    <Card class="w-full" shadow>
      <h4
        class="
          hidden
          md:block
          font-bold
          md:text-xl
          text-xl text-center
          md:text-left
        "
      >
        Sign in
      </h4>
      <form @submit.prevent="loginUser">
        <Input
          type="text"
          class="mt-8"
          placeholder="What's your mail?"
          v-model="user.username"
          width="w-full"
          id="username"
          errorText="Please enter a valid email address"
          :validation="rules.username"
          @valid="valid.username = $event"
        />
        <Input
          class="mt-8"
          type="password"
          placeholder="What's your password"
          revealPassword
          v-model="user.secret"
          width="w-full"
          id="password"
          errorText="Password field cannot be empty"
          :validation="rules.secret"
          @valid="valid.secret = $event"
        />
        <div>
          <div class="flex">
            <router-link to="/forgot-password" class="flex ml-auto text-right">
              <p class="text-brandPurple mt-5 text-right text-sm">
                Forgot password?
              </p></router-link
            >
          </div>
        </div>

        <Button
          text="Login"
          width="w-full mt-8"
          :loading="loading"
          :shadow="true"
          :disabled="disableButton"
          type="submit"
        />
      </form>
      <p class="mt-8 text-center text-grey text-sm flex justify-center">
        Don’t have an account?
        <router-link to="/register">
          <span class="flex items-center">
            <span class="text-brandPurple mx-3">Sign up here </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="8"
              viewBox="0 0 16 8"
              fill="none"
            >
              <path
                d="M12.01 3.00057H1C0.45 3.00057 0 3.45057 0 4.00057C0 4.55057 0.45 5.00057 1 5.00057H12.01V6.79057C12.01 7.24057 12.55 7.46057 12.86 7.14057L15.64 4.35057C15.83 4.15057 15.83 3.84057 15.64 3.64057L12.86 0.850567C12.55 0.530567 12.01 0.760566 12.01 1.20057V3.00057Z"
                fill="#6B6873"
              />
            </svg>
          </span>
        </router-link>
      </p>
    </Card>
    <!-- modal prompt for if login attempt used merchant details that has completed registration-->
    <Modal
      :display="showLoginPrompt.display"
      @close="showLoginPrompt.display = false"
    >
      <transition name="slideX" mode="out-in">
        <div class="text-center" key="first">
          <h3 class="text-center text-black text-xl font-bold">
            This details belongs to a merchant
          </h3>
          <p class="text-grey text-lg mt-4">
            You can either login to your merchant dashboard or create a shoppers
            account using same details.
          </p>
          <Button
            text="Login to Merchant Dashboard"
            width="w-full"
            class="mt-16"
            @click="handleLoginPrompt"
          />
          <Button
            outline
            text="Create Shopper’s Account instead"
            width="w-full"
            class="mt-4"
            :loading="proceedLoading"
            @click="handleLoginPrompt('register')"
          />
        </div>
      </transition>
    </Modal>
    <!-- modal prompt for username -->
    <LargeModal :display="showProfile" size="large" hideClose>
      <div class="lg:w-3/5 mx-auto mt-12">
        <p
          class="
            text-center text-xl
            md:text-2xl
            font-bold
            primary-text
            profile-title
          "
        >
          You’re almost good to go; one last thing
        </p>
        <p class="text-center text-grey mt-2 md:text-lg">
          What would you like us to call you?
        </p>
        <form @submit.prevent="setNickName()">
          <Input
            width="w-full"
            class="mt-8"
            placeholder="Nickname"
            v-model="profileName"
            :validation="profileName.length > 0"
            errorText="Username field cannot be empty"
          />
          <Button
            :disabled="disableProfileButton"
            text="Continue to your account"
            width="w-full"
            class="mt-12"
            :loading="profileLoading"
            type="submit"
          />
        </form>
      </div>
    </LargeModal>
    <!-- modal if a user attempts to login with merchant details that hasn't completed registration -->
    <Modal :display="showMerchantPrompt" @close="showMerchantPrompt = false">
      <div class="text-center">
        <h3 class="text-center text-black text-lg md:text-xl font-bold">
          This details belongs to a merchant and hasn't completed registration
        </h3>
        <p class="text-grey text-sm md:text-lg mt-4">
          Would you like to complete your merchant registration?
        </p>
        <a :href="merchantUrl" target="_blank" rel="noopener noreferrer">
          <Button
            text="Yes, Continue merchant registration"
            width="w-full"
            class="mt-12"
          />
        </a>

        <p class="md:text-sm text-xs text-grey mt-2">
          ** You will be redirected to our merchant site
        </p>

        <p
          class="text-center font-semibold text-brandPurple mt-4"
          @click="showMerchantPrompt = false"
        >
          Close
        </p>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
import { Modal, LargeModal } from "@/UI/Modals";
export default {
  components: {
    Input,
    Button,
    LargeModal,
    Modal,
  },
  data() {
    return {
      showProfile: false,
      profileName: "",
      profileLoading: false,
      user: {
        username: "",
        secret: "",
      },
      loading: false,
      proceedLoading: false,
      valid: {
        username: false,
        secret: false,
      },
      showLoginPrompt: {
        display: false,
        firstPrompt: true,
        // secondPrompt: false,
      },
      showMerchantPrompt: false,
    };
  },

  computed: {
    rules() {
      return {
        username:
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.user?.username.trim()
          ),
        secret: this.user.secret.length !== 0,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
    disableProfileButton() {
      return this.profileName.length < 1;
    },
    merchantUrl() {
      return `${process.env.VUE_APP_MERCHANT_URL}/register`;
    },
  },
  methods: {
    ...mapActions("auth", ["login", "portMerchant", "addNickname"]),
    ...mapActions("notification", ["showAlert"]),
    loginUser() {
      this.user.loginAs = "CUSTOMER";
      this.user.username = this.user.username.trim();
      this.loading = true;
      this.login(this.user)
        .then((data) => {
          if (data.userCategory === "MERCHANT_USER") {
            this.showLoginPrompt.firstPrompt = true;
            this.showLoginPrompt.display = true;
          } else if (data.userCategory === "CUSTOMER_REGISTRATION") {
            this.showProfile = true;
          } else if (data.userCategory === "MERCHANT_REGISTRATION") {
            this.showMerchantPrompt = true;
          }

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleLoginPrompt(mode) {
      this.proceedLoading = true;
      this.portMerchant()
        .then(() => {
          this.showLoginPrompt.display = false;
          this.proceedLoading = false;
          setTimeout(() => {
            if (mode === "register") {
              this.loginUser();
            } else {
              window.open("https://merchant.usezilla.com", "_blank");
            }
          }, 1000);
        })
        .catch(() => {
          this.proceedLoading = false;
        });
    },
    setNickName() {
      this.profileLoading = true;
      this.addNickname({ nickName: this.profileName })
        .then(() => {
          // login the user
          this.login(this.user)
            .then(() => {
              // this.handleShowReminder(true);
              //   hide the nickname dialog
              this.profileLoading = false;
              this.showProfile = false;
            })
            .catch(() => {
              this.profileLoading = false;
            });
        })
        .catch(() => {
          this.profileLoading = false;
        });
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";

@media (max-width: 768px) {
  .login-container {
    min-height: calc(100vh - 15rem);
    max-width: 100%;
  }
}
</style>
