var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("ul", { staticClass: "tg-list" }, [
      _c("li", { staticClass: "tg-list-item" }, [
        _c("input", {
          staticClass: "tgl tgl-ios",
          attrs: { id: _vm.id, type: "checkbox", disabled: _vm.disabled },
          domProps: { value: _vm.value, checked: _vm.value },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.checked)
            },
            change: function ($event) {
              return _vm.$emit("change", _vm.value)
            },
          },
        }),
        _c("label", { staticClass: "tgl-btn", attrs: { for: _vm.id } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }