import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4fa32229&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "vue-tel-input/dist/vue-tel-input.css?vue&type=style&index=0&lang=css&"
import style1 from "./index.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4fa32229')) {
      api.createRecord('4fa32229', component.options)
    } else {
      api.reload('4fa32229', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=4fa32229&", function () {
      api.rerender('4fa32229', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/UI/Input/TelInput/index.vue"
export default component.exports