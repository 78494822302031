<template>
  <div>
    <div class="w-full p-2 flex items-center" v-if="display" :class="[!custom ? `${type}-toast` : null]">
      <svg-icon :color="_iconColor" type="mdi" :path="svgPath.alert"></svg-icon>
      <p class="ml-2 toast" v-html="description"></p>
    </div>
  </div>
</template>
<script>
import { mdiAlertCircleOutline } from "@mdi/js";

export default {
  props: {
    type: {
      type: String,
      default: "error",
      required: true,
      validator: (value) => ["error", "info"].includes(value),
    },
    description: {
      type: String,
      default: "Text here",
      required: false,
    },
    display: {
      type: Boolean,
      default: false,
      required: true,
    },
    custom: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    svgPath: {
      alert: mdiAlertCircleOutline,
    },
  }),
  computed: {
    _iconColor() {
      let color = "";
      switch (this.type) {
        case "info":
          color = "#2490BE";
          break;
        case "error":
          color = "#DE5F35";
          break;
      }
      return color;
    },
  },
};
</script>
<style scoped>

.toast {
  font-size: 13px !important;
}
.error-toast {
  background: #fff7f5;
  border: 1px solid #ffd5cc;
  border-radius: 4px;
}

.error-toast p {
  color: #490f00;
}

.info-toast {
  background: #f5fbff;
  border: 1px solid #ccecff;
  border-radius: 4px;
}

.info-toast p {
  color: #06263c;
}
</style>
