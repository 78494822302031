<template>
  <div class="navbar items-center h-20 md:h-28 pr-4">
    <div class="hidden logo h-28 md:flex items-center">
      <div class="h-8">
        <img
          @click="$router.push('/')"
          class="max-h-full max-w-full pl-8 cursor-pointer"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg"
          alt=""
        />
      </div>
    </div>
    <div
      class="flex w-full container navbar-container justify-between items-center pl-4"
    >
      <div>
        <h3 class="md:text-lg font-bold text-darkBlue">
          Hello {{ activeUser.firstname | capitalize }},
          <br class="md:hidden" />
          <span class="font-normal">{{ greeting }} </span>
        </h3>
      </div>
      <div class="flex items-center">
        <!-- <button
          class="purchase-button bg-brandPurple flex items-center mr-4"
          @click="showCheckout = true"
        >
          <svg
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
          >
            <path
              d="M11 8.50098c.55 0 1-.45 1-1v-2h2c.55 0 1-.45 1-1s-.45-1-1-1h-2v-2c0-.55-.45-1-1-1s-1 .45-1 1v2H8c-.55 0-1 .45-1 1s.45 1 1 1h2v2c0 .55.45 1 1 1ZM6 17.501c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2Zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2Zm-8.9-5h7.45c.75 0 1.41-.41 1.75-1.03l3.38-6.13002c.27-.48.09-1.09-.39-1.36-.48-.26-1.09-.09-1.35.39L14.55 10.501H7.53L3.54 2.07098c-.16-.35-.52-.57-.9-.57H1c-.55 0-1 .45-1 1s.45 1 1 1h1L5.6 11.091l-1.35 2.44c-.73 1.34.23 2.97 1.75 2.97h11c.55 0 1-.45 1-1s-.45-1-1-1H6l1.1-2Z"
              fill="#ffffff"
            />
          </svg>
          <p class="text-xs text-white">Make purchase</p>
        </button> -->
        <div class="relative cursor-pointer">
          <div
            class="icon flex items-center"
            @click="$router.push('/settings')"
          >
            <transition name="fade">
              <img
                v-show="showImage"
                class="icon rounded-full"
                :src="profilePicture"
                alt="profile picture"
                @error="handleImageError"
                @load="showImage = true"
              />
            </transition>
          </div>
        </div>
        <!-- </router-link> -->
      </div>
    </div>
    <Checkout
      :display="showCheckout"
      @close="showCheckout = false"
      @success="showCheckout = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Checkout: () => import("@/components/Home/Checkout/Checkout.vue"),
  },
  computed: {
    ...mapState({
      activeUser: (state) => state?.auth?.user,
    }),
    greeting() {
      let day = new Date();
      let hr = day.getHours();
      if (hr >= 0 && hr < 12) {
        return "Good Morning!";
      } else if (hr == 12) {
        return "Good Afternoon";
      } else if (hr >= 12 && hr <= 16) {
        return "Good Afternoon";
      } else {
        return "Good Evening!";
      }
    },
    // userBio: (state) => state?.userInfo?.userBio,
    // profilePicture() {
    //   return (
    //     `${process.env.VUE_APP_BASE_URL}/media/image/${this.activeUser?.customerFacialImageId}` ||
    //     `https://res.cloudinary.com/zillaafrica/image/upload/v1623454461/customer/Group_513_hnarq6.svg`
    //   );
    // },
  },
  data: () => ({
    showLogout: false,
    profilePicture: "",
    showImage: false,
    showCheckout: false,
  }),
  mounted() {
    if (this.activeUser?.customerFacialImageId) {
      this.profilePicture = `${process.env.VUE_APP_BASE_URL}/media/image/${this.activeUser?.customerFacialImageId}`;
    } else {
      this.profilePicture = require("@/assets/icons/profile.png");
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    toggleLogout() {},
    handleOptionsOutside() {
      this.showLogout = false;
    },
    handleImageError() {
      return (this.profilePicture = require("@/assets/icons/profile.png"));
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.navbar {
  background-color: #f3f3f6;
}
.logout-container {
  position: absolute;
  top: 2.5rem;
  width: 15rem;
  right: 0;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(175, 177, 225, 0.15);
}
.navbar-container {
  max-width: 53.125rem;
  margin: 0 auto;
}
.navbar {
  display: grid;
  grid-template-columns: 265px 1fr;
}
.logo {
  background: #ededf0;
}
.purchase-button {
  border-radius: 1rem;
  padding: 8px 16px;
}
.purchase-button svg {
  transform: scale(0.7);
}
@media (max-width: 768px) {
  .navbar {
    display: none;
    justify-content: center;
    align-items: center;
  }
}
</style>
