import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
import VuexPersistence from "vuex-persist";
import { v4 as uuidv4 } from "uuid";
import Crypto from "crypto-js";
import cookies from "vue-cookies";
import notification from "./notification";
import auth from "./auth";
import dashboard from "./dashboard";
import media from "./media";
import purchases from "./purchases";
import repayments from "./repayments";
import userInfo from "./userInfo";
import settings from "./settings";
import loading from "./loading";
import card from "./card";
import address from "./address";
import api from './api'

if (window?.localStorage?.getItem("vuex")) {
  localStorage.removeItem("vuex");
}

const cookieName = "cookieName";
const storageKey = "tunnel";
const encryptionToken = cookies.get(cookieName) || uuidv4();
cookies.set(cookieName, encryptionToken, { secure: true, expires: 180 });

const vuexLocal = new VuexPersistence({
  storage: {
    getItem: () => {
      // Get the store from local storage.
      const store = window.localStorage.getItem(storageKey);
      if (store) {
        try {
          // Decrypt the store retrieved from local storage
          // using our encryption token stored in cookies.
          const bytes = Crypto.AES.decrypt(store, encryptionToken);

          return JSON.parse(bytes.toString(Crypto.enc.Utf8));
        } catch (e) {
          // The store will be reset if decryption fails.
          window.localStorage.removeItem(storageKey);
        }
      }
      return null;
    },
    // Set the store (for e.g. in local storage)
    setItem: (key, value) => {
      // Encrypt the store using our encryption token stored in cookies.
      const store = Crypto?.AES?.encrypt(value, encryptionToken).toString();
      // Save the encrypted store in local storage.
      return window.localStorage.setItem(storageKey, store);
    },
    // Remove the store (for e.g. from local storage)
    removeItem: () => window.localStorage.removeItem(storageKey),
  },
  modules: ["auth", "userInfo", "card"],
});
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notification,
    auth,
    dashboard,
    media,
    purchases,
    repayments,
    userInfo,
    loading,
    settings,
    card,
    address,
    api
  },
  plugins: [vuexLocal.plugin],
});
