(() => {
    "use strict";
    const t = {
        appId: "kij6a9lae2",
        v: 2,
        q: [],
        start: function (o) {
            this.autorun = o || !0;
        },
        identify: function (t) {
            this.q.push(["identify", t]);
        },
    };
    window.Atlas = t;
    const e = document.createElement("script");
    (e.async = !0), (e.src = "https://app.getatlas.io/client-js/atlas.bundle.js");
    const s = document.getElementsByTagName("script")[0];
    s.parentNode?.insertBefore(e, s);
})();