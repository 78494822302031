<template>
  <div class="body-container">
    <div class="layout-container w-full min-h-screen">
      <div class="flex pt-8 h-14 md:h-16 items-center mb-8">
        <img
          class="max-h-full max-w-full border-r border-lightGrey-2 pr-4"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg"
          alt=""
        />
        <p class="text-grey pl-4">Shop More, Pay Later</p>
      </div>
      <div class="auth-layout md:grid grid-cols-2 content-center">
        <div class="w-full auth-layout-title p-6 md:p-0">
          <div class="auth-layout-heading">
            <transition name="slideY" :key="currentPage.header" mode="out-in">
              <h5 class="md:text-2xl text-xl font-bold primary-text">
                {{ currentPage.heading }}
              </h5>
            </transition>
            <p class="text-grey">{{ currentPage.description }}</p>
          </div>
        </div>
        <div class="md:ml-10 auth-layout-page">
          <slot />
          <p class="text-center text-brandPurple text-sm pt-4 pb-6">
            <a
              href="https://usezilla.notion.site/Data-Privacy-Protection-Cookie-Policy-fd834fd91648458fb5bbf68acce27242"
              target="blank"
              >Zilla Privacy Policy</a
            >
            |
            <a
              href="https://usezilla.notion.site/Terms-of-use-shoppers-3e19b68f1ac94c24aaf773369e2f3566"
              target="blank"
              >Terms of Use</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    currentPage() {
      let data;
      switch (this.$route.path) {
        case "/login":
          data = {
            heading: "Buy what you want now",
            description: "Get in to your account",
          };
          break;
        case "/register":
          data = {
            heading: "Ready to live your best life? Get started now!",
            description: "** takes less than 1 minute",
          };
          break;
        case "/otp":
          data = {
            heading: "Email Verification",
            description: "",
          };
          break;
        case "/forgot-password":
          data = {
            heading: "Forgot Password?",
            description:
              "This should be the email address registered with your Zilla account",
          };
          break;
        case "/forgot-password-otp":
          data = {
            heading: "Authorize reset password",
            description:
              "You will receive a PIN by email and SMS to authorize your password reset request.",
          };
          break;
        case "/create-password":
          data = {
            heading: "Create a new password",
            description:
              "You will receive a PIN by email and SMS to authorize your password reset request.",
          };
          break;
        case "/password":
          data = {
            heading: "Set up your account now",
          };
          break;
      }
      return data;
    },
  },
};
</script>
<style scoped>
.body-container {
  background: #f3f3f6 !important;
}
.layout-container {
  /* padding: 0px 8.8rem; */
  max-width: 69.375rem;
  margin: auto;
}
.auth-layout {
  min-height: calc(100vh - 8rem);
}
.auth-layout-page {
  max-width: 31.5rem;
}
.auth-layout-heading {
  max-width: 26.375rem;
}

@media (max-width: 767px) {
  .layout-container {
    background: #f3f3f6 !important;
    padding: 1rem;
  }
  .auth-layout {
    min-height: calc(100vh - 10rem);
    /* background: #fff; */
    max-width: 31.5rem;
    margin: auto;
    border-radius: 8px;
  }

  .navigation {
    background: black;
    min-height: calc(100vh - 9.5rem);
    left: 0;
    z-index: -1;
    width: 100%;
    background: white;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px;
  }
  .intro_content {
    display: none;
  }
}
</style>
