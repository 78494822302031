export default {
    card: {
        type: "",
        currency: "NGN",
        permanentAddressId: "",
        deliveryAddressId: "",
        pin: "",
        color: "",
        nickname: ""
    },
    virtualCards: [],
    fullAddress: {},
    calculatorData: {},
    allowedPaymentPlans: {}
};
