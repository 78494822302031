var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(10, function (i) {
      return _c("div", { key: i, staticClass: "mt-3 bg-white py-6 px-3" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-between" },
          [
            _c(
              "div",
              { staticClass: "flex items-center" },
              [
                _c("Skeleton", {
                  staticClass: "mr-2",
                  attrs: { type: "circle", height: "3rem", width: "3rem" },
                }),
                _c("Skeleton", { attrs: { width: "4rem" } }),
              ],
              1
            ),
            _c("Skeleton", { attrs: { width: "1rem" } }),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }