// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-2!./assets/main.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-2!@/assets/styles/animation.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "#app {\n  font-family: \"Inter\", Helvetica;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n  -webkit-tap-highlight-color: transparent;\n}\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-family: \"Gilroy\", Helvetica;\n}\np,\na,\nspan,\ntd,\ntr,\nbutton,\nli {\n  font-family: \"Inter\", Helvetica;\n}\n* {\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\np {\n  font-size: 16px;\n}\n@media only screen and (max-width: 600px) {\np {\n    font-size: 14px;\n}\n}\n.update {\n  bottom: 0;\n  left: 0;\n  height: 4rem;\n  background-color: black;\n  color: white;\n  width: 100%;\n  text-align: center;\n  display: grid;\n  place-items: center;\n}\n", ""]);
// Exports
module.exports = exports;
