<template>
  <div>
    <label
      class="flex items-center"
      :class="[p ? p : 'pt-1']"
      v-for="option in selectOptions"
      :key="option"
    >
      <div v-if="type === 'box'">
        <svg
          v-if="selected === option"
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <path
            d="M11.1667 0H1.83333C1.1 0 0.5 0.6 0.5 1.33333V10.6667C0.5 11.4 1.1 12 1.83333 12H11.1667C11.9 12 12.5 11.4 12.5 10.6667V1.33333C12.5 0.6 11.9 0 11.1667 0ZM11.1667 10.6667H1.83333V1.33333H11.1667V10.6667ZM10.4933 4L9.55333 3.05333L5.16 7.44667L3.44 5.73333L2.49333 6.67333L5.16 9.33333L10.4933 4Z"
            fill="#6B6873"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          @click="chooseOption(option)"
        >
          <path
            d="M11.1667 1.33333V10.6667H1.83333V1.33333H11.1667ZM11.1667 0H1.83333C1.1 0 0.5 0.6 0.5 1.33333V10.6667C0.5 11.4 1.1 12 1.83333 12H11.1667C11.9 12 12.5 11.4 12.5 10.6667V1.33333C12.5 0.6 11.9 0 11.1667 0Z"
            fill="#6B6873"
          />
        </svg>
      </div>
      <div v-else-if="type === 'circle'">
        <svg
          v-if="selected === option"
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
        >
          <circle
            cx="8.5"
            cy="9"
            r="7.5"
            fill="white"
            stroke="#D5CCFF"
            stroke-width="2"
          />
          <circle cx="8.5" cy="9" r="4.5" fill="#6956C8" />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          @click="chooseOption(option)"
        >
          <circle
            cx="8.5"
            cy="9"
            r="7.5"
            fill="white"
            stroke="#D5CCFF"
            stroke-width="2"
          />
        </svg>
      </div>
      <span @click="chooseOption(option)" class="ml-4 text-dark font-semibold"> {{ option }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "box",
      required: false,
    },
    label: {
      type: String,
      default: "box",
      required: false,
    },
    reduce: {
      type: Function,
    },
    p: String,
  },
  data() {
    return {
      selected: "",
      isObject: false,
    };
  },
  computed: {
    selectOptions() {
      //   if it is a type of object
      if (typeof this.options[0] === "object" && this.options !== null) {
        this.isObject = true;
        // if the label prop was passed
        if (this.label) {
          return this.options.map((item) => item[this.label]);
        } else {
          return this.options.map((item) => item.label);
        }
      } else {
        return this.options;
      }
    },
  },
  methods: {
    chooseOption(value) {
      // this.selected = e;
      if (typeof this.options[0] === "object" && this.options !== null) {
        //   get index of the selected value
        const index = this.selectOptions.indexOf(value);
        // use the index to locate the object in the options array
        this.$emit("selected", this.reduce(this.options[index]));
        this.selected = this.selectOptions[index];
      } else {
        this.selected = value;
        this.$emit("selected", this.selected);
      }
    },
  },
};
</script>
