var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.display
      ? _c(
          "div",
          { staticClass: "h-full flex justify-center loader items-center" },
          [
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "h-full w-full flex justify-center items-center",
                },
                [
                  _c("div", { staticClass: "lds-ring" }, [
                    _c("div"),
                    _c("div"),
                    _c("div"),
                    _c("div"),
                  ]),
                ]
              ),
              _c("div", { staticClass: "p-4 text-container" }, [
                _c(
                  "h2",
                  { staticClass: "text-dark text-lg text-center font-bold" },
                  [_vm._v(_vm._s(_vm.title))]
                ),
                _c("p", { staticClass: "text-grey text-center mt-4" }, [
                  _vm._v(_vm._s(_vm.description)),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }