var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _vm.label.length
      ? _c("p", { staticClass: "label-text" }, [
          _vm._v(_vm._s(_vm.label) + " "),
          _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "flex justify-between items-center input input-regular" },
      [
        !_vm.name.length ? _c("p", [_vm._v(_vm._s(_vm.text))]) : _vm._e(),
        _c("p", {}, [_vm._v(_vm._s(_vm._f("truncate")(_vm.name, 30)))]),
        _c(
          "label",
          { class: [_vm.width ? _vm.width : null], attrs: { for: _vm.id } },
          [
            _c("div", [
              _c("div", { staticClass: "upload-button" }, [
                _vm.loading
                  ? _c("div", { staticClass: "lds-dual-ring" })
                  : _c("div", [
                      !_vm.uploaded
                        ? _c(
                            "p",
                            { staticClass: "flex items-center cursor-pointer" },
                            [
                              _c("span", { staticClass: "text-sm mr-2" }, [
                                _vm._v("Attach"),
                              ]),
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "8",
                                    height: "16",
                                    viewBox: "0 0 8 16",
                                    fill: "none",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M6.99984 4.50002V11.5534C6.99984 12.9467 5.97984 14.1867 4.59317 14.32C2.99984 14.4733 1.6665 13.2267 1.6665 11.6667V3.42668C1.6665 2.55335 2.29317 1.76002 3.15984 1.67335C4.15984 1.57335 4.99984 2.35335 4.99984 3.33335V10.3333C4.99984 10.7 4.69984 11 4.33317 11C3.9665 11 3.6665 10.7 3.6665 10.3333V4.50002C3.6665 4.22668 3.43984 4.00002 3.1665 4.00002C2.89317 4.00002 2.6665 4.22668 2.6665 4.50002V10.24C2.6665 11.1133 3.29317 11.9067 4.15984 11.9934C5.15984 12.0934 5.99984 11.3133 5.99984 10.3333V3.44668C5.99984 2.05335 4.97984 0.813349 3.59317 0.680016C2.0065 0.526683 0.666504 1.77335 0.666504 3.33335V11.5134C0.666504 13.4267 2.0665 15.14 3.97317 15.32C6.1665 15.52 7.99984 13.8134 7.99984 11.6667V4.50002C7.99984 4.22668 7.77317 4.00002 7.49984 4.00002C7.2265 4.00002 6.99984 4.22668 6.99984 4.50002Z",
                                      fill: "#1E1452",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _c(
                            "p",
                            {
                              staticClass: "flex items-center cursor-pointer",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteFile()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-sm mr-2 text-brandRed" },
                                [_vm._v("Delete")]
                              ),
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "8",
                                    height: "16",
                                    viewBox: "0 0 8 16",
                                    fill: "none",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M6.99984 4.50002V11.5534C6.99984 12.9467 5.97984 14.1867 4.59317 14.32C2.99984 14.4733 1.6665 13.2267 1.6665 11.6667V3.42668C1.6665 2.55335 2.29317 1.76002 3.15984 1.67335C4.15984 1.57335 4.99984 2.35335 4.99984 3.33335V10.3333C4.99984 10.7 4.69984 11 4.33317 11C3.9665 11 3.6665 10.7 3.6665 10.3333V4.50002C3.6665 4.22668 3.43984 4.00002 3.1665 4.00002C2.89317 4.00002 2.6665 4.22668 2.6665 4.50002V10.24C2.6665 11.1133 3.29317 11.9067 4.15984 11.9934C5.15984 12.0934 5.99984 11.3133 5.99984 10.3333V3.44668C5.99984 2.05335 4.97984 0.813349 3.59317 0.680016C2.0065 0.526683 0.666504 1.77335 0.666504 3.33335V11.5134C0.666504 13.4267 2.0665 15.14 3.97317 15.32C6.1665 15.52 7.99984 13.8134 7.99984 11.6667V4.50002C7.99984 4.22668 7.77317 4.00002 7.49984 4.00002C7.2265 4.00002 6.99984 4.22668 6.99984 4.50002Z",
                                      fill: "#FC3C11",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                    ]),
              ]),
            ]),
            _c("input", {
              staticClass: "hidden",
              attrs: { type: "file", id: _vm.id },
              on: {
                change: function ($event) {
                  $event.stopPropagation()
                  return _vm.uploadImage($event)
                },
              },
            }),
          ]
        ),
      ]
    ),
    _c("p", { staticClass: "text-right text-grey text-xs mt-1" }, [
      _vm._v(_vm._s(_vm.desc)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }