export default {
  creditQualification: {},
  userEmployementInfo: {},
  userBio: {},
  userBanks: [],
  userCashoutAccount: {},
  cashoutEndpointCalled: false,
  userRegistrationTodo: [],
  accountSummary: {},
  allCreditCardFees: {},
  allCheckoutFees: {}
};
