import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import "./plugins/filters";
import "./plugins/freshDesk";
import "./plugins/sendinblue";
import Auth from "@/Layouts/auth.vue";
import Default from "@/Layouts/default.vue";
import Bare from "@/Layouts/bare.vue";
import Card from "@/UI/Card";
import Skeleton from "@/UI/Skeleton";
import axios from "./plugins/axios";
import VueCookies from "vue-cookies";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Hotjar from "vue-hotjar";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import SvgIcon from "@jamescoyle/vue-icon";
import "./plugins/growthbook";
import "./plugins/atlas";
import "@/plugins/tooltip/tooltip.js";
import "@/plugins/tooltip/tooltip.css";
import "@/plugins/clarity";

// sentry config
Sentry.init({
  Vue,
  environment: process.env?.VUE_APP_ENVIRONMENT.toString(),
  dsn: "https://d007deb0026749eeaa6a0127aec02078@o1117373.ingest.sentry.io/6620682",
  attachStacktrace: true,
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    // new Sentry.Replay(),
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      tracingOrigins: ["localhost", "customer.usezilla.com", "customer-webapp-dev.zilla.africa", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

const token = VueCookies.get("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

// set user for plugins
if (store?.state?.auth?.user?.email) {
  Sentry.setUser({ email: store?.state?.auth?.user?.email });
  window && window["clarity"] && window["clarity"]("set", "email", store?.state?.auth?.user?.email );
  window && window["clarity"] && window["clarity"]("set", "id", store?.state?.auth?.user?.id );
}

// initialize plugins in production
if (process.env?.VUE_APP_ENVIRONMENT?.toString() === "production") {
  // atlas
  window.Atlas.start();
  // hotjar configuration
  Vue.use(Hotjar, { 
    id: "3053876",
    isProduction: process?.env?.VUE_APP_ENVIRONMENT?.toString() === "production",
  });

  // firebase config
  const firebaseConfig = {
    apiKey: "AIzaSyDCgxd7vEW2CWR1NTyaMjGx1kQsvZOT6KI",
    authDomain: "shopper-s-webapp.firebaseapp.com",
    projectId: "shopper-s-webapp",
    storageBucket: "shopper-s-webapp.appspot.com",
    messagingSenderId: "651433800926",
    appId: "1:651433800926:web:32e6e34ab536c2ac1ffe4f",
    measurementId: "G-GBXWQVMYLK",
  };
  // Initialize Firebase
  initializeApp(firebaseConfig);
  process.env?.VUE_APP_ENVIRONMENT?.toString() === "production" && getAnalytics();
  Vue.prototype.$analytics = getAnalytics();
}
navigator?.serviceWorker?.getRegistrations().then(function (registrations) {
  for (let registration of registrations) {
    registration.unregister();
  }
});
Vue.component("auth-layout", Auth);
Vue.component("default-layout", Default);
Vue.component("bare-layout", Bare);
Vue.component("Card", Card);
Vue.component("Skeleton", Skeleton);
Vue.component("svg-icon", SvgIcon);

Vue.use(VueCookies);
Vue.prototype.$axios = axios;

// console.log(process.env.NODE_ENV.toString() === "development");
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
