import axios from "@/plugins/axios";

export const ORDER_CARD = (data) => {
    return axios.post(`/bnpl/v1/issued-card/order`, data);
};

export const FIX_CARD_LIMIT = ({ id, data }) => {
    return axios.put(`/issued-card/v1/issued-card/id/${id}/limit`, data);
};

export const GET_CARD_BY_ID = (id) => {
    return axios.get(
        `/issued-card/v1/issued-card/${id}/id/plain`
    );
};

export const CHANGE_CARD_PIN = ({ id, data }) => {
    return axios.put(`/issued-card/v1/issued-card/id/${id}/pin/change`, data);
};

export const BLOCK_CARD = (id) => {
    return axios.put(`/issued-card/v1/issued-card/id/${id}/block`);
};

export const GET_CARD_SPENDING_LIMIT = (id) => {
    return axios.get(
        `/issued-card/v1/issued-card/id/${id}/limit`
    );
};

export const GET_PAYMENT_PLANS = () => {
    return axios.get(
        `/bnpl/v2/payment-plan/get-all/customer-view`
    );
};

export const GET_DEFAULT_PAYMENT_PLAN = (id) => {
    return axios.get(
        `/bnpl/v2/issued-card/${id}`
    );
};

export const UPDATE_DEFAULT_PAYMENT_PLAN = (data) => {
    return axios.put(`/bnpl/v2/issued-card/update-payment-plan`, data);
};

export const GET_ALL_CARDS = (payload) => {
    return axios.get(`/issued-card/v1/issued-card`, { params: payload });
};
