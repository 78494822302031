import axios from "@/plugins/axios";

export const GET_UPCOMING_PAYMENTS = () => {
  return axios.get("/bnpl/recovery-schedule/upcoming-payments/customer-view");
};

export const GET_RECOVERY_SCHEDULES = (payload) => {
  return axios.get(`/bnpl/recovery-schedule/filter-by-principal/customer-view`, { params: payload });
};

export const PAY_NOW = (id, data) => {
  return axios.put(`/bnpl/recovery-schedule/${id}`, data);
};

export const GET_SINGLE_PAYMENT = (id) => {
  return axios.get(`/bnpl/recovery-schedule/${id}/customer-view`);
};

export const PAY_NOW_FROM_PURCHASE = (loanId, data) => {
  return axios.put(`/bnpl/loan/${loanId}/make-payment`, data);
};
