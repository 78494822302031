export const resetState = () => {
  return {
    creditQualification: {},
    userEmployementInfo: {},
    userBio: {},
    userBanks: [],
    userCashoutAccount: {},
    cashoutEndpointCalled: false,
    userRegistrationTodo: [],
    accountSummary: {},
  };
};
