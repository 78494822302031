import {
  GET_WALLET,
  GET_CARDS,
  FUND_WALLET_WITH_CARD,
  VERIFY_BANK,
  TOP_UP_HISTORY,
  DASHBOARD_DATA,
  FEATURED_MERCHANTS,
  WITHDRAW_FROM_WALLET,
  FETCH_DYNAMIC_FORM
} from "@/utils/api/dashboard";
import {
  INCREMENT_SUBCATEGORY_COUNT,
  GET_MERCHANTS_BY_SUBCATEGORY,
  GET_FEATURED_SUBCATEGORIES
} from "@/utils/api/merchant";
import MonoConnect from "@mono.co/connect.js";
import { ONBOARDING_STATUS } from "@/utils/sharedData/home";

export default {
  getUserWallet({ commit }) {
    return new Promise((resolve, reject) => {
      GET_WALLET()
        .then((res) => {
          commit("GET_USER_WALLET", res.data.data);
          resolve(res);
          // console.log(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCards({ commit }) {
    return new Promise((resolve, reject) => {
      GET_CARDS()
        .then((res) => {
          commit("GET_CARDS", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fundWithCard({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      FUND_WALLET_WITH_CARD(payload)
        .then(({ data }) => {
          const status = data.data.status;
          dispatch(
            "notification/showAlert",
            {
              description:
                status === "FAILED"
                  ? "Your attempt to fund your account failed, please contact support for any complaints"
                  : "Your wallet has been successfully funded",
              display: true,
              type: status === "FAILED" ? "error" : "success",
              // title: "Your wallet has been successfully funded",
            },
            { root: true }
          );

          status === "FAILED" ? reject(data) : resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getInstaWalletHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TOP_UP_HISTORY(payload.params)
        .then(({ data }) => {
          // console.log(data.data);x
          payload.type === "paginate"
            ? commit("ADD_INSTAWALLET_HISTORY", data.data)
            : commit("GET_INSTA_WALLET_HISTORY", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getMerchantSubCategories({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_FEATURED_SUBCATEGORIES(payload.params)
        .then(({ data }) => {
          // console.log(data.data);x
          payload.type === "paginate"
            ? commit("ADD_MERCHANT_SUBCATEGORY", data.data)
            : commit("GET_MERCHANT_SUBCATEGORY", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  clearSubCategories({ commit }) {
    commit("GET_MERCHANT_SUBCATEGORY", []);
    commit("GET_FEATURED_MERCHANTS", []);
  },

  getFeaturedMerchantsBySubCategory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_MERCHANTS_BY_SUBCATEGORY(payload.params)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_FEATURED_MERCHANTS", data.data)
            : commit("GET_FEATURED_MERCHANTS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  incrementSubCategoryCount({ }, data) {
    return new Promise((resolve, reject) => {
      INCREMENT_SUBCATEGORY_COUNT(data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  linkBank({ dispatch }) {
    return new Promise((resolve, reject) => {
      const handler = new MonoConnect({
        // onClose: () => returnPro
        onLoad: () => console.log("Widget loaded successfully"),
        onSuccess: ({ code }) => returnPromise(code),
        // dispatch("verifyBank", code).then((res) => {
        //   returnPromise(res);
        // }),
        key: process.env.VUE_APP_MONO_KEY,
      });
      const returnPromise = (value) => {
        return resolve(value);
      };

      handler.setup();
      handler.open();
    });
  },
  verifyBank({ }, code) {
    return new Promise((resolve, reject) => {
      VERIFY_BANK(code)
        .then((res) => {
          // call creditqualification endpoint if value is still false
          // !rootState?.userInfo?.creditqualification?.qualified &&
          //   dispatch("userInfo/getCreditQualification", {}, { root: true });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDashboardData({ commit }) {
    return new Promise((resolve, reject) => {
      DASHBOARD_DATA()
        .then(({ data }) => {
          commit("GET_DASHBOARD_DATA", data.data);
          let customerData = data.data.customerDto;
          customerData.addressDetails = data?.data?.customerAddress
          commit("auth/setUserDetails", customerData, {
            root: true,
          });
          let todos = data?.data?.onboardingSummary?.onboardingSummaryItems;
          if (
            todos.some(
              (todo) => todo.status !== ONBOARDING_STATUS.completed.name
            )
          ) {
            commit("SET_DASHBOARD_ENTRY", true);
          } else {
            commit("SET_DASHBOARD_ENTRY", false);
          }
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getFeaturedMerchants({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FEATURED_MERCHANTS(payload)
        .then(({ data }) => {
          commit("GET_FEATURED_MERCHANTS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  withdrawFromWallet({ }, payload) {
    return new Promise((resolve, reject) => {
      WITHDRAW_FROM_WALLET(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  selectActivateStep({ commit }, payload) {
    commit("SELECT_ACTIVATE_STEP", payload);
  },
  handleShowActivate({ commit }, payload) {
    commit("SHOW_ACTIVATE", payload);
  },
  handleShowReminder({ commit }, payload) {
    commit("SHOW_REMINDER", payload);
  },
  handleKycStep({ commit }, payload) {
    commit("HANDLE_KYC_STEP", payload);
  },
  showKycStep({ commit }, payload) {
    commit("SHOW_KYC_STEP", payload);
  },
  handleCreditQualificationStep({ commit, rootState }, payload) {
    if (rootState?.auth?.user?.identityNumberVerified) {
      commit("HANDLE_CREDIT_QUALIFICATION_STEP", payload);
    }
  },
  showCreditQualificationStep({ commit,}, payload) {
    // if (rootState?.auth?.user?.identityNumberVerified) {
      commit("SHOW_CREDIT_QUALIFICATION_STEP", payload);
    // } else {
    //   dispatch('notification/showAlert', {
    //     description: `Please verify your BVN details in the "Verify your Identity" section before proceeding`,
    //     display: true,
    //     type: "error",
    //     title: "Sorry",
    //     buttonText: "Close",
    //   },
    //     { root: true }
    //   )
    // }
  },
  getDynamicFormData({ commit }) {
    return new Promise((resolve, reject) => {
      FETCH_DYNAMIC_FORM()
        .then((res) => {
          commit('SET_DYNAMIC_FORM_DATA', res.data.data)
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  handleShowPersonalDetails({ commit }, payload) {
    commit('HANDLE_SHOW_PERSONAL_DETAILS', payload)
  }

};
