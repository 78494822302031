<template>
  <div>
    <div class="grid md:grid-cols-2 gap-4 mt-6" v-if="loading">
      <Card v-for="(pay, index) in 2" :key="'pay' + index">
        <Skeleton width="70%" />
        <Skeleton width="10%" height="1.5rem" />
      </Card>
      <!-- <Skeleton
        height="5.6rem"
        width="100%"
        v-for="(pay, index) in 2"
        :key="'pay' + index"
      /> -->
    </div>
    <div class="grid md:grid-cols-2 gap-4 mt-6" v-else>
      <Card>
        <div
          class="flex items-center justify-between cursor-pointer"
          @click="$router.push('/purchases')"
        >
          <div>
            <p class="font-semibold">
              Upcoming Payments
              <span class="text-blue"
                >({{ statistics.upcomingPaymentsCount }})</span
              >
            </p>
            <p class="font-bold">
              {{ statistics.totalUpcomingPayments | formatMoney }}
            </p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="12"
            fill="none"
          >
            <path
              d="M1.28957.70978c-.39.39-.39 1.02 0 1.41l3.88 3.88-3.88 3.88c-.39.39002-.39 1.02002 0 1.41002.39.39 1.02.39 1.41 0l4.59-4.59002c.39-.39.39-1.02 0-1.41l-4.59-4.59c-.38-.38-1.02-.38-1.41.01Z"
              fill="#000"
              fill-opacity=".54"
            />
          </svg>
        </div>
      </Card>
      <Card>
        <div
          class="flex items-center justify-between cursor-pointer"
          @click="$router.push('/purchases')"
        >
          <div>
            <p class="font-semibold">
              Overdue Payments
              <span class="text-brandRed"
                >({{ statistics.overduePaymentsCount }})</span
              >
            </p>
            <p class="font-bold">
              {{ statistics.totalOverduePayments | formatMoney }}
            </p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="12"
            fill="none"
          >
            <path
              d="M1.28957.70978c-.39.39-.39 1.02 0 1.41l3.88 3.88-3.88 3.88c-.39.39002-.39 1.02002 0 1.41002.39.39 1.02.39 1.41 0l4.59-4.59002c.39-.39.39-1.02 0-1.41l-4.59-4.59c-.38-.38-1.02-.38-1.41.01Z"
              fill="#000"
              fill-opacity=".54"
            />
          </svg>
        </div>
      </Card>
    </div>
  </div>
</template>
<script>
  import Skeleton from "@/UI/Skeleton";
  export default {
    components: {
      Skeleton,
    },
    props: {
      statistics: {
        type: Object,
        default: () => {
          overduePaymentsCount: 0;
          totalOverduePayments: 0;
          totalUpcomingPayments: 0;
          upcomingPaymentsCount: 0;
        },
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
  };
</script>
