<template>
  <div
    class="card transition-all"
    :class="[
      p,
      background.includes('bg-') ? background : `bg-${background}`,
      shadow ? 'shadow' : null,
      disabled ? 'pointer-events-none cursor-no-drop opacity-50' : null,
    ]"
    :style="{'border-radius': isNaN(radius) ? radius : `${radius}px`}"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    p: {
      type: String,
      default: "p-6",
    },
    background: {
      type: String,
      default: "bg-white",
    },
    shadow: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    radius:{
      type:[String,Number],
      default: '8px',
      required: false
    }
  },
};
</script>
<style scoped>
.card {
  border-radius: 8px;
}
.shadow {
  box-shadow: 3px 6px 12px rgba(30, 29, 32, 0.06);
}
</style>
