<template>
  <hr
    class="my-2"
    :style="{ background: color, border: `0.5px solid ${color}` }"
  />
</template>
<script>
  export default {
    props: {
      color: {
        type: String,
        default: "#EDEDF0",
        required: false,
      },
    },
  };
</script>