import {
  GET_RECOVERY_SCHEDULES,
  GET_UPCOMING_PAYMENTS,
  GET_SINGLE_PAYMENT
} from "@/utils/api/repayments.js";

export default {
  getRecoverySchedules({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_RECOVERY_SCHEDULES(payload.params)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_RECOVERY_SCHEDULES", data.data)
            : commit("GET_RECOVERY_SCHEDULES", data.data);
          resolve({ data });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUpcomingPayments({ commit }) {
    return new Promise((resolve, reject) => {
      GET_UPCOMING_PAYMENTS()
        .then(({ data }) => {
          commit("GET_UPCOMING_PAYMENTS", data.data);
          // console.log("repayments", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSingleSchedule({ commit }, id) {
    return new Promise((resolve, reject) => {
      GET_SINGLE_PAYMENT(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
};
