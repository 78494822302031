import {
  GET_ALL_BANKS,
  CREATE_CASHOUT_ACCOUNT,
  BANK_ACCOUNT_LOOKUP,
  VERIFY_OTP
} from "@/utils/api/settings";;

export default {
  getAllBanks({ commit }) {
    return new Promise((resolve, reject) => {
      GET_ALL_BANKS()
        .then((res) => {
          commit("GET_ALL_BANKS", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  accountLookup({}, payload) {
    return new Promise((resolve, reject) => {
      BANK_ACCOUNT_LOOKUP(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createCashoutAccount({}, payload) {
    return new Promise((resolve, reject) => {
      CREATE_CASHOUT_ACCOUNT(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  verifyCashoutAccount({}, payload) {
    return new Promise((resolve, reject) => {
      VERIFY_OTP(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
