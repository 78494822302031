export default {
  SET_LOADING(state, payload) {
    const test = {};
    if (typeof payload === "object" && payload !== null) {
      // 👇️ this runs
      state.loading = payload;
    } else {
      state.loading.display = payload;
    }
    // reset all values if loading = false
    if (!payload) {
      state.loading = {
        display: false,
        title: "",
        description: "",
      };
    }
  },
};
