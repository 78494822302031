import axios from "@/plugins/axios";

export const LOGIN = (data) => {
  return axios.post(`/bnpl/auth`, data);
};

export const REGISTER = (data) => {
  return axios.post(`/bnpl/customer-registration`, data);
};

export const SUBMIT_PASSWORD = (data) => {
  return axios.put(`/bnpl/customer-registration/submit-password`, data);
};
export const ADD_NICKNAME = (data) => {
  return axios.put(
    `/bnpl/v2/customer-registration/submit-nickname-and-complete`,
    data
  );
};

export const PORT_MERCHANT = () => {
  return axios.get(`/bnpl/customer-registration/port-merchant`);
};

// deprecated
// export const VERIFY_PHONE_BANK = (data) => {
//   return axios.put(
//     `/bnpl/customer-registration/initiate-account-number-validation`,
//     data
//   );
// };
// deprecated
// export const VERIFY_PHONE = (data) => {
//   return axios.put(`/bnpl/customer-registration/validate-account-number`, data);
// };

export const FORGOT_PASSWORD = (data) => {
  return axios.put(`/volt/password-change/initiate-password-reset`, data);
};

export const PASSWORD_OTP = (data) => {
  return axios.put(`/volt/password-change/submit-password-reset-otp`, data);
};

export const PASSWORD_RESET = (data) => {
  return axios.put(`/volt/password-change/complete-password-reset`, data);
};

export const NEW_PASSWORD = (data) => {
  return axios.put(`/volt/password-change/change-password`, data);
};
// deprecated
// export const VALIDATE_IDENTITY_NUMBER = (data) => {
//   return axios.put(
//     `/bnpl/customer-registration/validate-identity-number`,
//     data
//   );
// };

export const BVN_LOOKUP = (bvn) => {
  return axios.put(`bnpl/customer-registration/bvn/${bvn}/lookup`);
};
// deprecated
export const SEND_BVN_OTP = (bvn) => {
  return axios.put(`/bnpl/customer-registration/bvn/${bvn}/send-otp`);
};

export const RESEND_BVN_OTP_PORT = (id) => {
  return axios.get(
    `/bnpl/customer-registration/port-merchant/resend-otp-to-phone/${id}`
  );
};
// deprecated
// export const VALIDATE_BVN_OTP = (data) => {
//   return axios.put(`/bnpl/customer-registration/validate-bvn-with-otp`, data);
// };

// deprecated
// export const VALIDATE_BVN_SELFIE = (data) => {
//   return axios.put(
//     `/bnpl/customer-registration/validate-bvn-with-selfie
//   `,
//     data
//   );
// };

export const VERIFY_BANK_PHONE = (data) => {
  return axios.put(`/bnpl/customer-registration/validate-account-number`, data);
};


export const GET_GROWTHBOOK_DEFINITIONS = () => {
  return axios.get(`https://cdn.growthbook.io/api/features/${process.env.VUE_APP_GROWTHBOOK_API}`)
}