var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "show" } }, [
    _vm.display
      ? _c(
          "div",
          {
            staticClass: "toast-container rounded-lg",
            class: {
              success: _vm.icon === "success",
              error: _vm.icon === "error",
            },
          },
          [
            _c("div", { staticClass: "toast" }, [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.icon === "success",
                    expression: "icon === 'success'",
                  },
                ],
                staticClass: "w-6 h-6 mr-2",
                attrs: {
                  src: require("@/assets/icons/toastSuccess.svg"),
                  alt: "success icon",
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.icon === "warning",
                    expression: "icon === 'warning'",
                  },
                ],
                staticClass: "w-6 h-6 mr-2",
                attrs: { src: require("@/assets/icons/warning.svg"), alt: "" },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.icon === "error",
                    expression: "icon === 'error'",
                  },
                ],
                staticClass: "w-6 h-6 mr-2",
                attrs: {
                  src: require("@/assets/icons/errorIcon.svg"),
                  alt: "",
                },
              }),
              _c("p", { staticClass: "text-xs md:text-sm text-center" }, [
                _vm._v(_vm._s(_vm.description)),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }