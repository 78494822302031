export default {
  methods: {
    getImage(id, defaultImage) {
      if (id) {
        return `${process.env.VUE_APP_BASE_URL}/media/image/${id}`;
      } else if (defaultImage) {
        return defaultImage;
      } else {
        return `https://res.cloudinary.com/zillaafrica/image/upload/v1631281213/customer/Group_12946_yr3hik.svg`;
      }
    },
  },
};
