import axios from "@/plugins/axios";
import cookies from "vue-cookies";
import router from "@/router";
import { ONBOARDING_STATUS } from "@/utils/sharedData/home";
import * as Sentry from "@sentry/vue";

// import { GrowthBook } from "@growthbook/growthbook";

import {
  LOGIN,
  REGISTER,
  SUBMIT_PASSWORD,
  // VERIFY_PHONE_BANK,
  // VERIFY_PHONE,
  FORGOT_PASSWORD,
  PASSWORD_OTP,
  PASSWORD_RESET,
  NEW_PASSWORD,
  BVN_LOOKUP,
  // VALIDATE_BVN_OTP,
  // VALIDATE_BVN_SELFIE,
  SEND_BVN_OTP,
  PORT_MERCHANT,
  ADD_NICKNAME,
  GET_GROWTHBOOK_DEFINITIONS,
} from "@/utils/api/auth";
// import { BVN_LOOKUP } from "@/utils/api/lookup";
// import { reject } from "core-js/library/es6/promise";

export default {
  register({ commit }, user) {
    return new Promise((resolve, reject) => {
      REGISTER(user)
        .then((response) => {
          commit("SET_REGISTRATION", response.data.data.registrationId);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setEmploymentInfo({ commit }, payload) {
    commit("SET_EMPLOYMENT_INFO", payload);
  },

  submitPassword({ commit }, user) {
    return new Promise((resolve, reject) => {
      SUBMIT_PASSWORD(user)
        .then((response) => {
          const token = response.data.data.authResponse.token;
          cookies.set("token", token, "2h");
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  portMerchant({ commit }) {
    return new Promise((resolve, reject) => {
      PORT_MERCHANT()
        .then((res) => {
          commit("SET_REGISTRATION", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verifyPhoneBank({ commit }, user) {
    return new Promise((resolve, reject) => {
      VERIFY_PHONE_BANK(user)
        .then((res) => {
          router.push("/verify-phone");
          commit("SAVE_BANK_DATA", res.data.data);
          // console.log(res);
          resolve(res);
        })
        .catch((err) => {
          // console.log(err);
          reject(err);
        });
    });
  },
  verifyPhone(payload) {
    VERIFY_PHONE(payload)
      .then((res) => {
        // console.log("verify-phone", res);
      })
      .catch((err) => console.log(err));
  },
  login({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit("AUTH_REQUEST");
      LOGIN(user)
        .then((response) => {
          const token = response?.data?.data?.authResponse?.token;
          const tokenExpire = response?.data?.data?.authResponse?.tokenExpireAt;
          cookies.set("token", token);
          cookies.set("tokenExpire", tokenExpire);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          // if user has an acc but has not completed registration
          if (response?.data?.data?.userCategory === "CUSTOMER_REGISTRATION") {
            commit("setUserDetails", {
              email: user.username,
              password: user.secret,
            });
            resolve(response?.data?.data);
          } else if (response?.data?.data?.userCategory === "MERCHANT_USER") {
            resolve(response?.data?.data);
            commit("setUserDetails", {
              email: user.username,
              password: user.secret,
            });
          } else if (response?.data?.data?.userCategory === "MERCHANT_REGISTRATION") {
            resolve(response?.data?.data);
            commit("setUserDetails", {
              email: user.username,
              password: user.secret,
            });
          } else {
            // set token and user details
            commit("AUTH_SUCCESS", token);
            commit("setUserDetails", response.data.data.customer);
            commit("SET_TOKEN_EXPIRE", tokenExpire);
            commit('SET_CRREDIT_PERMISSION', response?.data?.data?.disableCreditPayments)
            // set sentry user
            Sentry.setUser({ email: response.data.data.customer?.email });
            window && window["clarity"] && window["clarity"]("set", "email", response?.data?.data?.customer?.email);
            window && window["clarity"] && window["clarity"]("set", "id", response?.data?.data?.customer?.id);

            // call to get the user credit worthiness data
            dispatch("userInfo/getCreditQualification", { type: "dashboard" }, { root: true })
              .then(() => {
                router.push("/");
                dispatch('handleShowOnboardingModal');
                resolve(response);
              })
              .catch((err) => {
                reject(err);
              });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(
              "notification/showAlert",
              {
                display: true,
                type: "error",
                description: error.response.data.message,
              },
              { root: true }
            );
          }
          commit("AUTH_ERROR", error);
          reject(error);
        });
    });
  },
  resetStoreStates({ commit }) {
    commit("AUTH_LOGOUT");
    commit("userInfo/SET_RESET_USERINFO_STATE", null, { root: true });
    commit("dashboard/SET_RESET_DASHBOARD_STATE", null, { root: true });
    commit("purchases/RESET_PURCHASE_STATE", null, { root: true });
    commit("repayments/RESET_REPAYMENT_STATE", null, { root: true });
    commit("card/RESET_CARD_MODULE_STATE", null, { root: true });
  },
  logout({ commit, dispatch }) {
    dispatch('resetStoreStates')
    Sentry.setUser(null);
    cookies.remove("token");
    cookies.remove("cookieName");
    localStorage.clear();
    window.localStorage.removeItem("tunnel");
    delete axios.defaults.headers.common["Authorization"];
    router.push("/login");
  },
  forgotPassword({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      FORGOT_PASSWORD(payload)
        .then((response) => {
          // console.log(response);
          commit("CHANGE_PASSWORD_ID", response?.data?.data?.id);
          resolve(response);
        })
        .catch((error) => {
          // console.log(error, "err");

          if (error?.response?.status === 404) {
            console.log(error, "err");
          }
          dispatch(
            "notification/showAlert",
            {
              display: true,
              type: "error",
              description: error.response.data.message,
            },
            { root: true }
          );
          reject(error);
        });
    });
  },

  setOtpId({ commit }, payload) {
    commit("CHANGE_PASSWORD_ID", payload);
  },

  verifyPasswordResetOtp({ }, payload) {
    return new Promise((resolve, reject) => {
      PASSWORD_OTP(payload)
        .then((response) => {
          router.push("/create-password");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  completePasswordReset({ }, payload) {
    return new Promise((resolve, reject) => {
      PASSWORD_RESET(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  handleShowOnboardingModal({ dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch("dashboard/getDashboardData", null, { root: true })
        .then((response) => {
          let completion = response?.data?.onboardingSummary?.percentCompletion;
          if (
            parseInt(completion) != 100
          ) {
            dispatch("dashboard/handleShowReminder", true, { root: true });
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },

  changePassword({ }, payload) {
    return new Promise((resolve, reject) => {
      NEW_PASSWORD(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  bvnLookup({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      BVN_LOOKUP(payload)
        .then((res) => {
          commit("BVN_LOOKUP", { number: payload, bvnData: res.data.data });
          resolve(res);
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            console.log(err, "err");
            dispatch(
              "notification/showAlert",
              {
                display: true,
                type: "error",
                description: err.response.data.message,
              },
              { root: true }
            );
          }

          reject(err);
        });
    });
  },
  sendBvnOtp({ }, payload) {
    return new Promise((resolve, reject) => {
      SEND_BVN_OTP(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  validateBvnOtp({ }, payload) {
    return new Promise((resolve, reject) => {
      VALIDATE_BVN_OTP(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  validateBvnSelfie({ }, payload) {
    return new Promise((resolve, reject) => {
      VALIDATE_BVN_SELFIE(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addNickname({ }, payload) {
    return new Promise((resolve, reject) => {
      ADD_NICKNAME(payload)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  completeAnonymousRegistration({ commit }, payload) {
    commit("setUserEmail", payload.email);
    commit("SET_REGISTRATION", payload.id);
  },

  // getGrowthBookFeatures({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     GET_GROWTHBOOK_DEFINITIONS().then(({data}) => {
  //       console.log(data);
  //       growthbook.setFeatures(data.features)
  //     });
  //   });
  // },
};
