import axios from "@/plugins/axios";

export const GET_WALLET = () => {
  return axios.get(`/bnpl/customer/wallets`);
};

export const GET_CARDS = () => {
  return axios.get(`/wallet/cards/user`);
};

export const SET_DEFAULT_CARD = (cardId) => {
  return axios.put(`/wallet/cards/${cardId}/primary`);
};

export const FUND_WALLET_WITH_CARD = (data) => {
  return axios.put(`/bnpl/customer/topup`, data);
};
export const VERIFY_BANK = (code) => {
  return axios.post(`/verification/linked-account/vendor/MONO`, {
    authenticationId: code,
  });
};

export const TOP_UP_HISTORY = (payload) => {
  return axios.get(`/bnpl/customer/instapay-history`, { params: payload });
};

export const DASHBOARD_DATA = () => {
  return axios.get("/bnpl/v3/dashboard/web");
};

export const FEATURED_MERCHANTS = (businessCategory) => {
  return axios.get(
    `/bnpl/dashboard/v1/business-category/${businessCategory}/featured-merchants`
  );
};

export const WITHDRAW_FROM_WALLET = (data) => {
  return axios.post(`/bnpl/customer/cashout`, data);
};

export const FETCH_DYNAMIC_FORM =  () => {
  return axios.get(`/bnpl/v1/forms`)
}

export const onboardingSummary = () => {
  return axios.get(`/bnpl/v3/dashboard/onboarding-summary`)
}