import { initialState } from "./defaultState";
export default {
  GET_USER_WALLET(state, payload) {
    state.userWallet = payload;
  },
  GET_CARDS(state, payload) {
    state.userCards = payload;
    // console.log(state.userCards);
  },
  SELECT_ACTIVATE_STEP(state, payload) {
    state.activateTab = payload;
  },
  SHOW_ACTIVATE(state, payload) {
    state.showActivate = payload;
  },
  GET_INSTA_WALLET_HISTORY(state, payload) {
    state.instaPayWalletHistory = payload;
  },
  ADD_INSTAWALLET_HISTORY(state, payload) {
    state.instaPayWalletHistory.push(...payload);
  },
  SHOW_REMINDER(state, payload) {
    state.showReminder = payload;
  },
  GET_DASHBOARD_DATA(state, payload) {
    state.dashboardData = payload;
  },
  GET_FEATURED_MERCHANTS(state, payload) {
    state.featuredMerchants = payload;
  },
  ADD_FEATURED_MERCHANTS(state, payload) {
    state.featuredMerchants.push(...payload);
  },
  HANDLE_KYC_STEP(state, payload) {
    state.kycSteps.activeTab = payload;
  },
  SHOW_KYC_STEP(state, payload) {
    state.kycSteps.display = payload;
  },
  HANDLE_CREDIT_QUALIFICATION_STEP(state, payload) {
    state.creditQualificationSteps.activeTab = payload;
  },
  SHOW_CREDIT_QUALIFICATION_STEP(state, payload) {
    state.creditQualificationSteps.display = payload;
  },
  SET_RESET_DASHBOARD_STATE(state) {
    Object.assign(state, initialState());
  },
  GET_MERCHANT_SUBCATEGORY(state, payload) {
    state.subCategoryList = payload;
  },
  ADD_MERCHANT_SUBCATEGORY(state, payload) {
    state.subCategoryList.push(...payload);
  },
  SET_DYNAMIC_FORM_DATA(state, payload) {
    state.personalDetails.dynamicForm = payload;
  },
  HANDLE_SHOW_PERSONAL_DETAILS(state, payload) {
    state.personalDetails.display = payload;
  },
  SET_DASHBOARD_ENTRY(state, payload) {
    state.showDashboardEntry = payload;
  },
  REMOVE_COMPLETED_TODO(state, payload) {
    // if(state?.dashboardData?.)
    const index = state?.dashboardData?.customerTodos.findIndex((item) => item.key === payload.key);
    state.dashboardData.customerTodos.splice(index, 1);
  },
  SET_DASHBOARD_COMPLETION(state){
    state.dashboardData.onboardingSummary.percentCompletion = 100
  }
};
