var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c("Card", { staticClass: "w-full md:shadow", attrs: { shadow: "" } }, [
        _vm.displaySuccess
          ? _c("div", { key: "form", staticClass: "pb-2 md:pb-4 mx-auto" }, [
              _c("div", {}, [
                _c(
                  "h4",
                  {
                    staticClass:
                      "hidden md:block font-bold md:text-xl text-xl text-center md:text-left",
                  },
                  [_vm._v(" Let’s start with your email ")]
                ),
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.initiateRegistration.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("Input", {
                      staticClass: "mt-10",
                      attrs: {
                        type: "email",
                        id: "email",
                        placeholder: "What's your email?",
                        width: "w-full",
                        errorText: "Please enter a valid email address",
                        validation: _vm.rules.email,
                      },
                      on: {
                        valid: function ($event) {
                          _vm.valid.email = $event
                        },
                      },
                      model: {
                        value: _vm.user.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "email", $$v)
                        },
                        expression: "user.email",
                      },
                    }),
                    _c("Button", {
                      staticClass: "mt-12",
                      attrs: {
                        text: "Create shopper's account",
                        width: "w-full",
                        shadow: "",
                        loading: _vm.loading,
                        disabled: _vm.disableButton,
                        type: "submit",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-8 justify-center items-center flex" },
                  [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center text-grey text-sm flex justify-center",
                        },
                        [
                          _vm._v(" Already have an account? "),
                          _c("router-link", { attrs: { to: "/login" } }, [
                            _c("span", { staticClass: "flex items-center" }, [
                              _c(
                                "span",
                                { staticClass: "text-brandPurple mx-3" },
                                [_vm._v("Sign in here ")]
                              ),
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "16",
                                    height: "8",
                                    viewBox: "0 0 16 8",
                                    fill: "none",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M12.01 3.00057H1C0.45 3.00057 0 3.45057 0 4.00057C0 4.55057 0.45 5.00057 1 5.00057H12.01V6.79057C12.01 7.24057 12.55 7.46057 12.86 7.14057L15.64 4.35057C15.83 4.15057 15.83 3.84057 15.64 3.64057L12.86 0.850567C12.55 0.530567 12.01 0.760566 12.01 1.20057V3.00057Z",
                                      fill: "#6B6873",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }