import { defaultState } from "./defaultPurchaseState";

export default {
  GET_PURCHASES(state, payload) {
    // console.log(payload);
    state.getPurchases = payload;
  },
  ADD_PURCHASES(state, payload) {
    state.getPurchases.push(...payload);
  },
  GET_PURCHASE_HISTORY(state, payload) {
    state.purchaseHistory.push(...payload);
  },
  GET_CATEGORY_PURCHASE_HISTORY(state, payload) {
    state.productPurchasesHistory.push(...payload);
  },
  GET_MERCHANT_PURCHASE_HISTORY(state, payload) {
    state.categoryPurchaseHistory.push(...payload);
  },
  ADD_PURCHASE_HISTORY(state, payload) {
    state.purchaseHistory.push(...payload);
  },
  RESET_PURCHASE_STATE(state, payload) {
    Object.assign(state, defaultState());
  },
};
