<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        class="w-full grid md:grid-cols-2 grid-cols-1 gap-4"
        v-if="loading"
        key="card"
      >
        <Card v-for="i in 2" :key="i">
          <Skeleton height="1rem" width="40%" />
          <Skeleton height="1.5rem" width="30%" class="mt-2" />
          <Skeleton height="1rem" width="80%" class="mt-4" />
        </Card>
      </div>
      <div
        class="grid balance-grid md:grid-cols-2 grid-cols-1 gap-4"
        v-else
        key="no card"
      >
        <div class="flex rounded-xl p-6 firstCard">
          <div class="w-1/2 md:w-full">
            <p class="text-white">Total Balance</p>
            <h3
              class="mt-1 md:mt-2.5 text-xl text-white font-bold"
              v-if="wallet.creditWallet"
            >
              {{ (zillaBalance + wallet.instaPayWallet.balance) | formatMoney }}
            </h3>
            <h3 class="mt-1 md:mt-2.5 text-xl text-white font-bold" v-else>
              {{ 0 | formatMoney }}
            </h3>
          </div>
          <div class="flex items-center justify-end lg:hidden w-full">
            <SecondaryButton
              text="View Details"
              @click="showFundHistory = true"
            />
          </div>
        </div>
        <Card class="md:flex hidden" p="p-6" v-if="!loading">
          <div class="flex items-center">
            <div class="credit">
              <p class="text-brandPurple">Credit Balance
                <span
                v-if="wallet.instaPayWallet"
                class="cursor-pointer"
                v-tooltip="{
                  content: getCreditFinancer(),
                  placement:'top',
                  trigger:'click',
                  autiHide: true
                }"
              >
                <svg-icon
                  class="inline-block"
                  color="#6956C8"
                  size="15"
                  type="mdi"
                  :path="svgPath.info"
                ></svg-icon>
              </span>
              </p>
              <h3
                class="mt-1 md:mt-2.5 text-xl text-brandPurple font-bold"
                v-if="wallet.instaPayWallet"
              >
                {{ zillaBalance | formatMoney }}
              </h3>
              <h3 class="mt-1 md:mt-2.5 text-xl text-black font-bold" v-else>
                {{ 0 | formatMoney }}
              </h3>
            </div>
            <div class="line"></div>
            <div class="wallet">
              <p class="text-brandPurple">Wallet Balance</p>
              <h3
                class="mt-1 md:mt-2.5 text-xl text-brandPurple font-bold"
                v-if="wallet.instaPayWallet"
              >
                {{ wallet.instaPayWallet.balance | formatMoney }}
              </h3>
              <h3 class="mt-1 md:mt-2.5 text-xl text-black font-bold" v-else>
                {{ 0 | formatMoney }}
              </h3>
            </div>
            <SecondaryButton
              text="View details"
              @click="showFundHistory = true"
            />

            <div class="flex flex-wrap justify-between items-center"></div>
          </div>
        </Card>
        <!-- modal for activating zilla -->

        <!-- modal to handle funding account -->
        <Modal
          :display="showFundAccount"
          @close="closeFundWallet"
          title="Add money to account"
        >
          <FundAccount
            @close="(showFundAccount = false), (showFundHistory = true)"
          />
        </Modal>

        <!-- modal to fund history -->
        <Modal
          :display="showFundHistory"
          @close="showFundHistory = false"
          title="Your Wallet"
          size="large"
        >
          <FundHistory
            @fund="(showFundHistory = false), (showFundAccount = true)"
            @withdraw="(showFundHistory = false), (showWithdrawal = true)"
            :balance="wallet.instaPayWallet.balance"
            :creditBalance="zillaBalance"
          />
        </Modal>

        <Modal
          :display="showWithdrawal"
          @close="(showWithdrawal = false), (showFundHistory = true)"
          title="Withdrawal"
        >
          <Withdrawal
            :noCashoutAccount="noCashoutAccount"
            :cashoutAccount="cashoutAccount"
            @completed="(showWithdrawal = false), (showFundHistory = true)"
          />
        </Modal>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Button, SecondaryButton } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
import {  mdiInformationOutline } from "@mdi/js";
export default {
  components: {
    Button,
    SecondaryButton,
    Modal,
    FundAccount: () => import("../FundAccount"),
    FundHistory: () => import("../FundHistory/fund.vue"),
    Withdrawal: () => import("../Withdrawal"),
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data: () => ({
    // showActivate: false,
    showFundAccount: false,
    // loading: true,
    showFundHistory: false,
    showWithdrawal: false,
    // cashoutAccount: {},
    noCashoutAccount: false,
    svgPath: {
        info: mdiInformationOutline,
      },
  }),
  computed: {
    ...mapState({
      wallet: (state) => state?.dashboard?.userWallet,
      showActivate: (state) => state?.dashboard?.showActivate,
      creditQualification: (state) => state?.userInfo?.creditQualification,
      customerId: (state) => state?.auth?.user?.id,
      cashoutAccount:(state) => state?.userInfo?.userCashoutAccount,
      cashoutEndpointCalled:(state) => state?.userInfo?.cashoutEndpointCalled
    }),

    repaymentBalance() {
      const zillaWallet = this.wallet?.creditWallet;
      if (Math.sign(zillaWallet?.balance) === 1) {
        return 0;
      } else {
        return Math.abs(zillaWallet?.balance);
      }
    },
    zillaBalance() {
      const balance =
        this.wallet?.creditWallet.balance +
        this.wallet?.creditWallet.creditLimit;
      return balance <= 0 ? 0 : balance;
    },
  },

  mounted() {
    if(Object.keys(this.cashoutAccount).length === 0 && !this.cashoutEndpointCalled){
      this.getCashoutAccountInfo();
    }
  },
  methods: {
    ...mapActions("notification", ["showAlert"]),
    ...mapActions("dashboard", [
      "getUserWallet",
      "handleShowActivate",
      "selectActivateStep",
    ]),
    ...mapActions("userInfo", ["getCreditQualification", "getCashoutAccount"]),
    ...mapActions("loading", ["setLoading"]),
    getCashoutAccountInfo() {
      // console.log("id", this.customerId);
      this.getCashoutAccount(this.customerId)
        .then(() => {
          // this.cashoutAccount = res.data.data;
        })
        .catch((err) => {
          if (err.response.data.message === "Cashout bank account not found") {
            this.noCashoutAccount = true;
          }
        });
    },
    getCreditFinancer(){
      return `Credit financed by ${this.creditQualification?.eligibilityCriteriaResult?.loanProviderName}`
    },
    closeFundWallet() {
      this.getUserWallet();
      this.showFundAccount = false;
      this.showFundHistory = true
    },
    handleCloseActivate() {
      this.setLoading(true);
      this.getCreditQualification()
        .then(() => {
          this.getUserWallet();
          this.setLoading(false);
          this.handleShowActivate(false);
        })
        .catch(() => {
          this.setLoading(false);
          this.handleShowActivate(false);
        });
    },
    handleCloseActivate() {
      this.setLoading(true);
      this.getCreditQualification()
        .then(() => {
          this.getUserWallet();
          this.setLoading(false);
          this.handleShowActivate(false);
        })
        .catch(() => {
          this.setLoading(false);
          this.handleShowActivate(false);
        });
    },
    // handleActivateZilla() {
    //   // this.selectActivateStep(3);
    //   // this.handleShowActivate(true);
    //   // to make sure that if a user has completed a step, we take him to the next step instead of starting from the beginning
    //   const qualification = this.creditQualification?.preConditionResult;
    //   const PHONE = qualification?.PHONE_NUMBER_CHECK?.eligibilityStatus;
    //   const ADDRESS =
    //     qualification?.CONTACT_INFORMATION_CHECK?.eligibilityStatus;
    //   const EMPLOYMENT =
    //     qualification?.EMPLOYMENT_STATUS_CHECK?.eligibilityStatus;
    //   const SELFIE = qualification?.SELFIE_CHECK?.eligibilityStatus;
    //   const CARD = qualification?.DEBIT_CARD_CHECK?.eligibilityStatus;
    //   const BANK =
    //     this.creditQualification?.eligibilityCriteriaResult
    //       ?.eligibilityStatus;
    //   const arrayOfQualification = [
    //     PHONE,
    //     ADDRESS,
    //     EMPLOYMENT,
    //     SELFIE,
    //     CARD,
    //     BANK,
    //   ];
    //   for (let i = 0; i < arrayOfQualification.length; i++) {
    //     if (arrayOfQualification[i] === "NOT_ELIGIBLE") {
    //       this.selectActivateStep(i + 1);
    //       this.handleShowActivate(true);
    //       break;
    //     }
    //   }
    // },
    beforeDestroy() {
      this.selectActivateStep(1);
      this.handleShowActivate(false);
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.firstCard {
  background: linear-gradient(
      217.04deg,
      #c6003b -191.09%,
      #6a30c3 77.43%,
      #63dfdf 156.06%
    ),
    #6a30c3;
  /* background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/v1645644541/customer/CARD_yocah6.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.direction {
  transform: rotate(-90deg);
}
.repayment-balance {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
}

.line {
  height: 3.25rem;
  background: #ededf0;
  border: 1px solid #ededf0;
  margin-right: 1.5rem;
}
.credit {
  margin-right: 4.938rem;
}
.wallet {
  margin-right: 2.344rem;
}
@media (min-width: 768px) {
  .balance-grid {
    grid-template-columns: 30% 1fr;
  }
}
</style>