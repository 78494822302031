import Vue from "vue";
import dayjs from "dayjs";

Vue.filter("formatMoney", function (value) {
  let formattedNumber;
  if (value === null || value === undefined) {
    formattedNumber = 0;
  } else {
    formattedNumber = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      currencyDisplay: "symbol",
    })
      .format(value)
      .replace(/(\.|,)00$/g, "");
  }

  return formattedNumber;
});

Vue.filter("timeBetween", function (date) {
  //Get 1 day in milliseconds
  var one_day = 1000 * 60 * 60 * 24;
  var one_week = 1000 * 60 * 60 * 24 * 7;

  // Convert both dates to milliseconds
  var date1_ms = new Date();
  var date2_ms = new Date(date).getTime();

  // Calculate the difference in milliseconds
  var difference_ms = date2_ms - date1_ms;

  let summary = "";
  if (Math.round(difference_ms / one_day) > -7 && Math.round(difference_ms / one_day) < 7) {
    if (Math.round(difference_ms / one_day) < 1) {
      summary = `${Math.abs(Math.round(difference_ms / one_day))} ${Math.round(difference_ms / one_day) == -1 ? 'day' : 'days'} ago`;
    }
    else {
      summary = `in ${Math.round(difference_ms / one_day)} ${Math.round(difference_ms / one_day) == 1 ? 'day' : 'days'}`;
    }
  } else {
    if (Math.round(difference_ms / one_week) < 1) {
      summary = `${Math.abs(Math.round(difference_ms / one_week))} ${Math.round(difference_ms / one_week) == -1 ? 'week' : 'weeks'} ago`;
    }
    else {
      summary = `in ${Math.round(difference_ms / one_week)} ${Math.round(difference_ms / one_week) == 1 ? 'week' : 'weeks'}`;
    }
  };

  return summary
})

Vue.filter("formatDate", function (value) {
  if (!value) return null;
  return dayjs(value);
});

Vue.filter("renamePlan", function (value) {
  let style;
  switch (value.toLowerCase()) {
    case "flexible":
      style = "Flexible";
      break;
    case "pay-twice":
      style = "Pay twice";
      break;
    case "pay-in-three":
      style = "Pay in 3";
      break;
    case "pay-in-full":
      style = "Pay once";
      break;
  }
  return style
})

Vue.filter("spaceCardNumber", function (value) {
  let card = value.match(/.{1,4}/g);
  return card.join(" ");
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
});

Vue.directive("click-outside", {
  bind: function (element, binding, vnode) {
    element.clickOutsideEvent = function (event) {
      //  check that click was outside the el and his children
      if (!(element === event.target || element.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
        // binding.value(); run the arg
      }
    };
    document.body.addEventListener("click", element.clickOutsideEvent);
  },
  unbind: function (element) {
    document.body.removeEventListener("click", element.clickOutsideEvent);
  },
});

Vue.filter("truncate", function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});
