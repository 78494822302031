var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "21",
        viewBox: "0 0 20 21",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M11.89 7.67351L10 9.56351L8.11 7.67351C7.72 7.28351 7.09 7.28351 6.7 7.67351C6.31 8.06351 6.31 8.69351 6.7 9.08351L8.59 10.9735L6.7 12.8635C6.31 13.2535 6.31 13.8835 6.7 14.2735C7.09 14.6635 7.72 14.6635 8.11 14.2735L10 12.3835L11.89 14.2735C12.28 14.6635 12.91 14.6635 13.3 14.2735C13.69 13.8835 13.69 13.2535 13.3 12.8635L11.41 10.9735L13.3 9.08351C13.69 8.69351 13.69 8.06351 13.3 7.67351C12.91 7.29351 12.27 7.29351 11.89 7.67351ZM10 0.973511C4.47 0.973511 0 5.44351 0 10.9735C0 16.5035 4.47 20.9735 10 20.9735C15.53 20.9735 20 16.5035 20 10.9735C20 5.44351 15.53 0.973511 10 0.973511ZM10 18.9735C5.59 18.9735 2 15.3835 2 10.9735C2 6.56351 5.59 2.97351 10 2.97351C14.41 2.97351 18 6.56351 18 10.9735C18 15.3835 14.41 18.9735 10 18.9735Z",
          fill: "black",
          "fill-opacity": "0.54",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }