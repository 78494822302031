// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-2!@/assets/styles/table.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-2!@/assets/styles/animation.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".body-container[data-v-df660e5c] {\n  grid-template-columns: 100%;\n  /* grid-template-rows: 20rem auto; */\n  /* grid-gap: 10px; */\n}\n@media (max-width: 768px) {\n.body-container[data-v-df660e5c] {\n    grid-template-columns: 100%;\n}\n.merchant-category[data-v-df660e5c] {\n    margin-top: 1rem;\n}\n}\n", ""]);
// Exports
module.exports = exports;
