var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.formLabel
        ? _c("p", { staticClass: "text-grey mb-2" }, [
            _vm._v(" " + _vm._s(_vm.formLabel) + " "),
          ])
        : _vm._e(),
      _c("v-select", {
        staticClass:
          "outline-none transition-all input-regular placeholder-borderGrey",
        class: [
          _vm.disabled ? "disabled" : null,
          _vm.error
            ? "error-border"
            : _vm.selected.length > 0
            ? "input-active"
            : "",
        ],
        attrs: {
          options: _vm.options,
          placeholder: _vm.placeholder,
          multiple: "",
          label: _vm.label,
          value: _vm.value,
          reduce: _vm.reduce,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", _vm.selected)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "open-indicator",
            fn: function (ref) {
              var attributes = ref.attributes
              return [
                _c(
                  "svg",
                  _vm._b(
                    {
                      staticClass: "transition-all",
                      attrs: { width: "11", height: "7", fill: "none" },
                    },
                    "svg",
                    attributes,
                    false
                  ),
                  [
                    _c("path", {
                      attrs: { d: "M.842 1l4.5 4.5 4.5-4.5", stroke: "#000" },
                    }),
                  ]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }