var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "relative inline-block", class: [_vm.width] }, [
      _c(
        "div",
        {
          staticClass:
            "inline-flex items-center input-regular placeholder-borderGrey",
          class: [
            _vm.displayPlaceholder || (_vm.displayValue && !_vm.error)
              ? "input-active"
              : _vm.error
              ? "error-border"
              : null,
            _vm.disabled ? "disabled" : null,
            _vm.p,
            _vm.width,
            _vm.error ? "text-orange" : "text-grey",
          ],
        },
        [
          _vm.displayValue || _vm.displayPlaceholder
            ? _c("p", { staticClass: "z-10 pr-2 text-grey" }, [
                _vm._v(" " + _vm._s(_vm.symbol) + " "),
              ])
            : _vm._e(),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.displayValue,
                expression: "displayValue",
              },
            ],
            staticClass: "outline-none transition-all",
            class: [_vm.width],
            attrs: {
              required: _vm.required,
              disabled: _vm.disabled,
              id: _vm.id,
              placeholder: _vm.placeholder,
              pattern: " [0-9]*",
              inputmode: "numeric",
            },
            domProps: { value: _vm.displayValue },
            on: {
              blur: _vm.displayInput,
              focus: _vm.handleFocus,
              focusout: function ($event) {
                _vm.displayPlaceholder = false
              },
              keypress: function ($event) {
                return _vm.isNumber($event)
              },
              keyup: function ($event) {
                _vm.isInputActive = true
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.displayValue = $event.target.value
              },
            },
          }),
        ]
      ),
    ]),
    !_vm.hideErrorMessage
      ? _c(
          "div",
          { staticClass: "error-text" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.error
                ? _c("p", { staticClass: "text-orange text-xs mt-1" }, [
                    _vm._v(" " + _vm._s(_vm.errorText) + " "),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }