var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade", mode: "out-in" } },
    [
      _vm.loading
        ? _c("Spinner")
        : _c(
            "button",
            {
              staticClass:
                "\n      rounded-lg\n      transition-all\n      focus:outline-none\n      grid\n      place-items-center\n    ",
              class: [
                _vm.background.includes("bg-")
                  ? _vm.background
                  : "bg-" + _vm.background,
                _vm.width,
                _vm.opacity ? _vm.opacity : null,
                _vm.p ? _vm.p : "py-3 px-4  md:p-3 ",
                _vm.color.includes("text-") ? _vm.color : "text-" + _vm.color,
                _vm.border ? _vm.border : null,
                _vm.borderColor ? _vm.borderColor : null,
                _vm.fontSize ? _vm.fontSize : "text-base",
                _vm.shadow && !_vm.disabled ? "box-shadow" : null,
                _vm.radius.includes("rounded-")
                  ? _vm.radius
                  : "rounded-" + _vm.radius,
                _vm.loading &&
                  "pointer-events-none cursor-not-allowed opacity-70",
              ],
              style: { background: _vm.background },
              attrs: { disabled: _vm.disabled, type: _vm.type },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleClick.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "p",
                {
                  class: [
                    _vm.fontSize ? _vm.fontSize : "text-base",
                    _vm.fontWeight ? _vm.fontWeight : "font-semibold",
                  ],
                },
                [_vm._v(" " + _vm._s(_vm.text) + " ")]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }