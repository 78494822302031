var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "body-container" }, [
    _c("div", { staticClass: "layout-container w-full min-h-screen" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "auth-layout md:grid grid-cols-2 content-center" },
        [
          _c("div", { staticClass: "w-full auth-layout-title p-6 md:p-0" }, [
            _c(
              "div",
              { staticClass: "auth-layout-heading" },
              [
                _c(
                  "transition",
                  {
                    key: _vm.currentPage.header,
                    attrs: { name: "slideY", mode: "out-in" },
                  },
                  [
                    _c(
                      "h5",
                      {
                        staticClass:
                          "md:text-2xl text-xl font-bold primary-text",
                      },
                      [_vm._v(" " + _vm._s(_vm.currentPage.heading) + " ")]
                    ),
                  ]
                ),
                _c("p", { staticClass: "text-grey" }, [
                  _vm._v(_vm._s(_vm.currentPage.description)),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "md:ml-10 auth-layout-page" },
            [_vm._t("default"), _vm._m(1)],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex pt-8 h-14 md:h-16 items-center mb-8" },
      [
        _c("img", {
          staticClass: "max-h-full max-w-full border-r border-lightGrey-2 pr-4",
          attrs: {
            src: "https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg",
            alt: "",
          },
        }),
        _c("p", { staticClass: "text-grey pl-4" }, [
          _vm._v("Shop More, Pay Later"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "text-center text-brandPurple text-sm pt-4 pb-6" },
      [
        _c(
          "a",
          {
            attrs: {
              href: "https://usezilla.notion.site/Data-Privacy-Protection-Cookie-Policy-fd834fd91648458fb5bbf68acce27242",
              target: "blank",
            },
          },
          [_vm._v("Zilla Privacy Policy")]
        ),
        _vm._v(" | "),
        _c(
          "a",
          {
            attrs: {
              href: "https://usezilla.notion.site/Terms-of-use-shoppers-3e19b68f1ac94c24aaf773369e2f3566",
              target: "blank",
            },
          },
          [_vm._v("Terms of Use")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }