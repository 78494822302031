import { resetAuthState } from "./defaultState";

export default {
  SET_REGISTRATION(state, registrationId) {
    state.registrationId = registrationId;
  },
  setUserDetails(state, user) {
    state.user = user;
  },
  setUserEmail(state, email) {
    state.user.email = email;
  },
  AUTH_REQUEST(state) {
    state.status = "loading";
  },
  AUTH_SUCCESS(state, token) {
    state.status = "success";
    state.token = token;
  },
  AUTH_ERROR(state) {
    state.status = "error";
  },
  AUTH_LOGOUT(state) {
    state.token = "";
    state.user = "";
    state.tokenExpire = null;
    Object.assign(state, resetAuthState())
  },
  SAVE_BANK_DATA(state, payload) {
    state.bankData = payload;
  },
  CREDIT_QUALIFICATION(state, payload) {
    state.creditQualification = payload;
  },
  CHANGE_PASSWORD_ID(state, payload) {
    state.password_id = payload;
  },
  SET_TOKEN_EXPIRE(state, payload) {
    state.tokenExpire = payload;
  },
  BVN_LOOKUP(state, payload) {
    state.bvn = payload;
  },
  SET_EMPLOYMENT_INFO(state, payload) {
    state.employmentInfo = payload;
  },
  SET_REGISTRATION_OTP(state, payload) {
    state.registrationOtp = payload;
  },
  disableCreditPayments(state, payload){
    state.enableCredit = !payload
  }

};
