import axios from "@/plugins/axios";

export const GET_ALL_BANKS = () => {
  return axios.get(`/bnpl/lookup/all-banks`);
};

export const BANK_ACCOUNT_LOOKUP = (data) => {
  return axios.post(`/bnpl/lookup/account-number`, data);
};
export const CREATE_CASHOUT_ACCOUNT = ({ method, data }) => {
  return axios[method](`/bnpl/customer/cashout-bank-account`, data);
};

export const VERIFY_OTP = (data) => {
  return axios.put(`/bnpl/customer/cashout-bank-account/verify`, data);
};
