import axios from "axios";
import cookies from "vue-cookies";
// import router from "../router";
import store from "../store";

const token = cookies.get("token");
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 412 && error.response.data.message === "Card has no spending limit") {
      return error;
    }
    // we check for this error code and if it exists in the state; the errors eill be handle wherever it is called
    if (error?.response?.status !== store?.state?.api?.errorCodeToWatch) {
      // empty the error code state
      if (error?.response?.status != 401) {
        // legacy
        if (error?.response?.status != 404) {
          if (error?.response?.data?.message) {
            store.dispatch("notification/showAlert", {
              display: true,
              type: "cancel",
              description: error.response.data.message,
            });
          } else {
            store.dispatch("notification/showAlert", {
              display: true,
              type: "cancel",
              description: "We are sorry this happened. Please check your internet connection and try again",
              title: "An error occurred",
            });
          }
        }
      }
    }

    if (store?.state?.api?.errorCodeToWatch) {
      store.dispatch("api/setErrorCodeToWatch", null);
    }
    if (error.response.status === 401) {
      store.dispatch("auth/logout");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
