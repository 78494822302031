<template>
  <div class="body-container">
    <Balance :loading="balanceLoading" />
    <SummaryPageEntry v-if="dashboardData.onboardingSummary.percentCompletion < 100 && !dashboardLoading "/>
    <!-- <SummaryPageEntry v-if="showDashboardEntry && !dashboardLoading" /> -->

    <Profile
      :customerTodos="dashboardData.customerTodos"
      :onboardingCompletion="dashboardData.onboardingCompletionPercent"
    />
    <inline-toast class="mt-4" description="You can only make your payments in full at the moment while we revise and improve our installment payment plans" :display="!enableCredit" type="info"/>
    <Statistics
      :loading="dashboardLoading"
      v-if="dashboardData.pendingPaymentsSummaryDto && 
      (dashboardData.pendingPaymentsSummaryDto.overduePaymentsCount || dashboardData.pendingPaymentsSummaryDto.upcomingPaymentsCount)"
      :statistics="dashboardData.pendingPaymentsSummaryDto"
    />
    <div class="merchant-category">
      <MerchantCategory
        :loading="dashboardLoading"
        :dashboardData="dashboardData.businessCategories"
      />
    </div>
    <Payments
      :upcomingPayments="upcomingPayments"
      :upComingLoading="upComingLoading"
      :purchases="getPurchases"
      :purchaseLoading="purchaseLoading"
    />
    <ActivationReminder
      @close="handleShowReminder(false)"
      :display="showReminder && !dashboardLoading"
      :customerTodos="dashboardData.customerTodos"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Balance from "./Balance";
import MerchantCategory from "./MerchantCategory";
import Payments from "./Payments/Payments.vue";
import Statistics from "./PaymentStatistics/Statistics.vue";
import {DASHBOARD_STEPS} from '@/utils/sharedData/home'
import {InlineToast} from '@/UI/Notification'
export default {
  components: {
    Balance,
    MerchantCategory,
    ActivationProgress: () => import("./ActivationProgress"),
    ActivationReminder: () => import("./ActivationReminder/reminder.vue"),
    Profile: () => import("./Profile/Profile.vue"),
    SummaryPageEntry:() => import('./SummaryPageEntry'),
    Payments,
    Statistics,
    SummaryPageEntry: () => import('./SummaryPageEntry'),
    InlineToast
  },
  data: () => ({
    upComingLoading: false,
    purchaseLoading: false,
    showSingleSchedule: false,
    singleRepaymentData: {},
    // upcomingPayments: [],
    balanceLoading: false,
    interval: "",
    dashboardLoading: false,
    params: {
      pageSize: 10,
    },
  }),
  computed: {
    ...mapState({
      creditQualification: (state) => state?.userInfo?.creditQualification,
      upcomingPayments: (state) => state?.repayments?.upcomingPayments,
      getPurchases: (state) => state?.purchases?.getPurchases,
      showReminder: (state) => state?.dashboard?.showReminder,
      dashboardData: (state) => state?.dashboard?.dashboardData,
      showDashboardEntry: (state) => state?.dashboard?.showDashboardEntry,
      wallet: (state) => state?.dashboard?.userWallet,
      enableCredit:(state) => state?.auth?.enableCredit
    }),
    showProfile() {
      // console.log(this.dashboardData.customerTodos)
      if (this.dashboardData.customerTodos) {
        const dashboardItems = [
          // "IDENTITY_VERIFICATION",
          // "CREDIT_QUALIFICATION",
          DASHBOARD_STEPS.overduePayment,
          // 'USER_DETAILS_UPDATE'
        ];
        if(this.$growthbook.isOn('issued-card')) {
          dashboardItems.push(DASHBOARD_STEPS.cardZilla)
        }
        // console.log('new items', dashboardItems);
        const dashboardArray = this.dashboardData.customerTodos.map(
          (value) => value.key
        );
        return dashboardItems.some((item) => dashboardArray.includes(item));
      }
    },
  },

  beforeMount() {
    if(Object.keys(this.dashboardData).length === 0){
      this.allDashboardData();
    }

    if (!Object.keys(this.creditQualification).length ) {
      this.getCreditQualification();
    }
    if(!this.getPurchases.length){
      this.getRecentPurchases();
    }
    if(!this.wallet.creditWallet || !this.wallet.instaPayWallet){
      this.getWallet();
    }
    if(!this.upcomingPayments.length){
      this.getUpcoming();
    }
  },
  methods: {
    ...mapActions("repayments", ["getUpcomingPayments"]),
    ...mapActions("dashboard", [
      "getUserWallet",
      "handleShowReminder",
      "getDashboardData",
    ]),
    ...mapActions("purchases", ["getUserPurchases", "getPurchaseHistory"]),
    ...mapActions("userInfo", ["getCreditQualification"]),
    ...mapActions("loading", ["setLoading"]),
    getUpcoming() {
      this.upComingLoading = true;
      this.getUpcomingPayments()
        .then((res) => {
          // this.upcomingPayments = res.data;
          this.upComingLoading = false;
        })
        .catch(() => {
          this.upComingLoading = false;
        });
    },
    handleSingleRepayment(value) {
      this.singleRepaymentData = value;
      this.showSingleSchedule = true;
    },
    handleCompleted() {
      this.showSingleSchedule = false;
      this.getUpcoming();
      this.getUserWallet();
    },

    getWallet() {
      this.balanceLoading = true;
      this.getUserWallet()
        .then(() => {
          this.balanceLoading = false;
        })
        .catch(() => {
          this.balanceLoading = false;
        });
    },
    allDashboardData() {
      this.dashboardLoading = true;
      this.getDashboardData()
        .then(() => {
          this.dashboardLoading = false;
        })
        .catch(() => {
          this.dashboardLoading = false;
        });
    },
    getRecentPurchases() {
      this.purchaseLoading = true;
      this.getUserPurchases({
        params: { pageSize: 10, status: "SUCCESSFUL" },
      })
        .then(() => {
          this.purchaseLoading = false;
        })
        .catch(() => {
          this.purchaseLoading = false;
        });
    },
  },
  beforeDestroy() {
    this.handleShowReminder(false);
  },
};
</script>
<style scoped>
@import "~@/assets/styles/table.css";
@import "~@/assets/styles/animation.css";
.body-container {
  grid-template-columns: 100%;
  /* grid-template-rows: 20rem auto; */
  /* grid-gap: 10px; */
}

@media (max-width: 768px) {
  .body-container {
    grid-template-columns: 100%;
  }
  .merchant-category {
    margin-top: 1rem;
  }
}
</style>
