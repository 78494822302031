import { defaultState } from "./defaultRepaymentState";

export default {
  GET_RECOVERY_SCHEDULES(state, payload) {
    state.repaymentSchedules = payload;
  },
  GET_UPCOMING_PAYMENTS(state, payload) {
    state.upcomingPayments = payload;
  },
  ADD_RECOVERY_SCHEDULES(state, payload) {
    state.repaymentSchedules.push(...payload);
  },
  RESET_REPAYMENT_STATE(state, payload){
    Object.assign(state, defaultState());
  }
};
