import axios from "@/plugins/axios";

export const GET_ADDRESSES = (payload) => {
    return axios.get(`/bnpl/v1/address`, { params: payload });
};

export const CREATE_NEW_ADDRESS = (data) => {
    return axios.post(`/bnpl/v1/address`, data);
};

export const GET_PRIMARY_ADDRESS = () => {
    return axios.get(`/bnpl/v1/address/primary`);
};
