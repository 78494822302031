import Vue from "vue";
import VueRouter from "vue-router";
import Register from "../views/register";
import Login from "../views/login";
import Home from "../views";
import store from "../store";
import cookies from "vue-cookies";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/auth",
  //   name: "Home",
  //   component: Home,
  // },

  {
    path: "/register",
    name: "Register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Register,
    meta: {
      layout: "auth",
      guest: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "auth",
      guest: true,
    },
  },
  {
    path: "/otp",
    name: "Otp",
    component: () => import("@/views/otp"),
    meta: {
      layout: "auth",
      guest: true,
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot",
    component: () => import("@/views/forgotPassword"),
    meta: { layout: "auth", guest: true },
  },
  {
    path: "/forgot-password-otp",
    name: "forgot-password-otp",
    component: () => import("@/views/forgotPasswordOtp"),
    meta: { layout: "auth", guest: true },
  },
  {
    path: "/create-password",
    name: "Create Password",
    component: () => import("@/views/createPassword"),
    meta: { layout: "auth", guest: true },
  },
  {
    path: "/password",
    name: "Password",
    component: () => import("@/views/password"),
    meta: { layout: "auth", guest: true },
  },

  {
    path: "/purchases",
    name: "Purchases",
    component: () => import("@/views/purchases"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },

  // Drawer routes
  {
    path: "/drawer",
    component: () => import("@/views/drawer/drawer"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "drawer",
        component: () => import("@/views/drawer"),
      },
      {
        path: 'card',
        name: "drawer-card",
        component: () => import("@/views/card"),
      },
      {
        path: 'card/view-card/:id',
        name: "drawer-single-card",
        component: () => import("@/views/card/singleCard"),
      },
      {
        path: "card/request-card",
        name: "drawer-request-card",
        component: () => import("@/views/card/requestCard"),
      },
      {
        path: "card/customize",
        name: "drawer-customize-card",
        component: () => import("@/views/card/customize"),
      },
      {
        path: "card/address",
        name: "drawer-card-address",
        component: () => import("@/views/card/address"),
      },
      {
        path: "card/create-pin",
        name: "drawer-create-pin",
        component: () => import("@/views/card/createPin"),
      },
      {
        path: "card/payment",
        name: "drawer-card-payment",
        component: () => import("@/views/card/payment"),
      },
      {
        path: "finance",
        name: "drawer-finance",
        component: () => import("@/views/finance"),
      },
      {
        path: "calculator",
        name: "calculator",
        component: () => import("@/components/ZillaCards/Calculator/Calculator"),
      },
      // {
      //   path: "drawer/card/spending-limit",
      //   name: "SpendingLimit",
      //   component: () => import("@/views/card/spendingLimit"),
      //   meta: {
      //     layout: "default",
      //     requiresAuth: true,
      //   },
      // },
      // {
      //   path: "drawer/card/spending-limit/details",
      //   name: "SpendingLimitDetails",
      //   component: () => import("@/views/card/spendingLimitDetails"),
      //   meta: {
      //     layout: "default",
      //     requiresAuth: true,
      //   },
      // },
    ]
  },

  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/settings"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/blank",
    name: "Blank",
    component: () => import("@/views/blank"),
  },
  {
    path: "/bare",
    name: "Bare",
    component: () => import("@/views/bare"),
    meta: {
      layout: "bare",
    },
  },
  {
    path: "/404",
    name: "Not Found",
    component: () => import("@/views/404"),
    meta: {
      layout: "bare",
    },
  },
  {
    path: "*",
    redirect: "/404",
    name: "Page Not Found",
    component: () => import("@/views/404"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

router.beforeEach((to, from, next) => {
  if (to.query.ref) {
    cookies.set("referralCode", to.query.ref);
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const expiryDate = new Date(store?.state?.auth?.tokenExpire);
    const currentDate = new Date().getTime();
    if (store.getters["auth/isAuthenticated"] && expiryDate > currentDate) {
      next();
      return;
    } else {
      next('/login')
      store.dispatch('auth/resetStoreStates')
    }
    // if it is a guest page
  } else if (to.matched.some((record) => record.meta.guest)) {
    const expiryDate = new Date(store?.state?.auth?.tokenExpire);
    const currentDate = new Date().getTime();
    if (store.getters["auth/isAuthenticated"] && expiryDate > currentDate) {
      next("/");
      return;
    }else{
      next();
    }
  } else {
    next();
  }
});

// const originalPush = router.prototype.push;
// router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => {
//     if (err.name !== 'NavigationDuplicated') throw err
//   });
// }

export default router;
