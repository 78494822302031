import cookies from "vue-cookies";

export default {
  user: {
    email: ""
  },
  registrationId: "",
  token: cookies.get("token"),
  tokenExpire: cookies.get("tokenExpire"),
  status: "",
  bankData: {},
  creditQualification: {},
  password_id: "",
  bvn: {
    number: "",
  },
  employmentInfo: {},
  registrationOtp: "",
  enableCredit: false
};
