var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.display
      ? _c(
          "div",
          {
            staticClass: "w-full p-2 flex items-center",
            class: [!_vm.custom ? _vm.type + "-toast" : null],
          },
          [
            _c("svg-icon", {
              attrs: {
                color: _vm._iconColor,
                type: "mdi",
                path: _vm.svgPath.alert,
              },
            }),
            _c("p", {
              staticClass: "ml-2 toast",
              domProps: { innerHTML: _vm._s(_vm.description) },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }