import cookies from "vue-cookies";

export const resetAuthState = () => {
  return {
    user: {
      email: "",
    },
    registrationId: "",
    token: cookies.get("token"),
    tokenExpire: null,
    status: "",
    bankData: {},
    creditQualification: {},
    password_id: "",
    bvn: {
      number: "",
    },
    employmentInfo: {},
    registrationOtp: "",
  };
};
