var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "grid md:grid-cols-2 gap-4 mt-6" },
          _vm._l(2, function (pay, index) {
            return _c(
              "Card",
              { key: "pay" + index },
              [
                _c("Skeleton", { attrs: { width: "70%" } }),
                _c("Skeleton", { attrs: { width: "10%", height: "1.5rem" } }),
              ],
              1
            )
          }),
          1
        )
      : _c(
          "div",
          { staticClass: "grid md:grid-cols-2 gap-4 mt-6" },
          [
            _c("Card", [
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-between cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/purchases")
                    },
                  },
                },
                [
                  _c("div", [
                    _c("p", { staticClass: "font-semibold" }, [
                      _vm._v(" Upcoming Payments "),
                      _c("span", { staticClass: "text-blue" }, [
                        _vm._v(
                          "(" +
                            _vm._s(_vm.statistics.upcomingPaymentsCount) +
                            ")"
                        ),
                      ]),
                    ]),
                    _c("p", { staticClass: "font-bold" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatMoney")(
                              _vm.statistics.totalUpcomingPayments
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "8",
                        height: "12",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M1.28957.70978c-.39.39-.39 1.02 0 1.41l3.88 3.88-3.88 3.88c-.39.39002-.39 1.02002 0 1.41002.39.39 1.02.39 1.41 0l4.59-4.59002c.39-.39.39-1.02 0-1.41l-4.59-4.59c-.38-.38-1.02-.38-1.41.01Z",
                          fill: "#000",
                          "fill-opacity": ".54",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _c("Card", [
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-between cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/purchases")
                    },
                  },
                },
                [
                  _c("div", [
                    _c("p", { staticClass: "font-semibold" }, [
                      _vm._v(" Overdue Payments "),
                      _c("span", { staticClass: "text-brandRed" }, [
                        _vm._v(
                          "(" +
                            _vm._s(_vm.statistics.overduePaymentsCount) +
                            ")"
                        ),
                      ]),
                    ]),
                    _c("p", { staticClass: "font-bold" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatMoney")(
                              _vm.statistics.totalOverduePayments
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "8",
                        height: "12",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M1.28957.70978c-.39.39-.39 1.02 0 1.41l3.88 3.88-3.88 3.88c-.39.39002-.39 1.02002 0 1.41002.39.39 1.02.39 1.41 0l4.59-4.59002c.39-.39.39-1.02 0-1.41l-4.59-4.59c-.38-.38-1.02-.38-1.41.01Z",
                          fill: "#000",
                          "fill-opacity": ".54",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }