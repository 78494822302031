<template>
  <div class="h-full">
    <Card class="w-full md:shadow" shadow>
      <div class="pb-2 md:pb-4 mx-auto" v-if="displaySuccess" key="form">
        <div class="">
          <h4
            class="hidden md:block font-bold md:text-xl text-xl text-center md:text-left"
          >
            Let’s start with your email
          </h4>
          <form @submit.prevent="initiateRegistration">
            <Input
              type="email"
              id="email"
              placeholder="What's your email?"
              class="mt-10"
              v-model="user.email"
              width="w-full"
              errorText="Please enter a valid email address"
              :validation="rules.email"
              @valid="valid.email = $event"
            />
            <Button
              text="Create shopper's account"
              width="w-full"
              class="mt-12"
              shadow
              :loading="loading"
              :disabled="disableButton"
              type="submit"
            />
          </form>
          <div class="mt-8 justify-center items-center flex">
            <div>
              <p class="text-center text-grey text-sm flex justify-center">
                Already have an account?
                <router-link to="/login">
                  <span class="flex items-center">
                    <span class="text-brandPurple mx-3">Sign in here </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                      fill="none"
                    >
                      <path
                        d="M12.01 3.00057H1C0.45 3.00057 0 3.45057 0 4.00057C0 4.55057 0.45 5.00057 1 5.00057H12.01V6.79057C12.01 7.24057 12.55 7.46057 12.86 7.14057L15.64 4.35057C15.83 4.15057 15.83 3.84057 15.64 3.64057L12.86 0.850567C12.55 0.530567 12.01 0.760566 12.01 1.20057V3.00057Z"
                        fill="#6B6873"
                      />
                    </svg>
                  </span>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import { Input } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  export default {
    components: {
      Button,
      Input,
    },
    data: () => ({
      displaySuccess: true,
      loading: false,
      user: {
        email: "",
        password: "",
      },
      valid: {
        email: false,
      },
    }),
    computed: {
      rules() {
        return {
          email:
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              this.user?.email?.trim()
            ),
          // password:
          //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
          //     this.user.password
          //   ),
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },
    mounted() {
      console.log('kkkje', sendinblue);
      if (this.$route?.query?.email) {
        this.user.email = this.$route?.query?.email;
      }
    },
    methods: {
      ...mapActions("auth", ["register"]),
      ...mapActions("notification", ["showAlert"]),
      initiateRegistration() {
        this.loading = true;
        this.$store.commit("auth/setUserDetails", this.user);
        this.register({
          email: this.user.email?.trim(),
          referralCode: this.$cookies.get("referralCode"),
        })
          .then(() => {
            if (process.env?.VUE_APP_ENVIRONMENT?.toString() === "production") {
              sendinblue.identify(`${this.user.email?.trim()}`);
              sendinblue.track('registration_initiated', {
                email: `${this.user.email}`
              });
            }
            this.$router.push("/otp");
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
  /* .terms {
    position: fixed;
    bottom: 1rem;
  } */
  .main-body {
    min-height: calc(100vh);
    display: grid;
    place-items: center;
  }
  @media (max-width: 768px) {
    .main-body {
      min-height: calc(100vh - 10rem);
    }
  }
</style>
