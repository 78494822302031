var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body-container" },
    [
      _c("Balance", { attrs: { loading: _vm.balanceLoading } }),
      _vm.dashboardData.onboardingSummary.percentCompletion < 100 &&
      !_vm.dashboardLoading
        ? _c("SummaryPageEntry")
        : _vm._e(),
      _c("Profile", {
        attrs: {
          customerTodos: _vm.dashboardData.customerTodos,
          onboardingCompletion: _vm.dashboardData.onboardingCompletionPercent,
        },
      }),
      _c("inline-toast", {
        staticClass: "mt-4",
        attrs: {
          description:
            "You can only make your payments in full at the moment while we revise and improve our installment payment plans",
          display: !_vm.enableCredit,
          type: "info",
        },
      }),
      _vm.dashboardData.pendingPaymentsSummaryDto &&
      (_vm.dashboardData.pendingPaymentsSummaryDto.overduePaymentsCount ||
        _vm.dashboardData.pendingPaymentsSummaryDto.upcomingPaymentsCount)
        ? _c("Statistics", {
            attrs: {
              loading: _vm.dashboardLoading,
              statistics: _vm.dashboardData.pendingPaymentsSummaryDto,
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "merchant-category" },
        [
          _c("MerchantCategory", {
            attrs: {
              loading: _vm.dashboardLoading,
              dashboardData: _vm.dashboardData.businessCategories,
            },
          }),
        ],
        1
      ),
      _c("Payments", {
        attrs: {
          upcomingPayments: _vm.upcomingPayments,
          upComingLoading: _vm.upComingLoading,
          purchases: _vm.getPurchases,
          purchaseLoading: _vm.purchaseLoading,
        },
      }),
      _c("ActivationReminder", {
        attrs: {
          display: _vm.showReminder && !_vm.dashboardLoading,
          customerTodos: _vm.dashboardData.customerTodos,
        },
        on: {
          close: function ($event) {
            return _vm.handleShowReminder(false)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }