<template>
  <div class="merchant-container mt-8">
    <Skeleton v-if="loading" />
    <div class="flex items-center justify-between" v-else>
      <h4 class="text-lg font-semibold mb-2 text-grey">Merchant categories</h4>
    </div>
    <Loading v-if="loading" />
    <div v-else>
      <EmptyState
        description="Sorry there are currently no categories"
        v-if="dashboardData.length === 0"
      />
      <div class="grid lg:grid-cols-3 md:grid-cols-2 gap-2 md:gap-4" v-else>
        <div
          class="bg-white px-3 rounded-lg"
          v-for="category in dashboardData"
          :key="category.id"
        >
          <div
            class="flex items-center py-6 justify-between cursor-pointer"
            @click="getMerchants(category)"
          >
            <div class="flex items-center">
              <img class="mr-2 w-8 h-auto" :src="category.iconUrl" alt="logo" />
              <p class="font-semibold text-sm capitalize">
                {{ category.name }}
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="12"
              fill="none"
            >
              <path
                d="M1.28957.70978c-.39.39-.39 1.02 0 1.41l3.88 3.88-3.88 3.88c-.39.39002-.39 1.02002 0 1.41002.39.39 1.02.39 1.41 0l4.59-4.59002c.39-.39.39-1.02 0-1.41l-4.59-4.59c-.38-.38-1.02-.38-1.41.01Z"
                fill="#000"
                fill-opacity=".54"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to view featured merchants -->
    <LargeModal
      background="#fff"
      v-if="showFeaturedMerchants"
      
      :display="showFeaturedMerchants"
      @close="showFeaturedMerchants = false"
      title="Merchants"
    >
      <template v-slot:title>
        <h5 class="current-heading text-lg md:text-xl font-bold">
          Merchants related to
          <span>({{
            currentCategory.name.replace(/_/g, " ") | capitalize
          }})</span>
        </h5>
      </template>
      <MerchantListModal
        @confirmation="showConfirmation"
        @selectSubCategories="setSubCategories"
        :featuredMerchants="featuredMerchants"
        :currentCategory="currentCategory"
      />
      <Pagination
        :handleMore="handleMore"
        :loading="paginateLoading"
        @paginate="handlePagination"
      />
      <template v-slot:footer>
        <p class="text-dark text-center w-100 p-4  bg-white">
          Can't find where to buy from?
          <a
            href="https://wa.me/message/QNJDQQRGC7FJC1"
            target="blank"
            class="text-brandPurple underline underline-offset-2"
            >DM our WhatsApp</a
          >
          and we'll do the work
        </p>
      </template>
    </LargeModal>
    <!-- confirmation modal -->
    <Modal :display="showRedirectModal" @close="showRedirectModal = false">
      <ConfirmationModal
        :merchantData="merchantData"
        @close="showRedirectModal = false"
      />
    </Modal>
    <!-- Modal to view merchant details -->
    <LargeModal
      :display="showMerchantDetails"
      @close="
        showMerchantDetails = false;
        showFeaturedMerchants = true;
      "
      background="white"
    >
      <div class="text-center md:w-3/4 mx-auto">
        <div class="h-32 w-32 mx-auto rounded-full bg-grey">
          <img
            class="h-full w-full rounded-full"
            :src="getImage(merchantData.logoId)"
            alt=""
          />
        </div>
        <h5 class="mt-4 text-dark text-xl md:text-2xl font-bold">
          {{ merchantData.name }}
        </h5>
        <p class="text-grey text-lg font-semibold">
          Merchant ID:
          <span class="text-dark">#{{ merchantData.hqOutletCode }}</span>
        </p>
        <div class="flex justify-center items-center mt-4 flex-wrap">
          <div
            class="border location-pill border-grey p-2 mr-2 px-3"
            v-for="type in merchantData.storeLocationTypes"
            :key="type.logoId"
          >
            <p class="semibold text-sm text-dark capitalize">
              {{ type.replace(/_/g, " ") }}
            </p>
          </div>
        </div>

        <p
          class="description mt-4 text-sm text-grey"
          v-if="merchantData.featuredDescription"
        >
          {{ merchantData.featuredDescription }}
        </p>
        <div class="actions flex items-center justify-center mt-6">
          <!-- <p
            class="text-dark font-semibold mr-6 cursor-pointer"
            @click="handleCheckoutDisplay"
          >
            Make payment
          </p> -->
          <Button
            outline
            text="Visit store"
            p="py-2 px-4"
            @click="handleLinkTopDisplay(merchantData)"
          />
        </div>
        <div class="mt-8 flex items-center justify-between flex-wrap">
          <div class="flex items-center" v-if="merchantData.instagramHandle">
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12c0 6.6274 5.37258 12 12 12 6.6274 0 12-5.3726 12-12 0-6.62742-5.3726-12-12-12ZM9.36161 5.63876c.68269-.03106.90079-.03866 2.63899-.03866h-.002c1.7387 0 1.956.0076 2.6387.03866.6813.03121 1.1466.13907 1.5547.29734.4213.16334.7773.38201 1.1333.73801.356.35574.5747.71281.7387 1.13374.1573.40694.2653.87201.2973 1.55335.0307.6827.0387.9008.0387 2.639 0 1.7381-.008 1.9557-.0387 2.6384-.032.6811-.14 1.1463-.2973 1.5533-.164.4208-.3827.7779-.7387 1.1337-.3556.356-.7121.5752-1.1329.7386-.4073.1583-.8729.2662-1.5542.2974-.6827.031-.9001.0386-2.6384.0386-1.738 0-1.956-.0076-2.63872-.0386-.68121-.0312-1.14641-.1391-1.55361-.2974-.42068-.1634-.77775-.3826-1.13335-.7386-.35587-.3558-.57454-.7129-.73814-1.1338-.15814-.4069-.266-.872-.29734-1.5533-.03093-.6827-.03866-.9002-.03866-2.6383 0-1.7382.008-1.9565.03853-2.63913.03067-.68107.13867-1.14628.29734-1.55335.164-.4208.38267-.77787.73867-1.13361.35574-.35587.71281-.57454 1.13375-.73801.40693-.15827.872-.26613 1.55334-.29734Z"
                fill="#1E1D20"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4265 6.75355c.1115-.00017.2314-.00012.3608-.00006l.2133.00006c1.7088 0 1.9114.00614 2.5862.0368.624.02854.9627.1328 1.1883.22041.2986.116.5116.25467.7354.47867.224.224.3627.43734.479.73601.0876.22533.192.564.2204 1.18801.0306.67465.0373.87735.0373 2.58535 0 1.708-.0067 1.9107-.0373 2.5854-.0286.624-.1328.9626-.2204 1.188-.116.2987-.255.5113-.479.7352-.224.224-.4366.3627-.7354.4787-.2254.088-.5643.192-1.1883.2205-.6747.0307-.8774.0373-2.5862.0373-1.7089 0-1.9115-.0066-2.58613-.0373-.62401-.0288-.96268-.1331-1.18842-.2207-.29866-.116-.512-.2546-.736-.4786-.22401-.224-.36267-.4368-.47894-.7357-.0876-.2253-.192-.564-.2204-1.188-.03067-.6746-.0368-.8773-.0368-2.5864 0-1.7091.00613-1.9107.0368-2.58535.02853-.62401.1328-.96268.2204-1.18828.116-.29867.25493-.51201.47894-.73601.224-.224.43734-.36267.736-.47894.22561-.088.56441-.192 1.18842-.22067.59043-.02666.81923-.03466 2.01203-.036v.0016Zm3.9904 1.06263c-.424 0-.768.34361-.768.76775 0 .424.344.768.768.768.424 0 .768-.344.768-.768 0-.42401-.344-.76801-.768-.76801v.00026ZM8.71393 12.0003c0-1.815 1.47157-3.28661 3.28657-3.28668 1.8151 0 3.2863 1.47158 3.2863 3.28668s-1.4711 3.2861-3.2862 3.2861-3.28667-1.471-3.28667-3.2861Z"
                fill="#1E1D20"
              />
              <path
                d="M12.0005 9.86719c1.1782 0 2.1334.95511 2.1334 2.13331s-.9552 2.1334-2.1334 2.1334-2.13331-.9552-2.13331-2.1334.95511-2.13331 2.13331-2.13331Z"
                fill="#1E1D20"
              />
            </svg>
            <p class="text-dark">{{ merchantData.instagramHandle }}</p>
          </div>
          <div class="flex items-center" v-if="merchantData.twitterHandle">
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12c0 6.6274 5.37258 12 12 12 6.6274 0 12-5.3726 12-12 0-6.62742-5.3726-12-12-12Zm-.3342 10.1689-.0252-.4152c-.0755-1.0762.5876-2.05919 1.6368-2.44052.3861-.13558 1.0408-.15253 1.4689-.03389.1679.05084.4868.22032.7135.37285l.4113.27965.4532-.14406c.2518-.07627.5876-.20338.7387-.28812.1427-.07626.2686-.11863.2686-.09321 0 .14406-.3106.63555-.5708.90672-.3525.38133-.2518.41522.4616.161.4281-.14405.4365-.14405.3526.01695-.0504.08474-.3106.38133-.5876.6525-.47.46607-.4952.51692-.4952.90673 0 .6016-.2854 1.8558-.5708 2.5422-.5288 1.2881-1.6619 2.6185-2.7951 3.2879-1.5948.9406-3.71844 1.1779-5.50631.6271C7.02404 16.3211 6 15.8465 6 15.7618c0-.0254.31057-.0593.68829-.0678.78901-.017 1.57802-.2373 2.24952-.6271l.45327-.2712-.52042-.1779c-.73865-.2542-1.40175-.8389-1.56963-1.3897-.05036-.178-.03357-.1865.43648-.1865l.48683-.0085-.41129-.1949c-.48684-.2457-.93171-.6609-1.14994-1.0846-.15948-.3051-.36093-1.0762-.30218-1.1355.01679-.0255.19306.0254.39451.0932.57917.2118.65471.161.31896-.1949-.62953-.64406-.82259-1.60163-.52041-2.50835l.14269-.40675.55399.55081c1.13316 1.1101 2.46776 1.77107 3.99543 1.96599l.4197.0508Z"
                fill="#1E1D20"
              />
            </svg>
            <p>{{ merchantData.twitterHandle }}</p>
          </div>
          <div
            class="flex items-center"
            v-if="merchantData.whatsappPhoneNumber"
          >
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 12c0 6.6274 5.37258 12 12 12 6.6274 0 12-5.3726 12-12 0-6.62742-5.3726-12-12-12C5.37258 0 0 5.37258 0 12Zm19.5311-.4593c-.0016 3.9333-3.2013 7.1339-7.1356 7.1355h-.0029c-1.1942-.0005-2.3677-.3001-3.40991-.8686l-3.78268.9923 1.01231-3.6976c-.62444-1.0821-.95302-2.3097-.95248-3.5673.00156-3.93425 3.20258-7.1351 7.13566-7.1351 1.9088.00083 3.7004.74394 5.0476 2.09271 1.3472 1.3487 2.0887 3.14146 2.088 5.04809Z"
                fill="#1E1D20"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="m6.9135 17.105 2.24469-.5888.21657.1285c.91054.5404 1.95444.8263 3.01864.8267h.0024c3.2689 0 5.9294-2.6606 5.9307-5.9308.0006-1.58475-.6157-3.07481-1.7354-4.19586-1.1198-1.12104-2.6089-1.73872-4.193-1.73927-3.27138 0-5.93196 2.66033-5.93326 5.93033-.00046 1.1206.3131 2.212.90678 3.1563l.14102.2244-.59914 2.1885Zm8.833-4.0276c.1243.0601.2083.1007.2442.1605.0445.0744.0445.4313-.104.8479-.1486.4164-.8609.7966-1.2035.8477-.3071.046-.6959.0651-1.123-.0706-.2589-.0822-.591-.1919-1.0164-.3756-1.6715-.7217-2.80102-2.3417-3.01451-2.6479-.01496-.0215-.02542-.0365-.03125-.0443l-.00144-.0019c-.09436-.1259-.72658-.9694-.72658-1.84246 0-.82126.40342-1.25173.58911-1.44988a4.46633 4.46633 0 0 0 .03491-.0375c.16342-.1785.35659-.22313.47544-.22313.11885 0 .23782.0011.34172.00632.0128.00064.0262.00057.0399.00048.1039-.0006.2335-.00136.3613.30558.0491.11812.121.29325.1969.47795.1534.3735.3229.78618.3528.8459.0445.08925.0743.19334.0148.31234-.0089.0179-.0171.0347-.025.0508-.0447.0912-.0775.1582-.1533.2466-.0298.0348-.0605.0723-.0913.1098-.0614.0747-.1227.1494-.1761.2026-.0893.0889-.1822.1854-.0782.3639s.4619.7623.9919 1.2351c.5697.5082 1.0649.723 1.3159.8319.0491.0212.0888.0385.1179.053.1782.0893.2822.0744.3862-.0446.1041-.119.4457-.5206.5646-.6991.1188-.1784.2377-.1487.4011-.0892.1635.0595 1.0401.4908 1.2184.5801.0348.0174.0673.0331.0975.0477Z"
                fill="#1E1D20"
              />
            </svg>
            <p class="text-dark">{{ merchantData.whatsappPhoneNumber }}</p>
          </div>
        </div>
      </div>
    </LargeModal>
    <Checkout
      :display="showCheckout"
      :code="merchantData.hqOutletCode"
      :name="merchantData.name"
      @close="
        showCheckout = false;
        showMerchantDetails = true;
      "
      @success="showCheckout = false"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Button } from "@/UI/Button";
import Loading from "./Loading";
import getImage from "@/mixins/getImage";
export default {
  mixins: [getImage],
  components: {
    Loading,
    Button,
    EmptyState: () => import("@/UI/EmptyState"),
    Hr: () => import("@/UI/Hr"),
    Modal: () => import("@/UI/Modals/Modal"),
    LargeModal: () => import("@/UI/Modals/LargeModal"),
    ConfirmationModal: () => import("./ConfirmationModal.vue"),
    MerchantListModal: () => import("./MerchantListModal.vue"),
    Checkout: () => import("../Checkout/Checkout.vue"),
    Pagination: () => import("@/UI/Pagination"),
  },
  data: () => ({
    showFeaturedMerchants: false,
    currentCategory: {},
    showRedirectModal: false,
    merchantData: {},
    showMerchantDetails: false,
    showCheckout: false,
    pageLoading: false,
    paginateLoading: false,
    handleMore: false,
    params: {
      page: 0,
      pageSize: 10,
      from: "",
      to: "",
      subCategoryIds: [],
    },
  }),
  computed: {
    ...mapState({
      featuredMerchants: (state) => state.dashboard.featuredMerchants,
    }),
  },
  props: {
    purchases: {
      type: Array,
      default: () => [],
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    dashboardData: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  methods: {
    ...mapActions("dashboard", [
      "getFeaturedMerchants",
      "getFeaturedMerchantsBySubCategory",
    ]),
    ...mapActions("loading", ["setLoading"]),
    getMerchants(category, type) {
      this.currentCategory = category;
      if (!type) {
        this.setLoading(true);
      }
      this.getFeaturedMerchants(category.id)
        .then((res) => {
          this.showFeaturedMerchants = true;
          this.setLoading(false);
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    showConfirmation(data) {
      this.merchantData = data;
      this.showMerchantDetails = true;
      this.showFeaturedMerchants = false;
    },
    handleCheckoutDisplay() {
      this.showCheckout = true;
      this.showMerchantDetails = false;
    },
    handleLinkTopDisplay(data) {
      let url;
      if (data.businessWebsite) {
        url = data.businessWebsite;
      } else if (data.instagramUrl) {
        url = data.instagramUrl;
      } else if (data.whatsappPhoneNumber) {
        const whatsappNumberArray = data.whatsappPhoneNumber.split("");
        if (whatsappNumberArray[0] === "0") {
          data.whatsappPhoneNumber.substring(1);
          url = `https://wa.me/+${data.whatsappPhoneNumber}`;
        } else {
          url = `https://wa.me/${data.whatsappPhoneNumber}`;
        }
      }
      window.open(url, "_blank");
    },

    setSubCategories(subCategories) {
      const ids = subCategories.map(function (item) {
        return item["id"];
      });
      this.params.subCategoryIds = ids.toString();
      if (ids.length) {
        this.listsMerchantsBySubCategories();
      } else {
        this.getMerchants(this.currentCategory, 'subcategory-listing');
      }
    },

    handlePagination(type) {
      this.params.page++;
      this.paginateLoading = true;
      this.listsMerchantsBySubCategories(type);
    },

    listsMerchantsBySubCategories(type) {
      // this.setLoading(true);
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.pageLoading = true);
      this.getFeaturedMerchantsBySubCategory({
        type: type,
        params: this.params,
      })
        .then((res) => {
          // this.setLoading(false);
          if (res.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.pageLoading = false);
        })
        .catch(() => {
          // this.setLoading(false);
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.pageLoading = false);
        });
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.location-pill {
  border-radius: 24px;
  /* background: white; */
  border: 0.5px solid #e0dfe3;
}
@media (max-width: 768px) {
  .merchant-container {
    height: 100%;
  }
}
</style>
