import {
  GET_PURCHASES,
  GET_PURCHASE_HISTORY,
  GET_SINGLE_PURCHASE_HISTORY,
} from "@/utils/api/purchase";

export default {
  getUserPurchases({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_PURCHASES(payload.params)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_PURCHASES", data.data)
            : commit("GET_PURCHASES", data.data);
          // console.log(data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPurchaseHistory({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      GET_PURCHASE_HISTORY(payload.params)
        .then(({ data }) => {
            commit("GET_PURCHASE_HISTORY", data.data);
            resolve(data)

          // function to handle transforming the array to our preferred list type
          // dispatch("handlePurchaseHistoryTabs", {
          //   items: data.data,
          //   filter: payload.params.sort,
          // }).then((res) => {
          //   // commit("GET_PURCHASE_HISTORY", res);
          //   // payload.type === "paginate"
          //   //   ? commit("ADD_PURCHASE_HISTORY", res)
          //     // : 
          //     switch(payload.params.sort){
          //       case 'DATE':
          //         commit("GET_PURCHASE_HISTORY", res);
          //         break;
          //       case 'MERCHANT_CATEGORY':
          //         commit("GET_CATEGORY_PURCHASE_HISTORY", res);
          //         break
          //       case 'MERCHANT':
          //         commit("GET_MERCHANT_PURCHASE_HISTORY", res);
          //     }
          //   resolve(data);
          // });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  handlePurchaseHistoryTabs({}, { items, filter }) {
    // choose what field in the items to filer the array by based on the sort params
    let filterItem;
    switch (filter) {
      case "DATE":
        filterItem = "createdAt";
        break;
      case "MERCHANT_CATEGORY":
        filterItem = "merchantBusinessCategory";
        break;
      case "MERCHANT":
        filterItem = "merchantName";
        break;
      default:
        filterItem = "createdAt";
    }
    const groups = items.reduce((groups, item) => {
      const label =
        filterItem === "createdAt"
          ? item[filterItem].split("T")[0]
          : item[filterItem];
      if (!groups[label]) {
        groups[label] = [];
      }
      groups[label].push(item);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((label) => {
      return {
        label,
        items: groups[label],
      };
    });
    return groupArrays;
  },
  getSinglePurchase({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_SINGLE_PURCHASE_HISTORY(payload)
        .then(({ data }) => {
          // console.log(data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
