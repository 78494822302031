import {
  CREDIT_QUALIFICATION,
  EMPLOYMENT_INFO,
  SUBMIT_EMPLOYMENT_DATA,
  USER_BIO,
  RESEND_EMPLOYMENT_OTP,
  GET_USER_BANKS,
  GET_USER_CASHOUT_ACCOUNT,
  INITIATE_PHONE_VERIFICATION,
  VERIFY_PHONE,
  REGISTRATION_TODO,
} from "@/utils/api/userInfo";
import { VERIFY_BVN, GET_ACCOUNT_SUMMARY_FOR_CUSTOMER } from "@/utils/api/verification";
import { GET_ALL_CUSTOMER_FEES } from "@/utils/api/purchase";

export default {
  getCreditQualification({ commit }) {
    return new Promise((resolve, reject) => {
      CREDIT_QUALIFICATION()
        .then((res) => {
          //   console.log('vuex credit',res.data.data);
          commit("CREDIT_QUALIFICATION", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  employmentInfo({ commit }) {
    return new Promise((resolve, reject) => {
      EMPLOYMENT_INFO()
        .then((res) => {
          commit("EMOLOYMENT_INFO", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addEmploymentInfo({ }, payload) {
    return new Promise((resolve, reject) => {
      SUBMIT_EMPLOYMENT_DATA(payload)
        .then((res) => {
          // console.log("hi", res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUserBio({ commit }) {
    return new Promise((resolve, reject) => {
      USER_BIO()
        .then((res) => {
          commit("GET_USER_BIO", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resendEmploymentOtp() {
    return new Promise((resolve, reject) => {
      RESEND_EMPLOYMENT_OTP()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUserBanks({ commit }) {
    return new Promise((resolve, reject) => {
      GET_USER_BANKS()
        .then((res) => {
          commit("GET_USER_BANKS", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getCashoutAccount({ commit }, payload) {
    commit('CASHOUT_ACCOUNT_HAS_BEEN_CALLED', true)
    return new Promise((resolve, reject) => {
      GET_USER_CASHOUT_ACCOUNT(payload)
        .then((res) => {
          commit("GET_USER_CASHOUT_ACCOUNT", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  initiatePhoneVerification({ }, payload) {
    return new Promise((resolve, reject) => {
      INITIATE_PHONE_VERIFICATION(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verifyPhone({ }, payload) {
    return new Promise((resolve, reject) => {
      VERIFY_PHONE(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verifyBvn({ }, payload) {
    return new Promise((resolve, reject) => {
      VERIFY_BVN(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getRegistrationTodo({ commit }) {
    return new Promise((resolve, reject) => {
      REGISTRATION_TODO()
        .then(({ data }) => {
          commit("SET_REGISTRATION_TODO", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAccountSummary({ commit }) {
    return new Promise((resolve, reject) => {
      GET_ACCOUNT_SUMMARY_FOR_CUSTOMER()
        .then(({ data }) => {
          commit("SET_ACCOUNT_SUMMARY", data.data);
          // console.log(data.data.lastCompletedAccountFinancialHealth);
          // this.customerAccountSummaryData = data.data;
          // if (data.data.lastCompletedAccountFinancialHealth) {
          //   this.verificationOptions[1].status = data.data.lastCompletedAccountFinancialHealth.dataStatus;
          // }
          // if (data.data.pendingDirectStatementRequest) {
          //   this.verificationOptions[0].status = data.data.pendingDirectStatementRequest.dataStatus;
          // }
          resolve(data);
        })
        .catch((error) => {
          this.setLoading(false);
          reject(error);
        });
    });
  },

  getAllCustomerFees({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_ALL_CUSTOMER_FEES(payload)
        .then((res) => {
          if(payload.isCreditCard){
            commit("SET_CREDIT_CARD_FEES", res.data.data);
          } else {
            commit("SET_CHECKOUT_FEES", res.data.data);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
