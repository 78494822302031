<template>
  <div id="app" ref="app">
    <AlertBox />
    <Toast />
    <Loader />
    <component v-if="growthBookReady" :is="layout">
      <!-- <div class="fixed update" v-show="updateExists">
        <div class="text-center cursor-pointer" @click="refreshApp">
          <p>Update Available!</p>
          <p class="font-semibold mt-2">Tap to update</p>
        </div>
      </div> -->
      <transition :name="handleTransition" mode="out-in">
        <router-view />
      </transition>
    </component>
  </div>
</template>
<script>
  import { mapState, mapGetters } from "vuex";
  // import updateMixin from "@/mixins/update";
  import { AlertBox, Toast } from "@/UI/Notification";
  import Loader from "@/UI/Loader";
  import { GET_GROWTHBOOK_DEFINITIONS } from "@/utils/api/auth";
  const default_layout = "default";

  export default {
    // mixins: [updateMixin],
    components: {
      AlertBox,
      Toast,
      Loader,
    },
    computed: {
      handleTransition() {
        return this.layout === "auth-layout" ? "slideX" : null;
      },
      layout() {
        return (this.$route.meta.layout || default_layout) + "-layout";
      },
      ...mapState({
        customer: (state) => state?.auth?.user,
      }),
      ...mapGetters("auth", ["isAuthenticated"]),
    },
    data: () => ({
      growthBookReady: false,
      timer: null
    }),
    watch: {
      isAuthenticated(value) {
        if (value === true) {
          window.Atlas.identify({
            userId: `${this.customer.id}`,
            name: `${this.customer.firstname} ${this.customer.lastname}`,
            email: `${this.customer.email}`,
          });
        }
      },
    },
    mounted() {
      this.recalculateBrowserWindow()
      this.getGrowthBookFeatures();
      // initially set it
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    methods: {
      getGrowthBookFeatures() {
        GET_GROWTHBOOK_DEFINITIONS().then(({ data }) => {
          this.$growthbook.setFeatures(data.features);
          this.growthBookReady = true;
        });
      },
      recalculateBrowserWindow(){
        window.addEventListener('resize', () => {
          // let timer;
          // return (...args) => {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            }, 500);
        })
      }
    },
  };
</script>
<style>
  @import "./assets/main.css";
  @import "~@/assets/styles/animation.css";
  #app {
    font-family: "Inter", Helvetica;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Gilroy", Helvetica;
  }
  p,
  a,
  span,
  td,
  tr,
  button,
  li {
    font-family: "Inter", Helvetica;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  p {
    font-size: 16px;
  }
  @media only screen and (max-width: 600px) {
    p {
      font-size: 14px;
    }
  }
  .update {
    bottom: 0;
    left: 0;
    height: 4rem;
    background-color: black;
    color: white;
    width: 100%;
    text-align: center;
    display: grid;
    place-items: center;
  }
</style>
