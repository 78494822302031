<template>
  <Register />
</template>
<script>
import Register from "@/components/Auth/Register";
export default {
  components: {
    Register,
  },
};
</script>
