<template>
  <!-- <div> -->
  <div
    class="line"
    :style="{
      width: width,
      height: height,
      'border-radius': type === 'circle' ? '50%' : radius,
    }"
  ></div>
  <!-- </div> -->
</template>
<script>
  export default {
    props: {
      width: {
        type: String,
        default: "6rem",
        required: false,
      },
      height: {
        type: String,
        default: "1rem",
        required: false,
      },
      type: {
        type: String,
        default: "rectangle",
        required: false,
      },
      radius: {
        type: String,
        default: "5px",
        required: false,
      },
    },
  };
</script>
<style scoped>
  .line {
    height: 7px;
    width: 150px;
    margin-bottom: 6px;
    border-radius: 5px;
    background: rgba(207, 203, 242, 0.2);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(8%, rgba(207, 203, 242, 0.2)),
      color-stop(8%, rgba(207, 203, 242, 0.3)),
      color-stop(8%, rgba(207, 203, 242, 0.2)),
      /* color-stop(18%, rgba(130, 130, 130, 0.3)),
      color-stop(33%, rgba(130, 130, 130, 0.2)) */
    );
    background: linear-gradient(
      to right,
      rgba(8%, rgba(207, 203, 242, 0.2)) 8%,
      rgba(8%, rgba(207, 203, 242, 0.4)) 18%,
      rgba(8%, rgba(207, 203, 242, 0.2)) 33%,
      /* rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33% */
    );
    background-size: 800px 100px;
    animation: wave-lines 2s infinite ease-out;
  }
  @keyframes wave-lines {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  @keyframes wave-squares {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
</style>
