export const KYC_STEPS = {
  phone: "Phone",
  camera: "Camera",
  bvn: "Bvn",
  address: "Address",
  success: "Success",
  personal: "PersonalDetails",
};

export const CREDIT_QUALIFICATION_STEPS = {
  bank: "Bank",
  work: "Work",
  success: "success",
};

export const DATA_STATUS = {
  available: "AVAILABLE",
  failed: "FAILED",
  pending: "PENDING",
  flagged: "FLAGGED",
  pendingAdminReview: "PENDING_ADMIN_REVIEW",
};

export const STATEMENT_REQUEST_STATUS = {
  requested: "STATEMENT_REQUESTED",
  sent: "TICKET_SENT",
  confirmed: "STATEMENT_CONFIRMED",
  recieved: "STATEMENT_RECIEVED",
  analyzed: "STATEMENT_ANALYZED",
  failed: "FAILED",
  statement_recieved:'STATEMENT_RECEIVED',
  statement_sent: 'STATEMENT_SENT',
};

export const FORM_TYPES = {
  amount: "AMOUNT",
  text: "TEXT",
  number: "NUMBER",
  phone: "PHONE_NUMBER",
  option: "OPTION",
  date: "DATE",
  upload: "FILE_UPLOAD",
  address: "ADDRESS",
  email: 'EMAIL'
};

export const DASHBOARD_STEPS = {
  personalDetails: "USER_DETAILS_UPDATE",
  credit: "CREDIT_QUALIFICATION",
  overduePayment: "CLEAR_OVERDUE_PAYMENT",
  identityVerification: "IDENTITY_VERIFICATION",
  cardZilla: "CREATE_FIRST_ZILLA_CARD",
  purchase: "MAKE_FIRST_PURCHASE",
  financialHealth:'FINANCIAL_HEALTH',
  employment: 'EMPLOYMENT_VERIFICATION'
};

export const TICKET_CHANNELS = {
  zilla_app: "ZILLA_APP",
  bank_app: "BANK_APP"
};

export const ONBOARDING_STATUS = {
  completed: {
    name: "COMPLETED",
    textColor: "text-dark",
    bgColor: "bg-lightGreen"
  },
  failed: {
    name: "FAILED",
    textColor: "text-brandRed",
    bgColor: "bg-lightRed-3"
  },
  pending: {
    name: "PENDING",
    textColor: "text-grey-3",
    bgColor: "bg-yellow"
  },
  expired: {
    name: "EXPIRED",
    textColor: "text-brandRed",
    bgColor: "bg-lightRed-3"
  },
  inProgress: {
    name: "IN_PROGRESS",
    textColor: "text-grey-3",
    bgColor: "bg-yellow"
  },
  awaitingApproval: {
    name: "AWAITING_APPROVAL",
    textColor: "text-grey-3",
    bgColor: "bg-yellow"
  }
};
