var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: _vm.currentAnimation } }, [
    _vm.display
      ? _c(
          "div",
          {
            staticClass: "fixed modal-container",
            on: { click: _vm.clickTarget },
          },
          [
            _c("transition", { attrs: { name: _vm.currentAnimation } }, [
              _vm.display
                ? _c("div", { staticClass: "modal-body bg-white p-6" }, [
                    _c("div", { staticClass: "flex justify-end" }, [
                      _c("img", {
                        staticClass: "h-3 w-3 cursor-pointer",
                        attrs: {
                          src: "https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg",
                          alt: "cancel",
                        },
                        on: { click: _vm.closeDisplay },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "completed mt-8" },
                      [
                        _vm.type === "green-success"
                          ? _c(
                              "svg",
                              {
                                staticClass: "h-32 w-32 mx-auto",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "90",
                                  height: "90",
                                  viewBox: "0 0 90 90",
                                  fill: "none",
                                },
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "90",
                                    height: "90",
                                    rx: "45",
                                    fill: "#F5FFFB",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M23.4393 46.1512L24.1412 46.8531L23.4393 46.1512C22.0193 47.5712 22.0193 49.8674 23.4393 51.2874L34.504 62.3521C35.924 63.7721 38.2201 63.7721 39.6401 62.3521L67.646 34.3462C69.066 32.9262 69.066 30.6301 67.646 29.2101C66.2261 27.7902 63.9302 27.79 62.5102 29.2097C62.5101 29.2098 62.51 29.2099 62.5099 29.2101L37.0592 54.635L28.5754 46.1512C27.1554 44.7312 24.8593 44.7312 23.4393 46.1512Z",
                                    fill: "#2C994B",
                                    stroke: "#2C994B",
                                    "stroke-width": "1.98529",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.type === "success"
                          ? _c(
                              "svg",
                              {
                                staticClass: "h-32 w-32 mx-auto",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "131",
                                  height: "130",
                                  viewBox: "0 0 131 130",
                                  fill: "none",
                                },
                              },
                              [
                                _c("circle", {
                                  attrs: {
                                    cx: "65.5",
                                    cy: "64.885",
                                    r: "64.885",
                                    fill: "#6A30C3",
                                  },
                                }),
                                _c("circle", {
                                  attrs: {
                                    cx: "65.5",
                                    cy: "64.885",
                                    r: "64.885",
                                    fill: "url(#paint0_linear_1_8514)",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M34.0682 71.9458L51.2778 87.1396L90.3074 38.9212",
                                    stroke: "white",
                                    "stroke-width": "6",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                  },
                                }),
                                _c(
                                  "defs",
                                  [
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id: "paint0_linear_1_8514",
                                          x1: "-811.589",
                                          y1: "622.896",
                                          x2: "436.487",
                                          y2: "1015.37",
                                          gradientUnits: "userSpaceOnUse",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.38059",
                                            "stop-color": "#C6003B",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.469915",
                                            "stop-color": "#6A30C3",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.667674",
                                            "stop-color": "#63DFDF",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.type === "error"
                          ? _c(
                              "svg",
                              {
                                staticClass: "h-32 w-32 mx-auto",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "131",
                                  height: "130",
                                  viewBox: "0 0 131 130",
                                  fill: "none",
                                },
                              },
                              [
                                _c("circle", {
                                  attrs: {
                                    cx: "65.5",
                                    cy: "64.885",
                                    r: "64.885",
                                    fill: "#6A30C3",
                                  },
                                }),
                                _c("circle", {
                                  attrs: {
                                    cx: "65.5",
                                    cy: "64.885",
                                    r: "64.885",
                                    fill: "url(#paint0_linear_1_9402)",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    "fill-rule": "evenodd",
                                    "clip-rule": "evenodd",
                                    d: "M120.615 65C120.615 95.3757 95.9907 120 65.615 120C35.2393 120 10.615 95.3757 10.615 65C10.615 34.6243 35.2393 10 65.615 10C95.9907 10 120.615 34.6243 120.615 65ZM65.1864 74.5571C62.0293 74.5571 59.615 72.1429 59.615 68.9857V40.5714C59.615 37.4143 62.0293 35 65.1864 35C68.3436 35 70.7578 37.4143 70.7578 40.5714V68.9857C70.7578 72.1429 68.3436 74.5571 65.1864 74.5571ZM61.2864 93.3147C62.215 94.429 63.7007 94.9862 65.1864 94.9862C66.6721 94.9862 68.1578 94.429 69.4578 93.3147C70.5721 92.3862 71.1293 90.9004 71.1293 89.4147C71.1293 89.229 71.0828 89.0433 71.0364 88.8576C70.99 88.6719 70.9436 88.4862 70.9436 88.3004C70.8507 88.1147 70.8043 87.929 70.7578 87.7433C70.7114 87.5576 70.665 87.3719 70.5721 87.1862C70.4793 87.0005 70.3864 86.8612 70.2936 86.7219C70.2007 86.5826 70.1078 86.4433 70.015 86.2576C69.8293 85.8862 69.4578 85.5147 69.2721 85.329C67.2293 83.2862 63.515 83.2862 61.4721 85.329C61.1007 85.7004 60.915 85.8862 60.7293 86.2576C60.6364 86.4433 60.5436 86.5826 60.4507 86.7219C60.3578 86.8612 60.265 87.0005 60.1721 87.1862C59.9864 87.5576 59.8007 87.929 59.8007 88.3004C59.615 88.6719 59.615 89.0433 59.615 89.4147C59.615 90.9004 60.1721 92.2004 61.2864 93.3147Z",
                                    fill: "white",
                                  },
                                }),
                                _c(
                                  "defs",
                                  [
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id: "paint0_linear_1_9402",
                                          x1: "-811.589",
                                          y1: "622.896",
                                          x2: "436.487",
                                          y2: "1015.37",
                                          gradientUnits: "userSpaceOnUse",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.38059",
                                            "stop-color": "#C6003B",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.469915",
                                            "stop-color": "#6A30C3",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.667674",
                                            "stop-color": "#63DFDF",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.type === "cancel"
                          ? _c(
                              "svg",
                              {
                                staticClass: "h-32 w-32 mx-auto",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "131",
                                  height: "130",
                                  viewBox: "0 0 131 130",
                                  fill: "none",
                                },
                              },
                              [
                                _c("circle", {
                                  attrs: {
                                    cx: "65.5",
                                    cy: "65",
                                    r: "65",
                                    fill: "#FC3C11",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    "fill-rule": "evenodd",
                                    "clip-rule": "evenodd",
                                    d: "M44.4345 38.1907C42.8481 36.6044 40.2761 36.6044 38.6898 38.1907C37.1034 39.7771 37.1034 42.3491 38.6898 43.9355L59.7549 65.0006L38.692 86.0634C37.1056 87.6498 37.1056 90.2218 38.692 91.8081C40.2784 93.3945 42.8504 93.3945 44.4367 91.8081L65.4996 70.7453L86.5624 91.8081C88.1488 93.3945 90.7208 93.3945 92.3072 91.8081C93.8935 90.2218 93.8935 87.6498 92.3072 86.0634L71.2443 65.0006L92.3094 43.9355C93.8958 42.3491 93.8958 39.7771 92.3094 38.1907C90.723 36.6044 88.151 36.6044 86.5647 38.1907L65.4996 59.2558L44.4345 38.1907Z",
                                    fill: "white",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "h3",
                          {
                            staticClass:
                              "text-lg md:text-xl font-bold mt-6 text-black mb-2",
                            class: [
                              _vm.type !== "green-success" ? "text-center" : "",
                            ],
                          },
                          [_vm._v(" " + _vm._s(_vm.title) + " ")]
                        ),
                        _c(
                          "h3",
                          {
                            class: [
                              _vm.type !== "green-success"
                                ? "text-lg md:text-xl font-bold mt-6 text-black text-center"
                                : "text-sm",
                            ],
                          },
                          [_vm._v(" " + _vm._s(_vm.description) + " ")]
                        ),
                        _c("p"),
                        _c(
                          "p",
                          { staticClass: "text-center text-sm warning" },
                          [_vm._v(_vm._s(_vm.info))]
                        ),
                        _c("Button", {
                          staticClass: "mt-12",
                          attrs: {
                            text: _vm.buttonText ? _vm.buttonText : "Close",
                            width: "w-full",
                          },
                          on: { click: _vm.closeDisplay },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }