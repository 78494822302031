// function initFreshChat() {
//   window.fcWidget.init({
//     token: "762ef54f-72cb-4f93-9dd7-50dbbcd91428",
//     host: "https://wchat.freshchat.com",
//     config: {
//       headerProperty: {
//         hideChatButton: true,
//         appName: "Zilla Support",
//         Color: "#FFFF00",
//         appLogo:
//           "https://res.cloudinary.com/zillaafrica/image/upload/v1641561622/customer/favicon_wlsw4x.png",
//       },
//     },
//   });
// }
// function initialize(i, t) {
//   var e;
//   i.getElementById(t)
//     ? initFreshChat()
//     : (((e = i.createElement("script")).id = t),
//       (e.async = !0),
//       (e.src = "https://wchat.freshchat.com/js/widget.js"),
//       (e.onload = initFreshChat),
//       i.head.appendChild(e));
// }
// function initiateCall() {
//   initialize(document, "Freshdesk Messaging-js-sdk");
// }
// window.addEventListener
//   ? window.addEventListener("load", initiateCall, !1)
//   : window.attachEvent("load", initiateCall, !1);
