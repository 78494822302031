import {KYC_STEPS, CREDIT_QUALIFICATION_STEPS} from '@/utils/sharedData/home'

export const initialState = () => {
  return {
    userWallet: {},
    userCards: [],
    // showActivate: false,
    instaPayWalletHistory: [],
    showReminder: false,
    dashboardData: {},
    featuredMerchants: [],
    subCategoryList:[],
    kycSteps: {
      activeTab: KYC_STEPS.bvn,
      display: false,
    },
    creditQualificationSteps: {
      activeTab: CREDIT_QUALIFICATION_STEPS.work,
      display: false,
    },
    personalDetails:{
      dynamicForm:{},
      display: false
    },
    subCategoryList: [],
    showDashboardEntry: false
  };
};
