import axios from "@/plugins/axios";

export const USER_BIO = () => {
  return axios.get(`/bnpl/customer/bio`);
};

export const SUBMIT_ADDRESS = (address) => {
  return axios.put(`/bnpl/customer/submit-address-information`, address);
};

export const SUBMIT_EMPLOYMENT_OTP = (otp) => {
  return axios.put(
    `/bnpl/employment-information/validate-work-email-otp/otp/${otp}`
  );
};

export const SUBMIT_EMPLOYMENT_DATA = ({ method, data }) => {
  return axios[method](`/bnpl/employment-information`, data);
};

export const RESEND_EMPLOYMENT_OTP = () => {
  return axios.put(
    "/bnpl/employment-information/request-work-email-verification"
  );
};

export const CUSTOMER_EMPLOYMENT_TYPE = (type) => {
  return axios.put(`/bnpl/customer/employment-status/${type}`);
};

export const SUBMIT_PROFILE_PICTURE = (data) => {
  return axios.put(`/bnpl/customer/submit-and-verify-profile-picture`, data);
};

export const ADD_CARD = (data) => {
  return axios.post(
    "/wallet/transaction/initialize-add-new-card-transaction",
    data
  );
};

export const CREDIT_QUALIFICATION = () => {
  return axios.get(
    `/bnpl/v2/customer-credit-qualification/get-customer-status`
  );
};

export const EMPLOYMENT_INFO = () => {
  return axios.get(`/bnpl/v2/employment-information/login-principal`);
};

export const GET_USER_BANKS = () => {
  return axios.get(`/bnpl/account-financial-health/logged-in`);
};

export const GET_USER_CASHOUT_ACCOUNT = (id) => {
  return axios.get(`/bnpl/cashout-bank-account`, {
    params: {
      ownerId: `${id}`,
    },
  });
};

export const INITIATE_PHONE_VERIFICATION = (phone) => {
  return axios.put(`/bnpl/customer/phone/${phone}/send-otp`);
};

export const VERIFY_PHONE = (data) => {
  return axios.put(`/bnpl/customer/verify-phone`, data);
};

export const GET_ADDRESSES = (payload) => {
  return axios.get(`/bnpl/v1/address`, { params: payload });
};

export const CREATE_NEW_ADDRESS = (data) => {
  return axios.post(`/bnpl/v1/address`, data);
};

export const REGISTRATION_TODO = () => {
  return axios.get(`/bnpl/v2/dashboard/customer/registration/todo`);
};

export const GET_CARD_PROCESSERS = () => {
  return axios.get(`/wallet/cards/processors`);
};
