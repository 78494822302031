import Vue from "vue";
import { GrowthBook } from "@growthbook/growthbook";
import { GET_GROWTHBOOK_DEFINITIONS } from "@/utils/api/auth";

const growthbook = new GrowthBook({
  // enableDevMode: true allows you to use the Chrome DevTools Extension to test/debug.
  enableDevMode: true,
});

Vue.prototype.$growthbook = growthbook;
