import axios from "@/plugins/axios";

export const MERCHANT_BRIEF = (id) => {
  return axios.get(`/bnpl/merchant/merchantOutlet/${id}/brief-data`);
};

export const GET_FEATURED_SUBCATEGORIES = (payload) => {
  return axios.get(`/bnpl/v1/featured-subcategory`, { params: payload });
};

export const GET_MERCHANTS_BY_SUBCATEGORY = (payload) => {
  return axios.get(`/bnpl/merchant/by-subcategory`, { params: payload });
};

export const INCREMENT_SUBCATEGORY_COUNT = (data) => {
  return axios.put(`/bnpl/v1/featured-subcategory/increment-click-count`, data);
};

