<template>
  <div class="relative">
    <p class="label-text" v-if=label.length>{{ label }} <span v-if="required">*</span> </p>
    <div class="flex justify-between items-center input input-regular">
      <p v-if="!name.length">{{ text }}</p>
      <p class>{{ name | truncate(30) }}</p>
      <label class="" :class="[width ? width : null]" :for="id">
        <div>
          <!-- <img
          class="h-8 w-8 mx-auto"
          src="@/assets/icons/uploadImage.svg"
          alt=""
        /> -->
          <!-- <Button text="Upload" /> -->

          <div class="upload-button">
            <div class="lds-dual-ring" v-if="loading"></div>

            <div v-else>
              <p class="flex items-center cursor-pointer" v-if="!uploaded">
                <span class="text-sm mr-2">Attach</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="16"
                  viewBox="0 0 8 16"
                  fill="none"
                >
                  <path
                    d="M6.99984 4.50002V11.5534C6.99984 12.9467 5.97984 14.1867 4.59317 14.32C2.99984 14.4733 1.6665 13.2267 1.6665 11.6667V3.42668C1.6665 2.55335 2.29317 1.76002 3.15984 1.67335C4.15984 1.57335 4.99984 2.35335 4.99984 3.33335V10.3333C4.99984 10.7 4.69984 11 4.33317 11C3.9665 11 3.6665 10.7 3.6665 10.3333V4.50002C3.6665 4.22668 3.43984 4.00002 3.1665 4.00002C2.89317 4.00002 2.6665 4.22668 2.6665 4.50002V10.24C2.6665 11.1133 3.29317 11.9067 4.15984 11.9934C5.15984 12.0934 5.99984 11.3133 5.99984 10.3333V3.44668C5.99984 2.05335 4.97984 0.813349 3.59317 0.680016C2.0065 0.526683 0.666504 1.77335 0.666504 3.33335V11.5134C0.666504 13.4267 2.0665 15.14 3.97317 15.32C6.1665 15.52 7.99984 13.8134 7.99984 11.6667V4.50002C7.99984 4.22668 7.77317 4.00002 7.49984 4.00002C7.2265 4.00002 6.99984 4.22668 6.99984 4.50002Z"
                    fill="#1E1452"
                  />
                </svg>
              </p>

              <p class="flex items-center cursor-pointer" v-else @click.stop="deleteFile()">
                <span class="text-sm mr-2 text-brandRed">Delete</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="16"
                  viewBox="0 0 8 16"
                  fill="none"
                >
                  <path
                    d="M6.99984 4.50002V11.5534C6.99984 12.9467 5.97984 14.1867 4.59317 14.32C2.99984 14.4733 1.6665 13.2267 1.6665 11.6667V3.42668C1.6665 2.55335 2.29317 1.76002 3.15984 1.67335C4.15984 1.57335 4.99984 2.35335 4.99984 3.33335V10.3333C4.99984 10.7 4.69984 11 4.33317 11C3.9665 11 3.6665 10.7 3.6665 10.3333V4.50002C3.6665 4.22668 3.43984 4.00002 3.1665 4.00002C2.89317 4.00002 2.6665 4.22668 2.6665 4.50002V10.24C2.6665 11.1133 3.29317 11.9067 4.15984 11.9934C5.15984 12.0934 5.99984 11.3133 5.99984 10.3333V3.44668C5.99984 2.05335 4.97984 0.813349 3.59317 0.680016C2.0065 0.526683 0.666504 1.77335 0.666504 3.33335V11.5134C0.666504 13.4267 2.0665 15.14 3.97317 15.32C6.1665 15.52 7.99984 13.8134 7.99984 11.6667V4.50002C7.99984 4.22668 7.77317 4.00002 7.49984 4.00002C7.2265 4.00002 6.99984 4.22668 6.99984 4.50002Z"
                    fill="#FC3C11"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        <input
          class="hidden"
          type="file"
          :id="id"
          @change.stop=" uploadImage($event)"
        />
      </label>
    </div>
    <p class="text-right text-grey text-xs mt-1">{{ desc }}</p>

    <!-- 
    <AnimateHeight>
      <div
        class="p-4 rounded flex justify-between mt-2 bg-dashGrey"
        v-show="uploadSuccess"
      >
        <div class="flex items-center">
          <div class="mr-2">
          </div>
          <div>
            <p class="text-brandGreen text-sm">{{ name }}</p>
            <p class="text-grey text-sm">{{ size }}KB</p>
          </div>
        </div>
     
      </div>
    </AnimateHeight> -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { UPLOAD_IMAGE } from "@/utils/api/media";
import AnimateHeight from "../../AnimateHeight";
import { Button, SecondaryButton } from "@/UI/Button";
export default {
  components: {
    AnimateHeight,
    Button,
    SecondaryButton,
  },
  props: {
    id: {
      type: String,
      deafult: "",
      required: false,
    },
    value: {
      type: String,
      default: "",
      // required: true,
    },
    text: {
      type: String,
      default: "",
      required: false,
    },
    validation: {
      type: Boolean,
      default: false,
      required: false,
    },
    fileType: {
      type: Array,
      default: () => [],
      required: true,
    },
    width: {
      type: String,
      default: "w-auto",
      required: false,
    },
    fileSize: {
      type: Number,
      default: 5000,
      required: false,
    },
    desc: {
      type: String,
      default: "Maximum file size: 5mb",
      required: false,
    },
    label: {
        type: String,
        default: "",
        required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  mounted() {
    if (this.text.length > 0) {
      this.uploaded = true;
    }
  },
  data() {
    return {
      loading: false,
      name: "",
      size: "",
      image: "",
      uploadSuccess: false,
      imageData: "",
      uploaded: false,
      // accept: ['.png'],
    };
  },
  methods: {
    ...mapActions("notification", ["showToast"]),
    valid() {},
    uploadImage(e) {
      if (this.fileType.length > 0) {
        let valid;
        // console.log(e, "valid");

        // try to get the file extention;
        let fileName = e.target.files[0].name.split(".");
        let name = fileName[fileName.length - 1];
        if (this.fileType.includes(name)) {
          // no upload greater than fileSize
          this.size = e.target.files[0].size / 1000;
          // console.log(e, "valid");
          if (this.size <= this.fileSize) {
            this.loading = true;
            const formData = new FormData();
            this.name = e.target.files[0].name;

            this.image = e.target.name;
            formData.append("file", e.target.files[0]);
            UPLOAD_IMAGE(formData)
              .then((res) => {
                this.$emit("valid", true);
                this.loading = false;
                this.uploadSuccess = true;
                this.showToast({
                  description: "File uploaded successfully",
                  display: true,
                  icon: "success",
                });
                this.uploaded = true;
                this.imageData = res.data.data;
                this.$emit("fileUploaded", this.name);
                this.$emit("upload", res.data.data);
                this.$emit('input', res.data.data)
              })
              .catch(() => {
                this.showToast({
                  icon: "error",
                  title: " 😌",
                  display: true,
                  description: "Upload Failed, Please try again",
                });
                this.loading = false;
              });
          } else {
            this.showToast({
              display: true,
              description: `File size cannot be more then ${
                this.fileSize >= 1000 ? this.fileSize / 1000 : this.fileSize
              }${this.fileSize < 1000 ? "KB" : "MB"}`,
              icon: "error",
            });
          }
        } else {
          this.showToast({
            display: true,
            description: "Please upload a supported file format",
            icon: "error",
          });
          valid = false;
        }
      }
    },
    deleteFile() {
      this.uploaded = false;
      this.name = "";
      this.$emit("deleted");
    },
  },
};
</script>
<style scoped>
label {
  display: inline-block;
}
.input {
  /* border: 1px solid hsl(251, 100%, 90%); */
  padding: 14px 10px;
  color: #706d78 !important;
  border-radius: 8px;
  font-size: 18px;
  background-color: transparent;
  position: relative;
  z-index: 2;
  -webkit-appearance: none;
  transition: all 0.3s linear;
  height: 3rem;
}
.label-text{
  color: #1e1d20;
    font-weight: 400;
    font-size: 14px;
    display: block;
    margin-bottom: 8px
}
.input-regular {
  border: 1px solid #ededf0 !important;
  background-color: #fff !important;
}
.input-active {
  border: 1px solid #d5ccff !important;
  background-color: transparent !important;
}
.error-border {
  border: 1px solid #ffd5cc !important;
  background: #fff7f5 !important;
  color: #fc3c11 !important;
}
.placeholder {
  color: #9d9d9d;
}
.line {
  /* border: 1px solid black; */
  background: black;
  height: 1px;
  margin-top: 0.3rem;
}
.bg {
  background-color: #eefcff;
}
.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.lds-dual-ring {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  top: -0.5rem;
  z-index: 5;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 22px;
  height: 22px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #023a59;
  border-color: #023a59 transparent #023a59 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
