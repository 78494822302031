var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.selectOptions, function (option) {
      return _c(
        "label",
        {
          key: option,
          staticClass: "flex items-center",
          class: [_vm.p ? _vm.p : "pt-1"],
        },
        [
          _vm.type === "box"
            ? _c("div", [
                _vm.selected === option
                  ? _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "17",
                          height: "16",
                          viewBox: "0 0 17 16",
                          fill: "none",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M11.1667 0H1.83333C1.1 0 0.5 0.6 0.5 1.33333V10.6667C0.5 11.4 1.1 12 1.83333 12H11.1667C11.9 12 12.5 11.4 12.5 10.6667V1.33333C12.5 0.6 11.9 0 11.1667 0ZM11.1667 10.6667H1.83333V1.33333H11.1667V10.6667ZM10.4933 4L9.55333 3.05333L5.16 7.44667L3.44 5.73333L2.49333 6.67333L5.16 9.33333L10.4933 4Z",
                            fill: "#6B6873",
                          },
                        }),
                      ]
                    )
                  : _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "17",
                          height: "16",
                          viewBox: "0 0 17 16",
                          fill: "none",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.chooseOption(option)
                          },
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M11.1667 1.33333V10.6667H1.83333V1.33333H11.1667ZM11.1667 0H1.83333C1.1 0 0.5 0.6 0.5 1.33333V10.6667C0.5 11.4 1.1 12 1.83333 12H11.1667C11.9 12 12.5 11.4 12.5 10.6667V1.33333C12.5 0.6 11.9 0 11.1667 0Z",
                            fill: "#6B6873",
                          },
                        }),
                      ]
                    ),
              ])
            : _vm.type === "circle"
            ? _c("div", [
                _vm.selected === option
                  ? _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "17",
                          height: "18",
                          viewBox: "0 0 17 18",
                          fill: "none",
                        },
                      },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "8.5",
                            cy: "9",
                            r: "7.5",
                            fill: "white",
                            stroke: "#D5CCFF",
                            "stroke-width": "2",
                          },
                        }),
                        _c("circle", {
                          attrs: {
                            cx: "8.5",
                            cy: "9",
                            r: "4.5",
                            fill: "#6956C8",
                          },
                        }),
                      ]
                    )
                  : _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "17",
                          height: "18",
                          viewBox: "0 0 17 18",
                          fill: "none",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.chooseOption(option)
                          },
                        },
                      },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "8.5",
                            cy: "9",
                            r: "7.5",
                            fill: "white",
                            stroke: "#D5CCFF",
                            "stroke-width": "2",
                          },
                        }),
                      ]
                    ),
              ])
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "ml-4 text-dark font-semibold",
              on: {
                click: function ($event) {
                  return _vm.chooseOption(option)
                },
              },
            },
            [_vm._v(" " + _vm._s(option))]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }