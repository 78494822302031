import {
  GET_ADDRESSES,
  CREATE_NEW_ADDRESS,
  GET_PRIMARY_ADDRESS
} from "@/utils/api/address";

export default {
  getUserAddress({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_ADDRESSES(payload.params)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_ADDRESS", data.data)
            : commit("GET_ADDRESS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addNewAddress({ }, payload) {
    return new Promise((resolve, reject) => {
      CREATE_NEW_ADDRESS(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getPrimaryAddress({ }, payload) {
    return new Promise((resolve, reject) => {
      GET_PRIMARY_ADDRESS(payload.params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getPrimaryAddress({ }) {
    return new Promise((resolve, reject) => {
      GET_PRIMARY_ADDRESS()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
