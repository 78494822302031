<template>
  <div class="mb-4">
    <div v-if="showNav" class="overlay z-20"></div>
    <div class="flex justify-between items-center px-6 py-4 bg-white header">
      <div class="h-6 w-20 md:h-8 md:w-24">
        <img
          @click="$router.push('/')"
          class="max-h-full max-w-full cursor-pointer"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg"
          alt=""
        />
      </div>
      <div class="flex items-center">
        <!-- <button
          class="purchase-button bg-brandPurple flex items-center mr-4"
          @click="showCheckout = true"
        >
          <svg
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
          >
            <path
              d="M11 8.50098c.55 0 1-.45 1-1v-2h2c.55 0 1-.45 1-1s-.45-1-1-1h-2v-2c0-.55-.45-1-1-1s-1 .45-1 1v2H8c-.55 0-1 .45-1 1s.45 1 1 1h2v2c0 .55.45 1 1 1ZM6 17.501c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2Zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2Zm-8.9-5h7.45c.75 0 1.41-.41 1.75-1.03l3.38-6.13002c.27-.48.09-1.09-.39-1.36-.48-.26-1.09-.09-1.35.39L14.55 10.501H7.53L3.54 2.07098c-.16-.35-.52-.57-.9-.57H1c-.55 0-1 .45-1 1s.45 1 1 1h1L5.6 11.091l-1.35 2.44c-.73 1.34.23 2.97 1.75 2.97h11c.55 0 1-.45 1-1s-.45-1-1-1H6l1.1-2Z"
              fill="#ffffff"
            />
          </svg>
          <p class="text-xs text-white">Make purchase</p>
        </button> -->
        <svg
          @click.stop="showNav = !showNav"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="13"
          viewBox="0 0 18 13"
          fill="none"
        >
          <path
            d="M0 12.5H18V10.5H0V12.5ZM0 7.5H18V5.5H0V7.5ZM0 0.5V2.5H18V0.5H0Z"
            fill="#1E1D20"
          />
        </svg>
      </div>
    </div>
    <transition name="slide">
      <div
        v-if="showNav"
        v-click-outside="handleOptionsOutside"
        class="z-50 sidebar-menu inset-y-0 left-0 w-2/3 bg-white overflow-y-auto sm:hidden"
      >
        <svg
          @click.stop="showNav = !showNav"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          class="absolute top-10 right-6 cursor-pointer"
        >
          <path
            d="M1 1L11 11M1 11L11 1"
            stroke="#292929"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="relative w-full">
          <div class="profile-image mt-24">
            <transition name="fade">
              <div class="h-20 w-20">
                <img
                  v-show="showImage"
                  class="icon rounded-full h-20 w-20"
                  :src="profilePicture"
                  alt="profile picture"
                  @error="handleImageError"
                  @load="showImage = true"
                />
              </div>
            </transition>
            <p class="pt-3 font-semibold text-lg">
              {{ activeUser.firstname | capitalize }}
            </p>
          </div>

          <div class="absolute left-0 pt-3 sm:hidden">
            <div class="grid h-full">
              <div>
                <div class="sidebar-content mt-8">
                  <div @click.stop="showNav = !showNav">
                    <router-link
                      to="/"
                      class="flex nav-item items-center"
                      exact
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        class="mr-3"
                      >
                        <path
                          d="M0.333496 21.6377H23.6668V23.971H0.333496V21.6377ZM2.66683 12.3044H5.00016V20.471H2.66683V12.3044ZM8.50016 12.3044H10.8335V20.471H8.50016V12.3044ZM13.1668 12.3044H15.5002V20.471H13.1668V12.3044ZM19.0002 12.3044H21.3335V20.471H19.0002V12.3044ZM0.333496 6.47103L12.0002 0.637695L23.6668 6.47103V11.1377H0.333496V6.47103ZM12.0002 7.6377C12.3096 7.6377 12.6063 7.51478 12.8251 7.29599C13.0439 7.07719 13.1668 6.78045 13.1668 6.47103C13.1668 6.16161 13.0439 5.86486 12.8251 5.64607C12.6063 5.42728 12.3096 5.30436 12.0002 5.30436C11.6907 5.30436 11.394 5.42728 11.1752 5.64607C10.9564 5.86486 10.8335 6.16161 10.8335 6.47103C10.8335 6.78045 10.9564 7.07719 11.1752 7.29599C11.394 7.51478 11.6907 7.6377 12.0002 7.6377Z"
                          fill=""
                        />
                      </svg>
                      <p class="text-grey">Overview</p>
                    </router-link>
                  </div>
                  <div @click.stop="showNav = !showNav">
                    <router-link
                      to="/purchases"
                      class="flex nav-item items-center mt-6"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="21"
                        viewBox="0 0 18 21"
                        class="mr-4"
                      >
                        <path
                          d="M17 20.3047H1C0.734784 20.3047 0.48043 20.1993 0.292893 20.0118C0.105357 19.8243 0 19.5699 0 19.3047V1.30469C0 1.03947 0.105357 0.785117 0.292893 0.597581C0.48043 0.410044 0.734784 0.304688 1 0.304688H17C17.2652 0.304688 17.5196 0.410044 17.7071 0.597581C17.8946 0.785117 18 1.03947 18 1.30469V19.3047C18 19.5699 17.8946 19.8243 17.7071 20.0118C17.5196 20.1993 17.2652 20.3047 17 20.3047ZM6 4.30469H4V6.30469C4 7.63077 4.52678 8.90254 5.46447 9.84022C6.40215 10.7779 7.67392 11.3047 9 11.3047C10.3261 11.3047 11.5979 10.7779 12.5355 9.84022C13.4732 8.90254 14 7.63077 14 6.30469V4.30469H12V6.30469C12 7.10034 11.6839 7.8634 11.1213 8.42601C10.5587 8.98862 9.79565 9.30469 9 9.30469C8.20435 9.30469 7.44129 8.98862 6.87868 8.42601C6.31607 7.8634 6 7.10034 6 6.30469V4.30469Z"
                          fill=""
                        />
                      </svg>
                      <p class="text-grey">My Purchases</p>
                    </router-link>
                  </div>
                  <!-- <div @click.stop="showNav = !showNav">
                    <router-link
                      to="/repayments"
                      class="flex nav-item items-center mt-6"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        class="mr-4"
                      >
                        <path
                          d="M20 7.30469V17.3047C20 17.5699 19.8946 17.8243 19.7071 18.0118C19.5196 18.1993 19.2652 18.3047 19 18.3047H1C0.734784 18.3047 0.48043 18.1993 0.292893 18.0118C0.105357 17.8243 0 17.5699 0 17.3047V7.30469H20ZM20 5.30469H0V1.30469C0 1.03947 0.105357 0.785117 0.292893 0.597581C0.48043 0.410044 0.734784 0.304688 1 0.304688H19C19.2652 0.304688 19.5196 0.410044 19.7071 0.597581C19.8946 0.785117 20 1.03947 20 1.30469V5.30469ZM13 13.3047V15.3047H17V13.3047H13Z"
                          fill=""
                        />
                      </svg>
                      <p class="text-grey">My Payments</p>
                    </router-link>
                  </div> -->
                  <div @click.stop="showNav = !showNav" v-if="$growthbook.isOn('issued-card')">
                    <router-link
                      to="/drawer"
                      class="flex nav-item items-center mt-6"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        class="mr-4"
                      >
                        <path
                          d="M4.75 0.609863C5.30812 0.609863 5.86077 0.719793 6.37641 0.933375C6.89204 1.14696 7.36056 1.46001 7.75521 1.85466C8.14986 2.24931 8.46291 2.71782 8.67649 3.23346C8.89007 3.74909 9 4.30175 9 4.85986V9.10986H4.75C3.62283 9.10986 2.54183 8.6621 1.7448 7.86507C0.947768 7.06804 0.500002 5.98703 0.500002 4.85986C0.500002 3.73269 0.947768 2.65169 1.7448 1.85466C2.54183 1.05763 3.62283 0.609863 4.75 0.609863ZM4.75 11.1099H9V15.3599C9 16.2004 8.75074 17.0221 8.28375 17.721C7.81675 18.4199 7.15299 18.9647 6.37641 19.2864C5.59982 19.608 4.74529 19.6922 3.92087 19.5282C3.09645 19.3642 2.33917 18.9594 1.7448 18.3651C1.15042 17.7707 0.745652 17.0134 0.581664 16.189C0.417677 15.3646 0.501841 14.51 0.823514 13.7335C1.14519 12.9569 1.68992 12.2931 2.38883 11.8261C3.08774 11.3591 3.90943 11.1099 4.75 11.1099ZM15.25 0.609863C16.3772 0.609863 17.4582 1.05763 18.2552 1.85466C19.0522 2.65169 19.5 3.73269 19.5 4.85986C19.5 5.98703 19.0522 7.06804 18.2552 7.86507C17.4582 8.6621 16.3772 9.10986 15.25 9.10986H11V4.85986C11 3.73269 11.4478 2.65169 12.2448 1.85466C13.0418 1.05763 14.1228 0.609863 15.25 0.609863ZM11 11.1099H15.25C16.0906 11.1099 16.9123 11.3591 17.6112 11.8261C18.3101 12.2931 18.8548 12.9569 19.1765 13.7335C19.4982 14.51 19.5823 15.3646 19.4183 16.189C19.2544 17.0134 18.8496 17.7707 18.2552 18.3651C17.6608 18.9594 16.9036 19.3642 16.0791 19.5282C15.2547 19.6922 14.4002 19.608 13.6236 19.2864C12.847 18.9647 12.1833 18.4199 11.7163 17.721C11.2493 17.0221 11 16.2004 11 15.3599V11.1099Z"
                          fill=""
                        />
                      </svg>
                      <p class="text-grey flex items-center justify-between">
                        Cards and more
                        <span
                          class="px-3 py-1 text-teal-400 bg-teal-300 rounded-lg text-xs ml-2"
                          >New</span
                        >
                      </p>
                    </router-link>
                  </div>
                  <div @click.stop="showNav = !showNav">
                    <router-link
                      to="/settings"
                      class="flex nav-item items-center mt-6"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        class="mr-4"
                      >
                        <path
                          d="M7.95405 0.514414C9.30298 0.231548 10.6958 0.230867 12.045 0.512414C12.1349 1.1057 12.3574 1.6711 12.6958 2.16659C13.0343 2.66208 13.4801 3.07492 14 3.37441C14.5196 3.67494 15.1002 3.85448 15.6987 3.89967C16.2973 3.94485 16.8983 3.85453 17.457 3.63541C18.3753 4.66347 19.0709 5.87045 19.5 7.18041C19.0314 7.55499 18.6532 8.03036 18.3935 8.57121C18.1338 9.11205 17.9993 9.70446 18 10.3044C18 11.5684 18.586 12.6954 19.502 13.4284C19.0704 14.7373 18.3739 15.9433 17.456 16.9714C16.8974 16.7525 16.2966 16.6622 15.6983 16.7074C15.0999 16.7526 14.5195 16.932 14 17.2324C13.4805 17.5318 13.0351 17.9444 12.6968 18.4395C12.3585 18.9346 12.1361 19.4996 12.046 20.0924C10.6973 20.3759 9.30439 20.3773 7.95505 20.0964C7.86559 19.5026 7.64335 18.9366 7.30487 18.4406C6.96639 17.9445 6.52038 17.5312 6.00005 17.2314C5.48041 16.931 4.89975 16.7517 4.30124 16.7067C3.70272 16.6617 3.10176 16.7521 2.54305 16.9714C1.62465 15.9431 0.929052 14.7357 0.500047 13.4254C0.96844 13.051 1.34651 12.576 1.60618 12.0355C1.86586 11.495 2.00048 10.903 2.00005 10.3034C2.00054 9.70329 1.86575 9.11078 1.60571 8.56993C1.34568 8.02908 0.96707 7.55379 0.498047 7.17941C0.929715 5.87054 1.62616 4.66451 2.54405 3.63641C3.1027 3.85537 3.70352 3.94561 4.30184 3.90043C4.90017 3.85524 5.48062 3.67579 6.00005 3.37541C6.51959 3.07601 6.96503 2.66344 7.30331 2.16832C7.64158 1.6732 7.864 1.10826 7.95405 0.515414V0.514414ZM10 13.3044C10.7957 13.3044 11.5588 12.9883 12.1214 12.4257C12.684 11.8631 13 11.1001 13 10.3044C13 9.50876 12.684 8.7457 12.1214 8.18309C11.5588 7.62048 10.7957 7.30441 10 7.30441C9.2044 7.30441 8.44134 7.62048 7.87873 8.18309C7.31612 8.7457 7.00005 9.50876 7.00005 10.3044C7.00005 11.1001 7.31612 11.8631 7.87873 12.4257C8.44134 12.9883 9.2044 13.3044 10 13.3044Z"
                          fill=""
                        />
                      </svg>
                      <p class="text-grey">Settings</p>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 py-6 ml-8 absolute bottom-6 w-48">
          <div class="flex items-center cursor-pointer" @click="logout">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
            >
              <path
                d="M7.79 13.415C8.18 13.805 8.81 13.805 9.2 13.415L12.79 9.825C13.18 9.435 13.18 8.805 12.79 8.415L9.2 4.825C8.81 4.435 8.18 4.435 7.79 4.825C7.4 5.215 7.4 5.845 7.79 6.235L9.67 8.125H1C0.45 8.125 0 8.575 0 9.125C0 9.675 0.45 10.125 1 10.125H9.67L7.79 12.005C7.4 12.395 7.41 13.035 7.79 13.415ZM16 0.125H2C0.89 0.125 0 1.025 0 2.125V5.125C0 5.675 0.45 6.125 1 6.125C1.55 6.125 2 5.675 2 5.125V3.125C2 2.575 2.45 2.125 3 2.125H15C15.55 2.125 16 2.575 16 3.125V15.125C16 15.675 15.55 16.125 15 16.125H3C2.45 16.125 2 15.675 2 15.125V13.125C2 12.575 1.55 12.125 1 12.125C0.45 12.125 0 12.575 0 13.125V16.125C0 17.225 0.9 18.125 2 18.125H16C17.1 18.125 18 17.225 18 16.125V2.125C18 1.025 17.1 0.125 16 0.125Z"
                fill="#FC3C11"
              />
            </svg>
            <p class="ml-4 text-brandRed">Sign out</p>
          </div>
        </div>
      </div>
    </transition>
    <Checkout
      :display="showCheckout"
      @close="showCheckout = false"
      @success="showCheckout = false"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  components: {
    Checkout: () => import("@/components/Home/Checkout/Checkout.vue"),
  },
  data: () => ({
    showNav: false,
    openPopover: false,
    profilePicture: "",
    showImage: false,
    showCheckout: false,
  }),
  computed: {
    ...mapState({
      activeUser: (state) => state?.auth?.user,
    }),
  },
  watch: {
    showNav(value) {
      if (value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  mounted() {
    if (this.activeUser?.customerFacialImageId) {
      this.profilePicture = `${process.env.VUE_APP_BASE_URL}/media/image/${this.activeUser?.customerFacialImageId}`;
    } else {
      this.profilePicture = require("@/assets/icons/profile.png");
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    handleOptionsOutside() {
      this.showNav = false;
    },
    handleImageError() {
      return (this.profilePicture = require("@/assets/icons/profile.png"));
    },
  },
};
</script>
<style scoped>
svg {
  fill: #acacba;
}
.header {
  /* background-color: #f6f7ff; */
  box-shadow: 0px 2px 4px #d8d8d8;
}
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(86, 85, 90, 0.7) !important;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 0.4s ease-in 0s;
}
.sidebar-menu {
  position: fixed;
  z-index: 200;
  opacity: none;
  height: 100%;
  box-shadow: 0px 6.65584px 39.9351px rgba(88, 88, 88, 0.2);
}
.nav-item {
  padding-left: 32px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  transition: all 0.3s ease-out;
  /* display: inline; */
}
.profile-image {
  padding-left: 32px;
}
.sidebar {
  overflow: auto;
  background: #ededf0;
}

.sidebar-content a.router-link-active {
  border-left: 6px solid #6956c8;
  padding-left: 26px;
}
svg {
  fill: #c2c0c7 !important;
}
.sidebar-content a.router-link-active p {
  color: #6956c8;
  font-weight: bold;
}
.sidebar-content a.router-link-active svg {
  fill: #6956c8 !important;
}
p {
  transition: all 0.3s ease-out;
}
.purchase-button {
  border-radius: 1rem;
  padding: 8px 16px;
}
.purchase-button svg {
  transform: scale(0.7);
}
</style>
