<template>
  <transition name="fade">
    <div class="h-full flex justify-center loader items-center" v-if="display">
      <div>
        <div class="h-full w-full flex justify-center items-center">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="p-4 text-container">
          <h2 class="text-dark text-lg text-center font-bold">{{ title }}</h2>
          <p class="text-grey text-center mt-4">{{ description }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState("loading", {
        description: (state) => state?.loading?.description,
        display: (state) => state.loading?.display,
        title: (state) => state.loading?.title,
      }),
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
  .text-container {
    max-width: 40rem;
  }
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: white;
    /* left: calc(100% - 10rem);
    right: calc(100% - 10rem); */
    display: grid;
    place-items: center;
    z-index: 30;
    /* left: 0;
     */
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 9.2rem;
    height: 10rem;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 8rem;
    height: 8rem;
    margin: 8px;
    border: 4px solid #543eb8;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #543eb8 transparent transparent #f7f5ff;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s ease;
    /* transform: translateX(0); */
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    /* transform: translateX(10px); */
  }
</style>
