var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar items-center h-20 md:h-28 pr-4" },
    [
      _c("div", { staticClass: "hidden logo h-28 md:flex items-center" }, [
        _c("div", { staticClass: "h-8" }, [
          _c("img", {
            staticClass: "max-h-full max-w-full pl-8 cursor-pointer",
            attrs: {
              src: "https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg",
              alt: "",
            },
            on: {
              click: function ($event) {
                return _vm.$router.push("/")
              },
            },
          }),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "flex w-full container navbar-container justify-between items-center pl-4",
        },
        [
          _c("div", [
            _c("h3", { staticClass: "md:text-lg font-bold text-darkBlue" }, [
              _vm._v(
                " Hello " +
                  _vm._s(_vm._f("capitalize")(_vm.activeUser.firstname)) +
                  ", "
              ),
              _c("br", { staticClass: "md:hidden" }),
              _c("span", { staticClass: "font-normal" }, [
                _vm._v(_vm._s(_vm.greeting) + " "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex items-center" }, [
            _c("div", { staticClass: "relative cursor-pointer" }, [
              _c(
                "div",
                {
                  staticClass: "icon flex items-center",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/settings")
                    },
                  },
                },
                [
                  _c("transition", { attrs: { name: "fade" } }, [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showImage,
                          expression: "showImage",
                        },
                      ],
                      staticClass: "icon rounded-full",
                      attrs: {
                        src: _vm.profilePicture,
                        alt: "profile picture",
                      },
                      on: {
                        error: _vm.handleImageError,
                        load: function ($event) {
                          _vm.showImage = true
                        },
                      },
                    }),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c("Checkout", {
        attrs: { display: _vm.showCheckout },
        on: {
          close: function ($event) {
            _vm.showCheckout = false
          },
          success: function ($event) {
            _vm.showCheckout = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }