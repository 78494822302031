var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card transition-all",
      class: [
        _vm.p,
        _vm.background.includes("bg-")
          ? _vm.background
          : "bg-" + _vm.background,
        _vm.shadow ? "shadow" : null,
        _vm.disabled ? "pointer-events-none cursor-no-drop opacity-50" : null,
      ],
      style: {
        "border-radius": isNaN(_vm.radius) ? _vm.radius : _vm.radius + "px",
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }