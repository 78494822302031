var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hr", {
    staticClass: "my-2",
    style: { background: _vm.color, border: "0.5px solid " + _vm.color },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }