var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Card", { staticClass: "w-full", attrs: { shadow: "" } }, [
        _c(
          "h4",
          {
            staticClass:
              "\n        hidden\n        md:block\n        font-bold\n        md:text-xl\n        text-xl text-center\n        md:text-left\n      ",
          },
          [_vm._v(" Sign in ")]
        ),
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.loginUser.apply(null, arguments)
              },
            },
          },
          [
            _c("Input", {
              staticClass: "mt-8",
              attrs: {
                type: "text",
                placeholder: "What's your mail?",
                width: "w-full",
                id: "username",
                errorText: "Please enter a valid email address",
                validation: _vm.rules.username,
              },
              on: {
                valid: function ($event) {
                  _vm.valid.username = $event
                },
              },
              model: {
                value: _vm.user.username,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "username", $$v)
                },
                expression: "user.username",
              },
            }),
            _c("Input", {
              staticClass: "mt-8",
              attrs: {
                type: "password",
                placeholder: "What's your password",
                revealPassword: "",
                width: "w-full",
                id: "password",
                errorText: "Password field cannot be empty",
                validation: _vm.rules.secret,
              },
              on: {
                valid: function ($event) {
                  _vm.valid.secret = $event
                },
              },
              model: {
                value: _vm.user.secret,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "secret", $$v)
                },
                expression: "user.secret",
              },
            }),
            _c("div", [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "flex ml-auto text-right",
                      attrs: { to: "/forgot-password" },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-brandPurple mt-5 text-right text-sm",
                        },
                        [_vm._v(" Forgot password? ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("Button", {
              attrs: {
                text: "Login",
                width: "w-full mt-8",
                loading: _vm.loading,
                shadow: true,
                disabled: _vm.disableButton,
                type: "submit",
              },
            }),
          ],
          1
        ),
        _c(
          "p",
          {
            staticClass:
              "mt-8 text-center text-grey text-sm flex justify-center",
          },
          [
            _vm._v(" Don’t have an account? "),
            _c("router-link", { attrs: { to: "/register" } }, [
              _c("span", { staticClass: "flex items-center" }, [
                _c("span", { staticClass: "text-brandPurple mx-3" }, [
                  _vm._v("Sign up here "),
                ]),
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "16",
                      height: "8",
                      viewBox: "0 0 16 8",
                      fill: "none",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M12.01 3.00057H1C0.45 3.00057 0 3.45057 0 4.00057C0 4.55057 0.45 5.00057 1 5.00057H12.01V6.79057C12.01 7.24057 12.55 7.46057 12.86 7.14057L15.64 4.35057C15.83 4.15057 15.83 3.84057 15.64 3.64057L12.86 0.850567C12.55 0.530567 12.01 0.760566 12.01 1.20057V3.00057Z",
                        fill: "#6B6873",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showLoginPrompt.display },
          on: {
            close: function ($event) {
              _vm.showLoginPrompt.display = false
            },
          },
        },
        [
          _c("transition", { attrs: { name: "slideX", mode: "out-in" } }, [
            _c(
              "div",
              { key: "first", staticClass: "text-center" },
              [
                _c(
                  "h3",
                  { staticClass: "text-center text-black text-xl font-bold" },
                  [_vm._v(" This details belongs to a merchant ")]
                ),
                _c("p", { staticClass: "text-grey text-lg mt-4" }, [
                  _vm._v(
                    " You can either login to your merchant dashboard or create a shoppers account using same details. "
                  ),
                ]),
                _c("Button", {
                  staticClass: "mt-16",
                  attrs: {
                    text: "Login to Merchant Dashboard",
                    width: "w-full",
                  },
                  on: { click: _vm.handleLoginPrompt },
                }),
                _c("Button", {
                  staticClass: "mt-4",
                  attrs: {
                    outline: "",
                    text: "Create Shopper’s Account instead",
                    width: "w-full",
                    loading: _vm.proceedLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleLoginPrompt("register")
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "LargeModal",
        { attrs: { display: _vm.showProfile, size: "large", hideClose: "" } },
        [
          _c("div", { staticClass: "lg:w-3/5 mx-auto mt-12" }, [
            _c(
              "p",
              {
                staticClass:
                  "\n          text-center text-xl\n          md:text-2xl\n          font-bold\n          primary-text\n          profile-title\n        ",
              },
              [_vm._v(" You’re almost good to go; one last thing ")]
            ),
            _c("p", { staticClass: "text-center text-grey mt-2 md:text-lg" }, [
              _vm._v(" What would you like us to call you? "),
            ]),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.setNickName()
                  },
                },
              },
              [
                _c("Input", {
                  staticClass: "mt-8",
                  attrs: {
                    width: "w-full",
                    placeholder: "Nickname",
                    validation: _vm.profileName.length > 0,
                    errorText: "Username field cannot be empty",
                  },
                  model: {
                    value: _vm.profileName,
                    callback: function ($$v) {
                      _vm.profileName = $$v
                    },
                    expression: "profileName",
                  },
                }),
                _c("Button", {
                  staticClass: "mt-12",
                  attrs: {
                    disabled: _vm.disableProfileButton,
                    text: "Continue to your account",
                    width: "w-full",
                    loading: _vm.profileLoading,
                    type: "submit",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showMerchantPrompt },
          on: {
            close: function ($event) {
              _vm.showMerchantPrompt = false
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c(
              "h3",
              {
                staticClass:
                  "text-center text-black text-lg md:text-xl font-bold",
              },
              [
                _vm._v(
                  " This details belongs to a merchant and hasn't completed registration "
                ),
              ]
            ),
            _c("p", { staticClass: "text-grey text-sm md:text-lg mt-4" }, [
              _vm._v(
                " Would you like to complete your merchant registration? "
              ),
            ]),
            _c(
              "a",
              {
                attrs: {
                  href: _vm.merchantUrl,
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
              },
              [
                _c("Button", {
                  staticClass: "mt-12",
                  attrs: {
                    text: "Yes, Continue merchant registration",
                    width: "w-full",
                  },
                }),
              ],
              1
            ),
            _c("p", { staticClass: "md:text-sm text-xs text-grey mt-2" }, [
              _vm._v(" ** You will be redirected to our merchant site "),
            ]),
            _c(
              "p",
              {
                staticClass: "text-center font-semibold text-brandPurple mt-4",
                on: {
                  click: function ($event) {
                    _vm.showMerchantPrompt = false
                  },
                },
              },
              [_vm._v(" Close ")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }