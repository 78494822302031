// import axios from "@/plugins/axios";
import { UPLOAD_BASE64 } from "@/utils/api/media";

export default {
  uploadBase({}, payload) {
    return new Promise((resolve, reject) => {
      UPLOAD_BASE64({
        base64: payload,
        mediaType: "image/png",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
