var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.label.length
        ? _c("label", { staticClass: "mb-2", attrs: { for: _vm.id } }, [
            _vm._v(" " + _vm._s(_vm.label) + " "),
            _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
          ])
        : _vm._e(),
      _c("vue-tel-input", {
        class: [
          _vm.width,
          _vm.phoneIsValid
            ? "input-active"
            : _vm.value && !_vm.phoneIsValid
            ? "tel-error-border"
            : null,
        ],
        attrs: {
          value: _vm.internalValue,
          "enabled-country-code": true,
          "auto-format": false,
          "input-options": _vm.inputOptions,
          "dropdown-options": _vm.dropdownOptions,
          disabled: _vm.disabled,
          mode: "national",
          defaultCountry: _vm.defaultCountry,
          "wrapper-classes": "border-0 focus:outline-none pl-12",
          validCharactersOnly: true,
        },
        on: {
          input: _vm.updateValue,
          blur: function ($event) {
            _vm.isBlurred = true
          },
        },
      }),
      _c(
        "div",
        { staticClass: "error-text" },
        [
          _c("transition", { attrs: { name: "slideY" } }, [
            _vm.isBlurred && !_vm.phoneIsValid
              ? _c("p", { staticClass: "text-brandRed text-xs mt-1" }, [
                  _vm._v(" " + _vm._s(_vm.errorText) + " "),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }