import { resetState } from "./defaultState";
export default {
    RESET_CARD_MODULE_STATE(state) {
        Object.assign(state, resetState());
    },
    UPDATE_CARD_TYPE(state, payload) {
        state.card.type = payload;
    },
    UPDATE_CARD_CUSTOMIZATION(state, payload) {
        state.card.color = payload.color;
        state.card.nickname = payload.nickname
    },
    UPDATE_CARD_PIN(state, payload) {
        state.card.pin = payload;
    },
    RESET_CARDS(state) {
        state.virtualCards = [];
    },
    ADD_VIRTUAL_CARDS(state, payload) {
        state.virtualCards.push(...payload);
    },
    GET_VIRTUAL_CARDS(state, payload) {
        state.virtualCards = payload;
    },
    UPDATE_CARD_DELIVER_ADDRESS(state, payload) {
        state.card.deliveryAddressId = payload;
        state.card.permanentAddressId = payload;
    },
    SAVE_FULL_ADDRESS(state, payload) {
        state.fullAddress = payload;
    },
    SET_CALCULATOR_DATA(state, payload) {
        state.calculatorData = payload;
    },
    SET_ALLOWED_PAYMENT_PLAN(state, payload) {
        state.allowedPaymentPlans = payload
    },

    SET_FLEXI_FEE(state, payload) {
        state.calculatorData[payload.type].flexiblePaymentPlanConfig.feeDetails = payload.fee;
    },

    SET_DEFAULT_FLEXI_FEE(state) {

        // if already in creditplan don't push
        const existInCreditPlans = state.calculatorData?.creditCard?.paymentPlans.find((item) => item.description === state.calculatorData?.creditCard?.defaultFlexiblePaymentPlan.description);
        if (!existInCreditPlans) {
            state.calculatorData?.creditCard?.paymentPlans.push(state.calculatorData?.creditCard?.defaultFlexiblePaymentPlan);
        }

        // if already in checkout don't push
        const existInCheckoutPlans = state.calculatorData?.checkout.paymentPlans.find((item) => item.description === state.calculatorData?.creditCard?.defaultFlexiblePaymentPlan.description);
        if (!existInCheckoutPlans) {
            state.calculatorData?.checkout?.paymentPlans.push(state.calculatorData?.checkout?.defaultFlexiblePaymentPlan);
        }
    },
};
