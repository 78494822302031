var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "line",
    style: {
      width: _vm.width,
      height: _vm.height,
      "border-radius": _vm.type === "circle" ? "50%" : _vm.radius,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }