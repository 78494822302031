var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { display: "flex" },
      style: { "justify-content": _vm.justify },
    },
    _vm._l(_vm.numInputs, function (item, i) {
      return _c("SingleOtpInput", {
        key: i,
        attrs: {
          num: _vm.numInputs,
          focus: _vm.activeInput === i,
          value: _vm.otp[i],
          separator: _vm.separator,
          "input-classes": _vm.inputClasses,
          "is-last-child": i === _vm.numInputs - 1,
          "should-auto-focus": _vm.shouldAutoFocus,
          "is-input-num": _vm.isInputNum,
        },
        on: {
          "on-change": _vm.handleOnChange,
          "on-keydown": _vm.handleOnKeyDown,
          "on-paste": _vm.handleOnPaste,
          "on-focus": function ($event) {
            return _vm.handleOnFocus(i)
          },
          "on-blur": _vm.handleOnBlur,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }