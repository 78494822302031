var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.loading
          ? _c(
              "div",
              {
                key: "card",
                staticClass: "w-full grid md:grid-cols-2 grid-cols-1 gap-4",
              },
              _vm._l(2, function (i) {
                return _c(
                  "Card",
                  { key: i },
                  [
                    _c("Skeleton", { attrs: { height: "1rem", width: "40%" } }),
                    _c("Skeleton", {
                      staticClass: "mt-2",
                      attrs: { height: "1.5rem", width: "30%" },
                    }),
                    _c("Skeleton", {
                      staticClass: "mt-4",
                      attrs: { height: "1rem", width: "80%" },
                    }),
                  ],
                  1
                )
              }),
              1
            )
          : _c(
              "div",
              {
                key: "no card",
                staticClass:
                  "grid balance-grid md:grid-cols-2 grid-cols-1 gap-4",
              },
              [
                _c("div", { staticClass: "flex rounded-xl p-6 firstCard" }, [
                  _c("div", { staticClass: "w-1/2 md:w-full" }, [
                    _c("p", { staticClass: "text-white" }, [
                      _vm._v("Total Balance"),
                    ]),
                    _vm.wallet.creditWallet
                      ? _c(
                          "h3",
                          {
                            staticClass:
                              "mt-1 md:mt-2.5 text-xl text-white font-bold",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatMoney")(
                                    _vm.zillaBalance +
                                      _vm.wallet.instaPayWallet.balance
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _c(
                          "h3",
                          {
                            staticClass:
                              "mt-1 md:mt-2.5 text-xl text-white font-bold",
                          },
                          [_vm._v(" " + _vm._s(_vm._f("formatMoney")(0)) + " ")]
                        ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-end lg:hidden w-full",
                    },
                    [
                      _c("SecondaryButton", {
                        attrs: { text: "View Details" },
                        on: {
                          click: function ($event) {
                            _vm.showFundHistory = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                !_vm.loading
                  ? _c(
                      "Card",
                      { staticClass: "md:flex hidden", attrs: { p: "p-6" } },
                      [
                        _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            _c("div", { staticClass: "credit" }, [
                              _c("p", { staticClass: "text-brandPurple" }, [
                                _vm._v("Credit Balance "),
                                _vm.wallet.instaPayWallet
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content: _vm.getCreditFinancer(),
                                              placement: "top",
                                              trigger: "click",
                                              autiHide: true,
                                            },
                                            expression:
                                              "{\n                content: getCreditFinancer(),\n                placement:'top',\n                trigger:'click',\n                autiHide: true\n              }",
                                          },
                                        ],
                                        staticClass: "cursor-pointer",
                                      },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "inline-block",
                                          attrs: {
                                            color: "#6956C8",
                                            size: "15",
                                            type: "mdi",
                                            path: _vm.svgPath.info,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                              _vm.wallet.instaPayWallet
                                ? _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "mt-1 md:mt-2.5 text-xl text-brandPurple font-bold",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatMoney")(
                                              _vm.zillaBalance
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "mt-1 md:mt-2.5 text-xl text-black font-bold",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm._f("formatMoney")(0)) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ]),
                            _c("div", { staticClass: "line" }),
                            _c("div", { staticClass: "wallet" }, [
                              _c("p", { staticClass: "text-brandPurple" }, [
                                _vm._v("Wallet Balance"),
                              ]),
                              _vm.wallet.instaPayWallet
                                ? _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "mt-1 md:mt-2.5 text-xl text-brandPurple font-bold",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatMoney")(
                                              _vm.wallet.instaPayWallet.balance
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "mt-1 md:mt-2.5 text-xl text-black font-bold",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm._f("formatMoney")(0)) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ]),
                            _c("SecondaryButton", {
                              attrs: { text: "View details" },
                              on: {
                                click: function ($event) {
                                  _vm.showFundHistory = true
                                },
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "flex flex-wrap justify-between items-center",
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "Modal",
                  {
                    attrs: {
                      display: _vm.showFundAccount,
                      title: "Add money to account",
                    },
                    on: { close: _vm.closeFundWallet },
                  },
                  [
                    _c("FundAccount", {
                      on: {
                        close: function ($event) {
                          ;(_vm.showFundAccount = false),
                            (_vm.showFundHistory = true)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "Modal",
                  {
                    attrs: {
                      display: _vm.showFundHistory,
                      title: "Your Wallet",
                      size: "large",
                    },
                    on: {
                      close: function ($event) {
                        _vm.showFundHistory = false
                      },
                    },
                  },
                  [
                    _c("FundHistory", {
                      attrs: {
                        balance: _vm.wallet.instaPayWallet.balance,
                        creditBalance: _vm.zillaBalance,
                      },
                      on: {
                        fund: function ($event) {
                          ;(_vm.showFundHistory = false),
                            (_vm.showFundAccount = true)
                        },
                        withdraw: function ($event) {
                          ;(_vm.showFundHistory = false),
                            (_vm.showWithdrawal = true)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "Modal",
                  {
                    attrs: { display: _vm.showWithdrawal, title: "Withdrawal" },
                    on: {
                      close: function ($event) {
                        ;(_vm.showWithdrawal = false),
                          (_vm.showFundHistory = true)
                      },
                    },
                  },
                  [
                    _c("Withdrawal", {
                      attrs: {
                        noCashoutAccount: _vm.noCashoutAccount,
                        cashoutAccount: _vm.cashoutAccount,
                      },
                      on: {
                        completed: function ($event) {
                          ;(_vm.showWithdrawal = false),
                            (_vm.showFundHistory = true)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }