<template>
  <div>
    <p class="text-grey mb-2" v-if="formLabel">
      {{ formLabel }}
    </p>
    <v-select
      :options="options"
      :placeholder="placeholder"
      multiple
      class="outline-none transition-all input-regular placeholder-borderGrey"
      :class="[
        disabled ? 'disabled' : null,
        error ? 'error-border' : selected.length > 0 ? 'input-active' : '',
      ]"
      :label="label"
      :value="value"
      :reduce="reduce"
      v-model="selected"
      @input="$emit('input', selected)"
    >
      <template #open-indicator="{ attributes }">
        <svg
          v-bind="attributes"
          width="11"
          height="7"
          fill="none"
          class="transition-all"
        >
          <path d="M.842 1l4.5 4.5 4.5-4.5" stroke="#000" />
        </svg>
      </template>
      <!-- <template #option="option">
        <div class="flex items-center py-1 justify-between cursor-pointer">
          <p>{{ getOptionLabel(option) }}</p>
          <img
            v-if="isOptionSelected(option)"
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1630797354/customer/Group_642_kmimow.svg"
            alt=""
            class="eligibile-icon w-4"
          />
        </div>
      </template> -->
    </v-select>
  </div>
</template>

<script>
import VSelect from "vue-select";
import Deselect from "./DeselectIcon.vue";

VSelect.props.components.default = () => ({ Deselect });

export default {
  components: {
    "v-select": VSelect,
  },

  props: {
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    error: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      default: "Select",
      required: false,
    },
    formLabel: {
      type: String,
      default: "Select",
      required: false,
    },
    reduce: {
      type: Function,
      default: (option) => option,
    },
    value: {
      type: Array,
      default: () => [],
      required: false,
    },
  },

  data() {
    return {
      selected: [],
    };
  },
  // methods: {
  //   getOptionLabel(option) {
  //     return option[this.label];
  //   },

  //   isOptionSelected(value) {
  //     if (this.selected.length > 0) {
  //       if (this.reduce) {
  //         console.log(value, typeof this.selected);
  //         const item = this.reduce(value);
  //         return this.selected.includes(item);
  //       } else {
  //         console.log(
  //           "huiio",
  //           this.selected.some((option) => option === value)
  //         );
  //         return this.selected.some((option) => option === value);
  //       }
  //     }
  //   },
  // },
};
</script>

<style src="vue-select/dist/vue-select.css"></style>
<style>
.vs__dropdown-toggle {
  background: transparent;
  border: none;
  min-height: 3.1rem;
}

.input-regular {
  border: 1px solid #ededf0;
  border-radius: 8px;
  background-color: #fcfbff;
}
.input-active {
  border: 1px solid #d5ccff;
  border-radius: 8px;
  background-color: transparent;
}
.error-border {
  border: 1px solid #ffd5cc;
  background: #fff7f5;
  color: #fc3c11;
}
.disabled {
  border: 1px solid #f8f8f8;
}

input.vs__search::placeholder {
  color: #9d9d9d;
  font-size: 1.125rem;
}

.vs__actions {
  padding-right: 12px;
}

.vs__open-indicator {
  fill: none;
}

.vs__search,
.vs__search:focus {
  padding-left: 12px;
}

.vs__selected {
  background-color: #fff;
  border: 1px solid #e0dfe3;
  border-radius: 8px;
  color: #706d78;
  padding: 0.3em 0.5em;
}

.vs__deselect {
  padding-left: 5px;
}

.vs__dropdown-menu {
  border: none;
  margin-top: 2px;
  background-color: #fcfbff;
  border-radius: 8px;
  box-shadow: 0px 6.65584px 39.9351px rgba(128, 70, 251, 0.2);
}

.vs__dropdown-option {
  padding: 10px;
  color: #1e1d20;
  border-bottom: 0.5px solid #e0dfe3;
  font-size: 1rem;
}

.vs__dropdown-option:last-child {
  border-bottom: none;
}

.vs__dropdown-option.vs__dropdown-option--highlight {
  background: transparent;
}

.vs__dropdown-option--selected,
.vs__dropdown-option--selected.vs__dropdown-option--highlight {
  /* background-color: #fff; */
  color: #9d9d9d;
  cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
  input.vs__search::placeholder {
    font-size: 1rem;
  }
  .vs__dropdown-toggle {
    min-height: 3rem;
  }
}
</style>